
import { defineComponent, onMounted, PropType, ref, toRef, watch } from "vue";

import fuzzysort from "fuzzysort";

import { strictInject, T } from "@/symbols";
import { SelectOption, SelectedValue } from "@/types/components/CustomSelectInput";

// Element styling customizable, Searchable Data List Input
export default defineComponent({
    setup(props, { emit }) {
        const t = strictInject(T);

        const muestraOptions = ref(false);
        const focus = () => {
            muestraOptions.value = true;
        };
        const focusOut = () => {
            setTimeout(() => {
                muestraOptions.value = false;
            }, 200);
        };

        const list = toRef(props, "list");

        const listaFiltrada = ref(list.value);
        const filtraLista = (value: string) => {
            if (value === "") {
                listaFiltrada.value = list.value;
            } else {
                const busqueda = fuzzysort.go(value, list.value, { key: "placeholder", limit: 50, threshold: -10000 });
                listaFiltrada.value = busqueda.map((resultado) => resultado.obj);
            }
        };

        const selectValue = toRef(props, "selectValue");
        watch(selectValue, (newVal) => {
            inputValue.value = newVal.isPredefinedOption
                ? (newVal.value as SelectOption).placeholder
                : (newVal.value as string);
        });

        const inputValue = ref("");
        watch(list, () => {
            filtraLista(inputValue.value);
        });

        const actualizaPropSelectedVal = (value: string) => {
            const valorEscogido: SelectedValue = {
                value: value,
                isPredefinedOption: false,
            };

            emit("update:selectValue", valorEscogido);
            inputValue.value = value;
        };

        const changeInputValue = (e: Event) => {
            const val = (e.target as HTMLInputElement).value;
            actualizaPropSelectedVal(val);
            filtraLista(val);
        };

        const selecciona = (el: typeof props["list"][number]) => {
            const valorEscogido: SelectedValue = {
                value: el,
                isPredefinedOption: true,
            };
            emit("update:selectValue", valorEscogido);
            inputValue.value = el.placeholder;
        };

        return {
            t,
            muestraOptions,
            inputValue,
            focus,
            focusOut,
            selecciona,
            listaFiltrada,
            changeInputValue,
        };
    },
    emits: ["update:selectValue"],
    props: {
        list: {
            type: Array as PropType<SelectOption[]>,
            required: true,
        },
        selectValue: {
            type: Object as PropType<SelectedValue>,
            required: true,
        },
        disabled: {
            type: Boolean as PropType<boolean>,
            required: false,
        },
    },
});
