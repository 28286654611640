import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "version-ceremonia-page" }
const _hoisted_2 = { class: "version-box" }
const _hoisted_3 = { class: "escoge-version-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.t("escogeVersionEntrevista.pregunta")), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "version-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.escogeVersion(_ctx.versionesEntrevista.normal)))
        }, _toDisplayString(_ctx.t("escogeVersionEntrevista.tipoCompleta")), 1),
        _createElementVNode("button", {
          class: "version-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.escogeVersion(_ctx.versionesEntrevista.memora)))
        }, _toDisplayString(_ctx.t("escogeVersionEntrevista.tipoCrematori")), 1)
      ])
    ])
  ]))
}