import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderSlot as _renderSlot, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-feafd258"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-entrevista--pregunta-group" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "form-entrevista--respuesta-selectores" }
const _hoisted_4 = { class: "form-entrevista--respuesta-selectores" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCheckBox = _resolveComponent("CustomCheckBox")!
  const _component_AlternativasSelectoresPregunta = _resolveComponent("AlternativasSelectoresPregunta")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.pregunta)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.pregunta), 1))
      : _createCommentVNode("", true),
    (_ctx.getConnotacion('Negativa', _ctx.checkboxesAtributos).length !== 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getConnotacion('Positiva', _ctx.checkboxesAtributos), (checkboxAtributo, index) => {
              return (_openBlock(), _createBlock(_component_CustomCheckBox, {
                key: index,
                value: checkboxAtributo.id,
                modelValue: _ctx.atributosSeleccionados,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.atributosSeleccionados) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(checkboxAtributo.label), 1)
                ]),
                _: 2
              }, 1032, ["value", "modelValue"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getConnotacion('Negativa', _ctx.checkboxesAtributos), (checkboxAtributo, index) => {
              return (_openBlock(), _createBlock(_component_CustomCheckBox, {
                key: index,
                value: checkboxAtributo.id,
                modelValue: _ctx.atributosSeleccionados,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.atributosSeleccionados) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(checkboxAtributo.label), 1)
                ]),
                _: 2
              }, 1032, ["value", "modelValue"]))
            }), 128))
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _mergeProps({
          key: 2,
          class: "form-entrevista--respuesta-selectores"
        }, _ctx.$attrs), [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkboxesAtributos, (checkboxAtributo, index) => {
            return (_openBlock(), _createBlock(_component_CustomCheckBox, {
              key: index,
              value: checkboxAtributo.id,
              modelValue: _ctx.atributosSeleccionados,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.atributosSeleccionados) = $event))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(checkboxAtributo.label), 1)
              ]),
              _: 2
            }, 1032, ["value", "modelValue"]))
          }), 128))
        ], 16)),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createVNode(_component_AlternativasSelectoresPregunta, {
      opcionesPreguntaSeleccionadas: _ctx.atributosSeleccionados,
      listadoValoresDefecto: _ctx.checkboxesAtributos,
      "valores-alternativos-selectores": _ctx.alternativas,
      "onUpdate:valores-alternativos-selectores": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.alternativas) = $event)),
      enlace: _ctx.enlace,
      simple: _ctx.simple
    }, null, 8, ["opcionesPreguntaSeleccionadas", "listadoValoresDefecto", "valores-alternativos-selectores", "enlace", "simple"])
  ]))
}