import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "custom-select-input" }
const _hoisted_2 = ["value", "disabled"]
const _hoisted_3 = {
  key: 0,
  role: "listbox",
  class: "custom-select-input--options-box"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "custom-select-input--default-option__disabled"
}
const _hoisted_6 = {
  key: 1,
  class: "custom-select-input--default-option__disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "text",
      onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
      onFocusout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.focusOut && _ctx.focusOut(...args))),
      value: _ctx.inputValue,
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeInputValue && _ctx.changeInputValue(...args))),
      class: "form-entrevista--respuesta-texto form-entrevista--respuesta-texto__inline",
      disabled: _ctx.disabled
    }, null, 40, _hoisted_2),
    (_ctx.muestraOptions)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_ctx.list.length > 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.listaFiltrada.length > 0)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.listaFiltrada, (el, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        onClick: ($event: any) => (_ctx.selecciona(el)),
                        role: "option",
                        class: "custom-select-input--default-option"
                      }, [
                        _renderSlot(_ctx.$slots, "default", { el: el }, () => [
                          _createTextVNode(_toDisplayString(el.placeholder), 1)
                        ])
                      ], 8, _hoisted_4))
                    }), 128))
                  : (_openBlock(), _createElementBlock("li", _hoisted_5, _toDisplayString(_ctx.t("generico.sinResultados")), 1))
              ], 64))
            : (_openBlock(), _createElementBlock("li", _hoisted_6, _toDisplayString(_ctx.t("generico.cargando")), 1))
        ]))
      : _createCommentVNode("", true)
  ]))
}