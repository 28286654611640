import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import session from "@/modules/session";

import Login from "@/views/Login.vue";
import Entrevista from "@/views/Entrevista.vue";
import EntrevistaBloqueada from "@/views/EntrevistaBloqueada.vue";
import ComposicionCeremonia from "@/views/ComposicionCeremonia.vue";
import ComponentGUITester from "@/views/ComponentGUITester.vue";
import EspacioMultimedia from "@/views/EspacioMultimedia.vue";
import MiCuenta from "@/views/MiCuenta.vue";
import EscogeVersionEntrevista from "@/views/EscogeVersionEntrevista.vue";
import { versionesEntrevista } from "@/types/auxiliares-logica-app";
import useConfigPorEntorno from "@/composables/useConfigPorEntorno";

function checkIfLogged() {
    if (!session.isValid()) {
        return { name: "Login" };
    }
}

const routes: Array<RouteRecordRaw> = [
    {
        path: "/test",
        name: "Development",
        component: ComponentGUITester,
        beforeEnter: checkIfLogged,
    },
    {
        path: "/",
        name: "Home",
        redirect: () => {
            // if (process.env.VUE_APP_ENVIRONMENT === "localhost") {
            //     return {
            //         name: "Development",
            //     };
            // } else {

            const navGuard = checkIfLogged();
            if (undefined !== navGuard) {
                return navGuard;
            }

            const config = useConfigPorEntorno();
            const pageName = config.configEntorno.paginaPrincipal;

            return {
                name: pageName,
            };
            // }
        },
    },
    {
        path: "/lang/:locale",
        redirect: (to) => {
            return {
                name: "Home",
                params: {
                    locale: to.params.locale,
                },
            };
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/entrevista",
        name: versionesEntrevista.normal,
        component: Entrevista,
        props: (route) => {
            if (
                route.params.idCeremoniaContinuada !== undefined &&
                route.params.fichaDifunto !== undefined &&
                route.params.respuestasEntrevista !== undefined
            ) {
                return {
                    idCeremoniaContinuada: JSON.parse(route.params.idCeremoniaContinuada as string),
                    idioma: JSON.parse(route.params.idioma as string),
                    fichaDifunto: JSON.parse(route.params.fichaDifunto as string),
                    respuestasEntrevista: JSON.parse(route.params.respuestasEntrevista as string),
                };
            }
        },
        beforeEnter: checkIfLogged,
    },
    {
        path: "/terrassa-crematori",
        name: versionesEntrevista.terrassa,
        component: EntrevistaBloqueada,
        props: (route) => {
            if (
                route.params.idCeremoniaContinuada !== undefined &&
                route.params.fichaDifunto !== undefined &&
                route.params.respuestasEntrevista !== undefined
            ) {
                return {
                    idCeremoniaContinuada: JSON.parse(route.params.idCeremoniaContinuada as string),
                    idioma: JSON.parse(route.params.idioma as string),
                    fichaDifunto: JSON.parse(route.params.fichaDifunto as string),
                    respuestasEntrevista: JSON.parse(route.params.respuestasEntrevista as string),
                    bloqueoCrematorioTerrassa: true,
                };
            } else {
                return {
                    bloqueoCrematorioTerrassa: true,
                };
            }
        },
        beforeEnter: checkIfLogged,
    },
    {
        path: "/memora-crematori",
        name: versionesEntrevista.memora,
        component: EntrevistaBloqueada,
        props: (route) => {
            if (
                route.params.idCeremoniaContinuada !== undefined &&
                route.params.fichaDifunto !== undefined &&
                route.params.respuestasEntrevista !== undefined
            ) {
                return {
                    idCeremoniaContinuada: JSON.parse(route.params.idCeremoniaContinuada as string),
                    idioma: JSON.parse(route.params.idioma as string),
                    fichaDifunto: JSON.parse(route.params.fichaDifunto as string),
                    respuestasEntrevista: JSON.parse(route.params.respuestasEntrevista as string),
                    bloqueoCrematorioMemora: true,
                };
            } else {
                return {
                    bloqueoCrematorioMemora: true,
                };
            }
        },
        beforeEnter: checkIfLogged,
    },
    {
        path: "/composicion",
        name: "composicion-ceremonia",
        component: ComposicionCeremonia,
        props: (route) => ({
            idCeremonia: JSON.parse(route.params.idCeremonia as string),
            difunto: JSON.parse(route.params.difunto as string),
            respuestasEntrevista: JSON.parse(route.params.respuestasEntrevista as string),
        }),
        beforeEnter: [
            checkIfLogged,
            (to) => {
                if (
                    !Object.prototype.hasOwnProperty.call(to.params, "idCeremonia") ||
                    !Object.prototype.hasOwnProperty.call(to.params, "difunto") ||
                    !Object.prototype.hasOwnProperty.call(to.params, "respuestasEntrevista")
                ) {
                    console.error(
                        "Faltan parámetros de ruta (a modo de props) para mostrar esta vista: 'idCeremonia' o 'difunto' o 'respuestasEntrevista'"
                    );
                    return { name: "entrevista" };
                }
            },
        ],
    },
    {
        path: "/multimedia/:tipoArchivo/:idMultimediaDifunto",
        name: "espacio-multimedia",
        component: EspacioMultimedia,
    },
    {
        path: "/cuenta",
        name: "cuenta",
        component: MiCuenta,
        beforeEnter: checkIfLogged,
    },
    {
        path: "/version",
        name: "version",
        component: EscogeVersionEntrevista,
        beforeEnter: checkIfLogged,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
