import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-entrevista--pregunta-group" }
const _hoisted_2 = { class: "form-entrevista--pregunta-group mt" }
const _hoisted_3 = { class: "inline-radio-buttons" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "form-entrevista--pregunta-group mt" }
const _hoisted_6 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListImageUploader = _resolveComponent("ListImageUploader")!
  const _component_CustomRadioButton = _resolveComponent("CustomRadioButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, _toDisplayString(_ctx.t("formEntrevista.serviciosExternos.personalizacionAudiovisual.fotosLabel")) + ":", 1),
      _createVNode(_component_ListImageUploader, { maxFotos: _ctx.maxFotos }, null, 8, ["maxFotos"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.t("formEntrevista.serviciosExternos.personalizacionAudiovisual.temasLabel")) + ":", 1),
      _createElementVNode("section", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listaTemas, (tema, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "seleccion-con-foto"
          }, [
            _createElementVNode("img", {
              class: "seleccion-con-foto--imagen",
              src: _ctx.backendApiConsumer.getPortadaTemaImg(tema.codigoTema)
            }, null, 8, _hoisted_4),
            _createVNode(_component_CustomRadioButton, {
              value: tema.codigoTema,
              modelValue: _ctx.codigoTemaEscogidoBridge,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.codigoTemaEscogidoBridge) = $event))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tema.nombre), 1)
              ]),
              _: 2
            }, 1032, ["value", "modelValue"])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("label", null, _toDisplayString(_ctx.t("formEntrevista.serviciosExternos.personalizacionAudiovisual.fraseDespedidaLabel")) + ":", 1),
      _withDirectives(_createElementVNode("input", {
        class: "form-entrevista--respuesta-texto form-entrevista--respuesta-texto__inline",
        name: "frase-despedida-audiovisual",
        type: "text",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fraseDespedidaBridge) = $event)),
        placeholder: _ctx.t('formEntrevista.serviciosExternos.personalizacionAudiovisual.fraseDespedidaPlaceholder')
      }, null, 8, _hoisted_6), [
        [_vModelText, _ctx.fraseDespedidaBridge]
      ])
    ])
  ], 64))
}