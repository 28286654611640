import * as Difunto from "@/modules/Difunto";
import { Enfoque } from "@/types/dominio-ceremonia-civil";

export default class TokenTipologiaCeremonia {
    private token: string;

    constructor(
        rangoEdad: Difunto.RangoEdad,
        sexo: Difunto.Sexo,
        tipoMuerte: Difunto.TipoMuerte,
        tipoRelaciones: { relaciones: Difunto.TipoRelaciones; ceremoniaGenerica: boolean },
        enfoqueReligioso: Enfoque
    ) {
        let relaciones: Difunto.TipoRelaciones | string = tipoRelaciones.relaciones;
        if (tipoRelaciones.relaciones === Difunto.TipoRelaciones.Conflictivo) {
            if (tipoRelaciones.ceremoniaGenerica) {
                relaciones = "2";
            } else {
                relaciones = "1";
            }
        }

        this.token = `${rangoEdad}${sexo}${tipoMuerte}${relaciones}${enfoqueReligioso}`;
    }

    testAgainstExpression(expr: string) {
        if (expr === "*") {
            return true;
        } else {
            const subtokensTipologias = expr.split(",");
            for (const subtoken of subtokensTipologias) {
                const subtokenRegex = new RegExp("^" + subtoken.replace(/\?/g, ".") + "$");
                if (subtokenRegex.test(this.token)) {
                    return true;
                }
            }

            return false;
        }
    }
}
