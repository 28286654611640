
import { App, defineComponent } from "vue";

import AccordionItem from "./AccordionItem.vue";
import * as TiposDifunto from "../modules/Difunto";
import {
    AlternativasSelectoresT,
    AtributosAdicionales,
    Desarrollable,
    EntrevistaCompletada,
    Intervencion,
    ServicioMusical,
    PARENTESCOS,
    Menciones,
    PiezaMusicalAppTextos,
    PIEZA_SIN_REGISTRO,
    EntrevistaGuardada,
    FotoDifuntoAudiovisual,
} from "@/types/dominio-ceremonia-civil";
import {
    AppLocales,
    FichaDifuntoForm,
    RespuestasEntrevistaForm,
    ServicioMusicalForm,
} from "@/types/auxiliares-logica-app";
import Alerta from "@/modules/Alerta";
import { generaComputedParaProps } from "@/store/helpers-generadores";
import store, { nombreMutationRespuestasEntrevista } from "@/store/index";

import SelectorAtributosCheckboxes from "@/components/SelectorAtributosCheckboxes.vue";
import AccordionContainer from "./AccordionContainer.vue";
import FormEntrevistaPreguntaDatosGenerales from "@/components/FormEntrevistaPreguntas/FormEntrevista_DatosGenerales.vue";
import FormEntrevistaPreguntaEnfoque from "@/components/FormEntrevistaPreguntas/FormEntrevista_Enfoque.vue";
import FormEntrevistaPreguntaServiciosExternos from "@/components/FormEntrevistaPreguntas/FormEntrevista_ServiciosExternos.vue";
import FormEntrevistaPreguntaCaracter from "@/components/FormEntrevistaPreguntas/FormEntrevista_Caracter.vue";
import FormEntrevistaPreguntaVinculos from "@/components/FormEntrevistaPreguntas/FormEntrevista_Vinculos.vue";
import FormEntrevistaPreguntaAficiones from "@/components/FormEntrevistaPreguntas/FormEntrevista_Aficiones.vue";
import FormEntrevistaPreguntaRecuerdo from "@/components/FormEntrevistaPreguntas/FormEntrevista_Recuerdo.vue";
import FormEntrevistaPreguntaIntervenciones from "@/components/FormEntrevistaPreguntas/FormEntrevista_Intervenciones.vue";
import FormEntrevistaPreguntaAtributosAdicionales from "@/components/FormEntrevistaPreguntas/FormEntrevista_AtributosAdicionales.vue";
import FormEntrevistaPreguntaMenciones from "@/components/FormEntrevistaPreguntas/FormEntrevista_Menciones.vue";
import { componeDesarrollables } from "@/modules/desarrollables-composer";
import { SelectedValue } from "@/types/components/CustomSelectInput";

export default defineComponent({
    data(): {
        fichaDifunto: FichaDifuntoForm;
        accordionItemsIds: number[];
    } {
        return {
            fichaDifunto: {
                nombre: "",
                apellidos: "",
                alias: "",
                lugarNacimiento: "",
                edad: undefined as number | undefined,
                sexo: -1,
                tipoMuerte: -1,
                comunidadNacimiento: "",
            },
            accordionItemsIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        };
    },
    computed: {
        ...(generaComputedParaProps(
            [
                "audiovisualAutomatico.idPoemaEscogido",
                "audiovisualAutomatico.codigoTemaEscogido",
                "audiovisualAutomatico.fraseDespedida",
                "audiovisualAutomatico.sinPoemaEnCeremonial",
                "audiovisualAutomatico.atributosAdicionales.listaAtributos",
                "audiovisualAutomatico.atributosAdicionales.usaSoloAtributosAdicionales",
                "enfoque",
                "informacionExtra",
                "menciones",
                "intervenciones",
                "isPersonaConflictiva",
                "eligeCeremoniaPersonalizada",
                "servicioMusical",
            ],
            store,
            nombreMutationRespuestasEntrevista,
            "respuestasEntrevista"
        ) as {
            respuestasEntrevista_audiovisualAutomatico_idPoemaEscogido: {
                get: () => number;
                set: (newVal: number) => void;
            };
            respuestasEntrevista_audiovisualAutomatico_sinPoemaEnCeremonial: {
                get: () => boolean;
                set: (newVal: boolean) => void;
            };
            respuestasEntrevista_audiovisualAutomatico_codigoTemaEscogido: {
                get: () => string;
                set: (newVal: string) => void;
            };
            respuestasEntrevista_audiovisualAutomatico_fraseDespedida: {
                get: () => string;
                set: (newVal: string) => void;
            };
            respuestasEntrevista_audiovisualAutomatico_atributosAdicionales_listaAtributos: {
                get: () => AtributosAdicionales["listaAtributos"];
                set: (newVal: AtributosAdicionales["listaAtributos"]) => void;
            };
            respuestasEntrevista_audiovisualAutomatico_atributosAdicionales_usaSoloAtributosAdicionales: {
                get: () => AtributosAdicionales["usaSoloAtributosAdicionales"];
                set: (newVal: AtributosAdicionales["usaSoloAtributosAdicionales"]) => void;
            };
            respuestasEntrevista_enfoque: {
                get: () => RespuestasEntrevistaForm["enfoque"];
                set: (newVal: RespuestasEntrevistaForm["enfoque"]) => void;
            };
            respuestasEntrevista_informacionExtra: {
                get: () => string;
                set: (newVal: string) => void;
            };
            respuestasEntrevista_intervenciones: {
                get: () => Intervencion[];
                set: (newVal: Intervencion[]) => void;
            };
            respuestasEntrevista_menciones: {
                get: () => Intervencion[];
                set: (newVal: Intervencion[]) => void;
            };
            respuestasEntrevista_isPersonaConflictiva: {
                get: () => boolean;
                set: (newVal: boolean) => void;
            };
            respuestasEntrevista_eligioCeremoniaPersonalizada: {
                get: () => boolean;
                set: (newVal: boolean) => void;
            };
            respuestasEntrevista_servicioMusical: {
                get: () => ServicioMusicalForm;
                set: (newVal: ServicioMusicalForm) => void;
            };
        }),
        difunto(): TiposDifunto.Difunto {
            return {
                ...(this.fichaDifunto as TiposDifunto.FichaDifunto),
                tipoRelaciones: this.respuestasEntrevista_isPersonaConflictiva
                    ? TiposDifunto.TipoRelaciones.Conflictivo
                    : TiposDifunto.TipoRelaciones.Positivo,
            };
        },
    },
    methods: {
        getItemId(index: number): number {
            return this.accordionItemsIds[index];
        },
        onSubmit() {
            if (this.validaInputs()) {
                const formateadas = this.formateaRespuestas(this.$store.state.respuestasEntrevista);

                this.$backendApiConsumer.upsertEntrevista(
                    this.idCeremonia,
                    this.$i18n.locale as AppLocales,
                    this.versionEntrevista,
                    true,
                    this.fichaDifunto,
                    this.$store.state.respuestasEntrevista
                );

                const fotosForm = this.$store.state.fotosDifuntoAudiovisual;
                const subeFotos = fotosForm.filter((foto) => foto.foto !== null);
                this.$backendApiConsumer.upsertFotos(subeFotos, this.idCeremonia);

                this.$emit("completa-entrevista", {
                    respuestas: formateadas,
                    difunto: this.difunto,
                } as EntrevistaCompletada);
            } else {
                this.notificaInvalidezInputs();
            }
        },
        formateaRespuestas(respuestasEntrevista: RespuestasEntrevistaForm) {
            const menciones: Menciones = {};
            if (respuestasEntrevista.menciones.length > 0) {
                for (const mencion of respuestasEntrevista.menciones) {
                    if (PARENTESCOS.familia.map(this.$t).indexOf(mencion.parentesco) !== -1) {
                        if (menciones["familia"] === undefined) {
                            menciones["familia"] = [];
                        }

                        menciones["familia"].push(mencion);
                    }
                    if (PARENTESCOS.amigos.map(this.$t).indexOf(mencion.parentesco) !== -1) {
                        if (menciones["amigos"] === undefined) {
                            menciones["amigos"] = [];
                        }

                        menciones["amigos"].push(mencion.nombre);
                    }
                    if (PARENTESCOS.otros.map(this.$t).indexOf(mencion.parentesco) !== -1) {
                        if (menciones["otros"] === undefined) {
                            menciones["otros"] = [];
                        }

                        menciones["otros"].push(mencion.nombre);
                    }
                }
            }

            if (respuestasEntrevista.audiovisualAutomatico.fraseDespedida === "") {
                this.$store.commit("updateGenericoState", {
                    prop: "respuestasEntrevista.audiovisualAutomatico.fraseDespedida",
                    value: this.$t(
                        "formEntrevista.serviciosExternos.personalizacionAudiovisual.fraseDespedidaPlaceholder"
                    ),
                });
            }

            const reperAux = respuestasEntrevista.servicioMusical.repertorio;
            const transformaPieza = (pieza: SelectedValue) => {
                if (typeof pieza.value === "string") {
                    return {
                        id: PIEZA_SIN_REGISTRO,
                        titulo: pieza.value,
                    } as PiezaMusicalAppTextos;
                } else {
                    return {
                        id: pieza.value.value,
                        titulo: pieza.value.placeholder,
                    } as PiezaMusicalAppTextos;
                }
            };

            const servicioMusical: ServicioMusical = {
                musicaContratada: respuestasEntrevista.servicioMusical.musicaContratada,
                repertorio: {
                    pieza1: transformaPieza(reperAux.pieza1),
                    pieza2: transformaPieza(reperAux.pieza2),
                    pieza3: transformaPieza(reperAux.pieza3),
                    pieza4: transformaPieza(reperAux.pieza4),
                },
            };

            return {
                ...respuestasEntrevista,
                menciones,
                servicioMusical,
                ...((
                    ["caracter", "actitud", "vinculos", "recuerdo", "aficiones"] as (keyof RespuestasEntrevistaForm)[]
                ).reduce((atributosDesarrollados, prop) => {
                    atributosDesarrollados[prop] = componeDesarrollables(
                        respuestasEntrevista[prop] as AlternativasSelectoresT,
                        this.$i18n.locale,
                        this.difunto
                    );
                    return atributosDesarrollados;
                }, {} as { [key: string]: Desarrollable[] }) as {
                    caracter: Desarrollable[];
                    actitud: Desarrollable[];
                    vinculos: Desarrollable[];
                    recuerdo: Desarrollable[];
                    aficiones: Desarrollable[];
                }),
            };
        },
        validaInputs() {
            if (
                this.fichaDifunto.nombre === "" ||
                this.fichaDifunto.apellidos === "" ||
                this.fichaDifunto.edad === undefined ||
                this.fichaDifunto.sexo === -1 ||
                this.fichaDifunto.tipoMuerte === -1 ||
                this.respuestasEntrevista_enfoque === -1
            ) {
                return false;
            } else {
                return true;
            }
        },
        notificaInvalidezInputs() {
            let campos = "";
            if (this.fichaDifunto.nombre === "")
                campos += `1 - ${this.$t("formEntrevista.datosGenerales.nombre")}<br />`;
            if (this.fichaDifunto.apellidos === "")
                campos += `1 - ${this.$t("formEntrevista.datosGenerales.apellidos")}<br />`;
            if (this.fichaDifunto.edad === undefined)
                campos += `1 - ${this.$t("formEntrevista.datosGenerales.edad")}<br />`;
            if (this.fichaDifunto.sexo === -1) campos += `1 - ${this.$t("formEntrevista.datosGenerales.sexo")}<br />`;
            if (this.fichaDifunto.tipoMuerte === -1)
                campos += `1 - ${this.$t("formEntrevista.datosGenerales.tipoMuerte")}<br />`;
            if (this.respuestasEntrevista_enfoque === -1)
                campos += `2 - ${this.$t("formEntrevista.enfoque.tituloPregunta")}<br />`;

            Alerta.warning(
                this.$t("formEntrevista.formValidationAlert.titulo"),
                this.$t("formEntrevista.formValidationAlert.mensaje") + "<br />" + campos
            );
        },
        guardaEntrevistaIncompleta() {
            this.$backendApiConsumer
                .upsertEntrevista(
                    this.idCeremonia,
                    this.$i18n.locale as AppLocales,
                    this.versionEntrevista,
                    false,
                    this.fichaDifunto,
                    this.$store.state.respuestasEntrevista
                )
                .then(() => {
                    Alerta.success(this.$t("formEntrevista.guardaEntrevista.exitoGuardadoAlert"));
                })
                .catch(() => {
                    Alerta.error(
                        this.$t("generico.errors.error"),
                        this.$t("formEntrevista.guardaEntrevista.falloGuardadoAlert")
                    );
                });

            const fotosForm = this.$store.state.fotosDifuntoAudiovisual;
            const subeFotos = fotosForm.filter((foto) => foto.foto !== null);
            this.$backendApiConsumer.upsertFotos(subeFotos, this.idCeremonia);
        },
    },
    created() {
        if (this.continuaFichaDifunto !== undefined) {
            this.fichaDifunto = this.continuaFichaDifunto as FichaDifuntoForm;
        }

        if (this.continuaRespuestasEntrevista !== undefined) {
            this.$store.commit("inicializaRespuestasEntrevista", {
                respuestasEntrevista: this.continuaRespuestasEntrevista,
            });

            this.$backendApiConsumer.getFotosCeremonia(this.idCeremonia).then((fotos) => {
                if (fotos.length > 0) {
                    this.$store.state.fotosDifuntoAudiovisual = fotos;
                }
            });
        }
    },
    props: {
        idCeremonia: {
            required: true,
            type: String,
        },
        continuaFichaDifunto: {
            required: false,
            type: Object,
        },
        continuaRespuestasEntrevista: {
            required: false,
            type: Object,
        },
        versionEntrevista: {
            required: true,
            type: String,
        },
    },
    components: {
        AccordionContainer,
        AccordionItem,
        FormEntrevistaPreguntaDatosGenerales,
        FormEntrevistaPreguntaEnfoque,
        FormEntrevistaPreguntaServiciosExternos,
        FormEntrevistaPreguntaCaracter,
        SelectorAtributosCheckboxes,
        FormEntrevistaPreguntaVinculos,
        FormEntrevistaPreguntaMenciones,
        FormEntrevistaPreguntaAficiones,
        FormEntrevistaPreguntaRecuerdo,
        FormEntrevistaPreguntaIntervenciones,
        FormEntrevistaPreguntaAtributosAdicionales,
    },
    emits: ["completa-entrevista"],
});
