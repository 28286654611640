import QRCode from "qrcode";

import { Ceremonia } from "@/types/dominio-ceremonia-civil";
import { GeneracionAudiovisuales } from "@/types/auxiliares-logica-app";
import { getTypedErrorMessage, unhighlightHTML } from "@/modules/helpers";

function titulaCeremonia(titulo: string, textoCeremonia: string) {
    return `<h2 style="text-align: center;">${titulo}</h2>${textoCeremonia}`;
}

function generaPdfFromHtml(htmlString: string) {
    return fetch(process.env.VUE_APP_BACKEND_API_URL + "/generapdf", {
        method: "post",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "html-ceremonia": htmlString,
        }),
    })
        .then((response) => {
            return response.blob().then((blob) => blob.slice(0, blob.size, "application/pdf;charset=utf-8"));
        })
        .catch((err) => {
            throw new Error(`Error convirtiendo el HTML string a PDF.\nError: ${err}\nString: ${htmlString}`);
        });
}

function encuadraPartePersonal(fullnameDifunto: string, htmlPartePersonal: string, dataUrlQR: string) {
    return `<html>
<head>
    <style>
        @page {
            margin: 0;
            padding: 0;
        }

        body {
            padding: 0;
            margin: 150px 200px 0 200px;
        }
    </style>
</head>

<body>
    <div margin: 0; padding: 0;">
        <p style="color: purple; text-align: center; font-size: 68px; margin: 0;">
            <b>${fullnameDifunto}</b>
        </p>
        <div
            id="texto-parte-personal"
            style="font-size: 56px; text-align: justify; text-justify: inter-word;"
        >
            ${htmlPartePersonal}
        </div>
        <div style="text-align: center; margin-top: 130px;">
            <img style="width: 500px;" src="${dataUrlQR}" />
        </div>
    </div>
</body>
</html>
    `;
}

function encuadraContenidoCeremonia(mainHtmlString: string, headerContent: string) {
    const backendDocRoot = process.env.VUE_APP_BACKEND_DOCUMENT_ROOT;

    // Depende de los DPI definidos en la config (backend) de DomPDF
    const FACTOR_FUENTE_DPI = 3;
    const mainHTMLresized = mainHtmlString.replaceAll(/font-size:(\d+)px/g, (match: string, p1: string) => {
        return "font-size:" + parseInt(p1) * FACTOR_FUENTE_DPI + "px";
    });

    return `<html>
<head>
    <style>
        @page {
            margin: 360px 225px 240px 225px;
        }

        footer {
            position: fixed; 
            bottom: -120px; 
            right: -90px;
            height: 60px; 

            text-align: right;
            line-height: 105px;
        }

        footer .page-number:after {
            content: counter(page);
        }

        header {
            position: fixed;
            top: -180px;
            left: 0px;
            right: 0px;
            height: 360px;

            text-align: right;
            line-height: 105px;
        }

        .header-logo {
            max-height: 150px;
            width: auto;
            height: auto;

            position: absolute;
            top: -20;
            left: 0;
        }

        main {
            font-size: 60px;
            text-align: justify;
            text-justify: inter-word;
        }

        hr {
            height: 2px;
            border-width:0;
            color:gray;
            background-color:gray
        }
    </style>
</head>

<body>
    <header>
        <img class="header-logo" src="${backendDocRoot}/frontend/src/assets/img/nessun-dorma-logo-header-ceremonia.jpeg">
        ${headerContent}
        <hr>
    </header>

    <footer>
        <span class="page-number"></span>
    </footer>

    <main>
        ${mainHTMLresized}
    </main>
</body>
</html>`;
}

function generaCeremoniaCompleta({ ceremonia, textoCompleto }: { ceremonia: Ceremonia; textoCompleto: string }) {
    return generaPdfFromHtml(
        encuadraContenidoCeremonia(
            titulaCeremonia(ceremonia.titulo, textoCompleto),
            `${ceremonia.difunto.nombre} ${ceremonia.difunto.apellidos}`
        )
    );
}

function generaCeremoniaCompletaSeccionada({ ceremonia }: { ceremonia: Ceremonia }) {
    const seccionesRellenadas = ceremonia.seccionesRellenadas.filter((seccion) => seccion.textoRellenado !== "");
    let htmlCeremoniaCompletaSeccionada = "";
    for (let i = 0; i < seccionesRellenadas.length; i++) {
        const pageBreak = i < seccionesRellenadas.length - 1 ? ' style="page-break-after: always;"' : "";
        htmlCeremoniaCompletaSeccionada = `${htmlCeremoniaCompletaSeccionada}<section${pageBreak}>${seccionesRellenadas[i].textoRellenado}</section>`;
    }

    return generaPdfFromHtml(
        encuadraContenidoCeremonia(
            titulaCeremonia(ceremonia.titulo, htmlCeremoniaCompletaSeccionada),
            `${ceremonia.difunto.nombre} ${ceremonia.difunto.apellidos}`
        )
    );
}

function generaLinkAudiovisual({
    linksAudiovisuales,
    nombreCompletoDifunto,
}: {
    linksAudiovisuales: GeneracionAudiovisuales;
    nombreCompletoDifunto: string;
}) {
    if (
        linksAudiovisuales.linkPreceremonial === undefined &&
        linksAudiovisuales.linkEntrega === undefined &&
        linksAudiovisuales.linkCrematori === undefined
    ) {
        throw new Error(
            "Error en la selección de Documentos Resultado, se declaró un documento que tiene como dependencia la generación del audiovisual."
        );
    }
    const backendDocRoot = process.env.VUE_APP_BACKEND_DOCUMENT_ROOT;

    const htmlLinkPreceremonial = linksAudiovisuales.linkPreceremonial
        ? `<div style="width: 60%; margin: 25px auto; padding: 30px; border-radius: 30px; background-color: #ffb15e;"><a style="color: inherit;" href="${linksAudiovisuales.linkPreceremonial}">Audiovisual para la Ceremonia</a></div>`
        : "";
    const htmlLinkEntrega = linksAudiovisuales.linkEntrega
        ? `<div style="width: 60%; margin: 25px auto; padding: 30px; border-radius: 30px; background-color: #73b9ff;"><a style="color: inherit;" href="${linksAudiovisuales.linkEntrega}">Audiovisual de Entrega</a></div>`
        : "";
    const htmlLinkCrematori = linksAudiovisuales.linkCrematori
        ? `<div style="width: 60%; margin: 25px auto; padding: 30px; border-radius: 30px; background-color: #64fa8c;"><a style="color: inherit;" href="${linksAudiovisuales.linkCrematori}">Audiovisual Crematori</div>`
        : "";

    return generaPdfFromHtml(
        `<div style="width: 100%; text-align: center;"><div style="width: 50%; margin: 0 auto;"><img style="width: 100%;" src="${backendDocRoot}/frontend/src/assets/img/nessun-dorma-logo-header-ceremonia.jpeg"></div><div style="margin: 48px;">Links de descarga de los audiovisuales para: <div style="margin: 64px; font-size: 64px;"><b>${nombreCompletoDifunto}</b></div></div> ${htmlLinkPreceremonial}${htmlLinkEntrega}${htmlLinkCrematori}</div>`
    );
}

function generaPartePersonalEntrega({
    partePersonal,
    linkAudiovisual,
    nombreCompletoDifunto,
}: {
    partePersonal: string;
    linkAudiovisual: string;
    nombreCompletoDifunto: string;
}) {
    if (linkAudiovisual === undefined) {
        throw new Error(
            "Error en la selección de Documentos Resultado, se declaró un documento que tiene como dependencia la generación del audiovisual."
        );
    }

    const unhighlightedPartePersonal = unhighlightHTML(partePersonal);
    return QRCode.toDataURL(linkAudiovisual, { errorCorrectionLevel: "H", version: 10 })
        .then((url) => {
            return generaPdfFromHtml(encuadraPartePersonal(nombreCompletoDifunto, unhighlightedPartePersonal, url));
        })
        .then((blob) => blob);
}

export function generaDocumentos(
    nombreCompletoDifunto: string,
    docs: { nombre: string; deps: Record<string, unknown> }[]
) {
    const nombre = nombreCompletoDifunto.replaceAll(" ", "_");
    const documentoNombreCallback: {
        [key: string]: {
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            cb: (...args: any[]) => Promise<Blob>;
            nombreArchivo: string;
        };
    } = {
        ceremoniaCompleta: {
            cb: generaCeremoniaCompleta,
            nombreArchivo: `${nombre}-ceremonia_completa.pdf`,
        },
        ceremoniaCompletaSeccionada: {
            cb: generaCeremoniaCompletaSeccionada,
            nombreArchivo: `${nombre}-ceremonia_seccionada.pdf`,
        },
        partePersonal: {
            cb: generaPartePersonalEntrega,
            nombreArchivo: `${nombre}-parte_personal.pdf`,
        },
        linkAudiovisual: {
            cb: generaLinkAudiovisual,
            nombreArchivo: `${nombre}-link_audiovisual.pdf`,
        },
    };

    type ErrorGeneracion = { errorGeneracion: { usrErrorMsg: string; errorCause: string } };
    type GeneracionPromise = Promise<Blob | ErrorGeneracion>;
    const promises: GeneracionPromise[] = [];
    const nombresArchivos: string[] = [];
    for (const doc of docs) {
        const genera = documentoNombreCallback[doc.nombre];

        let documentoGenerado: GeneracionPromise | null = null;
        let errorGeneracion: ErrorGeneracion | null = null;
        if (genera !== undefined) {
            try {
                documentoGenerado = genera.cb(doc.deps);
            } catch (cbError) {
                const errorMsg = getTypedErrorMessage(cbError);

                errorGeneracion = {
                    errorGeneracion: {
                        usrErrorMsg: `No se pudo descargar el documento: ${doc.nombre}`,
                        errorCause: errorMsg,
                    },
                };
            }
        } else {
            errorGeneracion = {
                errorGeneracion: {
                    usrErrorMsg: `No se pudo descargar el documento: ${doc.nombre}`,
                    errorCause: "Se intentó generar un documento de resultado no registrado con nombre: " + doc.nombre,
                },
            };
        }

        if (errorGeneracion !== null) {
            documentoGenerado = Promise.resolve(errorGeneracion);
        }

        if (documentoGenerado === null) {
            throw new Error("Error de lógica imposible. Cuestión de tipado.");
        } else {
            promises.push(documentoGenerado);
            nombresArchivos.push(genera.nombreArchivo);
        }
    }

    return Promise.all(promises).then((docs) => {
        const documentosResultado = [];
        for (let i = 0; i < docs.length; i++) {
            if (Object.getOwnPropertyNames(docs[i]).indexOf("errorGeneracion") !== -1) {
                documentosResultado.push({
                    nombreArchivo: nombresArchivos[i],
                    error: (docs[i] as ErrorGeneracion).errorGeneracion,
                });
            } else {
                documentosResultado.push({
                    nombreArchivo: nombresArchivos[i],
                    blob: docs[i] as Blob,
                });
            }
        }

        return documentosResultado;
    });
}
