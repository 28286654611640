export enum Sexo {
    Hombre,
    Mujer,
}

export enum TipoMuerte {
    Esperada,
    Inesperada,
}

export enum TipoRelaciones {
    Positivo,
    Conflictivo,
}

export enum RangoEdad {
    Infante,
    Joven,
    Adulto,
    Anciano,
}

export type FichaDifunto = {
    nombre: string;
    apellidos: string;
    alias: string;
    lugarNacimiento: string;
    edad: number;
    sexo: Sexo;
    tipoMuerte: TipoMuerte;
    comunidadNacimiento: string;
};

export type Difunto = FichaDifunto & {
    tipoRelaciones: TipoRelaciones;
};

export function rangoEdadALos(edadAnyos: number): RangoEdad {
    let rangoEdad = -1;
    if (edadAnyos > 60) {
        rangoEdad = RangoEdad.Anciano;
    } else if (edadAnyos > 30) {
        rangoEdad = RangoEdad.Adulto;
    } else if (edadAnyos > 15) {
        rangoEdad = RangoEdad.Joven;
    } else {
        rangoEdad = RangoEdad.Infante;
    }

    return rangoEdad;
}
