
import { defineComponent, PropType } from "vue";

import AccordionContainer from "@/components/AccordionContainer.vue";
import AccordionItem from "@/components/AccordionItem.vue";

import ServiciosExternosReperorio from "@/components/FormEntrevistaPreguntas/FormEntrevista_ServiciosExternos_Repertorio.vue";
import ServiciosExternosPoema from "@/components/FormEntrevistaPreguntas/FormEntrevista_ServiciosExternos_Poema.vue";
import ServiciosExternosAudiovisualExtras from "@/components/FormEntrevistaPreguntas/FormEntrevista_ServiciosExternos_AudiovisualExtras.vue";

import { Poema } from "@/types/dominio-ceremonia-civil";
import { ServicioMusicalForm, TemaFondoAudiovisual } from "@/types/auxiliares-logica-app";

export default defineComponent({
    data(): {
        accordionItemsIds: number[];
    } {
        return {
            accordionItemsIds: [1, 2, 3],
        };
    },
    computed: {
        idPoemaEscogidoBridge: {
            get(): Poema["id_grabacion"] {
                return this.idPoema;
            },
            set(newVal: Poema["id_grabacion"]) {
                const idPoema = newVal;

                this.$emit("update:idPoema", idPoema);
            },
        },
        sinPoemaEnCeremonialBridge: {
            get() {
                return this.sinPoemaEnCeremonial;
            },
            set(newVal: boolean) {
                this.$emit("update:sinPoemaEnCeremonial", newVal);
            },
        },
        codigoTemaEscogido: {
            get(): TemaFondoAudiovisual["codigoTema"] {
                return this.codigoTema;
            },
            set(newVal: TemaFondoAudiovisual["codigoTema"]) {
                this.$emit("update:codigoTema", newVal);
            },
        },
        fraseDespedidaBridge: {
            get() {
                return this.fraseDespedida;
            },
            set(newVal: string) {
                this.$emit("update:fraseDespedida", newVal);
            },
        },
        servicioMusicalBridge: {
            get() {
                return this.servicioMusical;
            },
            set(newServices: ServicioMusicalForm) {
                this.$emit("update:servicioMusical", newServices);
            },
        },
        bloqueoCrematorio() {
            return this.bloqueoCrematorioMemora || this.bloqueoCrematorioTerrassa;
        },
    },
    props: {
        servicioMusical: {
            type: Object as PropType<ServicioMusicalForm>,
            required: true,
        },
        idPoema: {
            type: Number,
            required: true,
        },
        sinPoemaEnCeremonial: {
            type: Boolean,
            required: true,
        },
        codigoTema: {
            type: String as PropType<TemaFondoAudiovisual["codigoTema"]>,
            required: true,
        },
        fraseDespedida: {
            type: String as PropType<string>,
            required: true,
        },
        bloqueoCrematorioTerrassa: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: false,
        },
        bloqueoCrematorioMemora: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: false,
        },
    },
    emits: [
        "update:servicioMusical",
        "update:idPoema",
        "update:sinPoemaEnCeremonial",
        "update:codigoTema",
        "update:fraseDespedida",
    ],
    components: {
        AccordionContainer,
        AccordionItem,
        ServiciosExternosReperorio,
        ServiciosExternosPoema,
        ServiciosExternosAudiovisualExtras,
    },
});
