import { TraduccionCheckboxes } from "@/modules/FormEntrevistaSelectoresPorPregunta";
import { ConnotacionAtributo } from "@/types/dominio-ceremonia-civil";

export const alternativasCatala: TraduccionCheckboxes = {
    checkboxesCaracter: [
        {
            id: 1,
            label: "Obert((/a))",
            desarrollos: [
                "Sempre disposa((t/da)) a aprendre coses noves, a extreure de la vida tot el que ens pogui donar",
                "Acti((u/va)) i vital, amb aquelles ganes de fer coses, de viure la vida intensament, de sentir que la felicitat no és l'objectiu, és el camí",
                "Amb aquella capacitat d'adaptarse a cadascuna de les realitats de la vida, amb aquella força, aquella vitalitat que ((el/la)) feien tan especial",
                "Donant valor al que realment és important, aprendre a viure i a compartir la vida creixent dia a dia",
                "Sociable, li encantaba conèixer a la gent, relacionar-se, sentir, connectar amb allò que ens uneix els uns als altres",
                "Sempre obert((/a)) a profunditzar, comunicar, xerrar, entrar en l'altre per a conèixer i compartir el millor de cadascun",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 2,
            label: "Alegre",
            desarrollos: [
                "Amb aquell somriure que sempre tenia preparat per a tots vosaltres, propi de qui aprèn que la felicitat no passa per casualitat sinó per elecció",
                "Sempre amb ganes de somriure a la vida, trobant en cada moment l'alegria de viure",
                "Sabent que l'alegria és l'art de ser feliç i que la felicitat no es troba, es crea",
                "Que va entendre que la verdadera alegria es troba a les coses simples de la vida, una paraula, un gest, aquell somriure que sabia dibuixar als seus llabis i als de la seva gent",
                "Que entenia que l'alegria no és més que un plaer que es multiplica en compartir-lo amb els altres",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 3,
            label: "Positi((u/va))",
            desarrollos: [
                "Sabent que quan hi ha ganes, quan l'actitut acompanya, tot es pot",
                "Sempre mirant cap endavant conscient que la dificultat del camí és una oportunitat disfressada",
                "Conscient que mantenir l'actitut adequada causa una reacció en cadena de pensaments, esdeveniments i resultats positius",
                "Conscient que no som un producte de les nostres circumstancies sinó de les nostres propies decisions",
                "Preferint sempre veure el costat bo que totes les coses tenen",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 4,
            label: "Reserva((t/da))",
            desarrollos: [
                "Li costava expressar el que sentia, però sabia que el que mostrava era el vertader",
                "Sabent que ser mesurat és un art, no malgastaba cap paraula de més",
                "Prescindint de l'eloqüència per a expressar en paraules tot allò que era capaç de sentir",
                "Donant valor al que sentia més enllà de trobar la manera d'expressar-ho",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
        {
            id: 6,
            label: "Introverti((t/da))",
            desarrollos: [
                "Amb aquella habilitat que tenia per observar i reconèixer de forma sezilla l'essència de les coses",
                "Fidel al seu món interior, a la seva manera d'entendre el que li envoltava, sense demanar res ni molestar a ningú",
                "Trobant a la profunditat del seu ésser la companyia necessària per a defugir la seva soledat",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
        {
            id: 32,
            label: "De caràcter",
            desarrollos: ["Amb aquella personalitat tan contundent que ((ell/ella)) tenia."],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
    ],
    checkboxesVinculos: [
        {
            id: 7,
            label: "Familiar",
            desarrollos: [
                "La família sempre va ser el valor més preat, un somni fet realitat que es va gestar al costat del seu amor i es va consolidar amb el temps, creixent i alimentant-se de respecte i ternura",
                "Que va entendre que la família és el tresor més gran que es pot aconseguir",
                "Que va comprendre que la família és on la vida comença i l'amor mai termina, i que formar part d'una família significa que estimaràs i seràs estimat sempre i sense condició",
                "Va construïr una família, un lloc on anar, algú a qui amar, el millor de tots els regals que la vida li va donar",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 10,
            label: "Ami((c/ga)) dels seus amics",
            desarrollos: [
                "Sempre tenia una mà extesa per a qui la necessités agafar, un somriure per poder animar, una mirada per saber comprendre, una llàgrima per unir al dolor de l'altre i una paraula per fer saber que sempre estava present",
                "Va entendre que una amistat no creix per la presència de les persones sinó per la màgia de saber que tot i que no la vegis la portes al cor",
                "Es va mantenir fidel a l'idea que l'amistat és un amor que no es comunica a través dels sentits, existeix i per això hem de valorar-la, mimar-la i cuidar-la com un petit i fràgil tresor",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 33,
            label: "Estima((t/da)) al seu entorn (Altres)",
            desarrollos: ["Estima((t/da)) per tots amb qui convivia i compartia el seu dia a dia"],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: false,
        },
        {
            id: 11,
            label: "Independent / Solit((ari/ària))",
            desarrollos: [
                "Que li agradava estar amb la seva pròpia companyia, gaudint dels seus pensaments, amb els seus projectes, els seus somnis...",
                "Que invertia el seu temps en entendre que l'important a la vida era seguir vivint i deixant viure",
                "Que es relacionava des del seu silenci dient molt més del que deia i fent-se entendre molt més del que parlava",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
    ],
    checkboxesAficiones: [
        {
            id: 12,
            label: "L'Art",
            desarrollos: [
                "La <b>música</b>, la seva música que tant li agradava escoltar, sentir i gaudir, emocions mesclades amb realitats presents i pasades que conformen una passió única",
                "La <b>pintura</b>, aquells traços sobre el llenç que imitaven la seva imaginació entre pincells, paisatges i retrats",
                "La <b>lliteratura</b>, els seus llibres, hores i hores de lectura que transportava la seva ment a aquells llocs plens de personatges amb les seves històries, vides, passions i sentiments",
                "El <b>cinema</b>, la màgia del cel·luloide que tantes emocions va ser capaç de regalar-li amb les seves escenes plenes d'alegries i penes, de somriures i llàgrimes",
                "El <b>teatre</b>, aquells textes que semblava impossible retenir, els nervis de les actuacions, els assajos...",
            ],
        },
        {
            id: 13,
            label: "La Cuina",
            desarrollos: [
                "Recordeu-l((o/a)) amb aquella facilitat que tenia per a la cuina i aquells plats únics, amb els que alimentava l'estómac però també el vostre cor",
                "Segurament una excusa per a tenir-vos a tots a prop, al voltant d'una taula i gaudint del que més li agradava fer, aquells plats inolvidables plens d'amor",
            ],
        },
        {
            id: 14,
            label: "L'Esport",
            desarrollos: [
                "Gaudia de tots els esports que seguia",
                "El futbol, la seva passió, una cita obligada, les seves esperançes posades en onze jugadors i una pilota que no sempre li donaven les alegries que esperava però que celebrava amb força cada vegada que sortien al camp",
                "El bàsquet, li encantava veure'l, senti-lo, vivir-lo",
                "El tennis, aquell silenci trencat per l'impacte de la pilota amb la raqueta, els sets que mai terminen...",
                "El motor, les carreres, els circuits, les curves, les voltes de reconeixement, els avançaments impossibles...",
            ],
        },
        {
            id: 15,
            label: "La Natura",
            desarrollos: [
                "El mar, les aigües cristalines, les ones, la platja, la sorra, els passejos, tot el que significaba ser a prop del mar, era un motiu per a somriure",
                "La montanya, aquells passejos entre els arbres, els senders llargs plens de terra i pedres, el so dels animals, l'aroma de les flors, paisatges únics que ((el/la)) conectaven amb allò més profund del seu ésser",
                "El seu hort, la seva terra, aquella terra que cultivava amb les seves mans per a rebre els aliments que collia i compartia amb tots vosaltres",
                "Gaudint de la tendresa que només la companyia dels nostres amics de quatre potes ens poden donar",
            ],
        },
        {
            id: 28,
            label: "El Treball",
            desarrollos: [
                "Sempre comprom((ès/esa)) amb projectes nous que emplenaven les hores, els dies i sobre tot aquell espai per a la seva creativitat",
                "El treball no com a mitjà per a la subsistència sinó com petites motivacions que donaven sentit al seu dia a dia",
                "Aquelles expectatives professionals, que donaven resposta a les ganes de crèixer, de superar-se com a persona",
            ],
        },
        {
            id: 20,
            label: "Les Manualitats",
            desarrollos: [
                "El bricolatge, era el que diem un 'manitas', amb aquella habilitat per fer, construir, refer i reconstruir, i tot amb les seves pròpies mans, des de zero i cuidant de tots els detalls",
                "La costura, cosir, aquest art que requereix de les mans per a crear de forma metòdica aquells detallls que decoren els records",
            ],
        },
    ],
    checkboxesActitud: [
        {
            id: 24,
            label: "Treballador((/a)) / Lluitador((/a))",
            desarrollos: [
                "Que va entendre que la vida dóna les batalles més difícils als millors lluitadors, i sense dubte va saber batallar per moure endavant la seva vida",
                "Que va saber comprendre que el vertader lluitador no és qui lluita amb els punys per un trofeu sinó qui lluita amb el cor per fer realitat els seus somnis, i va lluitar amb el cor fins que va realitzar el seu",
                "Que va comprendre que el denominador comú per assolir l'éxit és el treball i que l'éxit no és més que treballar durament per alguna cosa que amem",
                "Que sempre va saber que era més important aprendre a aixecar-se que rendir-se enfront de l'adversitat",
                "Li va tocar viure en una etapa difícil de la història del nostre país, corrien rumors de guerra, realitats de postguerra, dificultats de tot tipus que com tants coetanis va haver de superar, però sempre va mirar cap endavant, amb aquella capacitat de superació, amb aquelles ganes de construir una realitat millor per a si i per als seus",
                "Va viure una de les realitats més difícils d'entendre de la nostra història recent, una guerra que va deixar una postguerra, moments difícils que va haver de superar amb enteresa, amb aquella capacitat per a superar-se davant l'adversitat, però mai va mirar enrere, va buscar sempre un present millor per a poder construir el futur que mereixia",
                "Va haver de donar aquell pas endavant en un moment de màxima dificultat, un present tenyit pel pas de la guerra i la crudesa de la postguerra que no entèn de matisos. Realitats que va haver de superar amb l'enteresa de qui sap que l'esforç del dia a dia permetrà trobar el camí de la tranquilitat",
                "Va venir en busca de millors oportunitats, lluita, esforç i superació van ser els seus companys de viatge. Un viatge ple de dificultats que tenia l'estabilitat com a recompensa, que tenia l'objectiu d'arribar ala seguretat de qui es sap conscient de les seves limitacions però amb la motivació necessària per a seguir endavant",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 25,
            label: "Gener((ós/osa))",
            desarrollos: [
                "Va entendre que la generositat no és un acte puntual, és una forma de vida",
                "Gener((ós/osa)), no solament en allò material sinó en el més important, en l'amor, en el donar i rebre, sense condicions, sense demanar res a canvi",
                "Que va saber donar-se als altres desinteresadament, compartit el que era, el que tenia, transmetent aquella sensació de pau i d'amor que tots heu rebut",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 26,
            label: "Senzill((/a)) / Humil",
            desarrollos: [
                "Que no necessitaba de grans luxes a la seva vida, només petites coses que la feien més gran",
                "Sabent que al caràcter, a la forma, a l'estil, en totes les coses, la excel·lència suprema és a la senzillesa",
                "Que sabia valorar les petites coses de la vida, sense grandiloqüències ni parafernàlies que ocultessin l'autèntica veritat",
                "Que va saber veure que tot és molt difícil abans de ser senzill, i fidel al seu mandat va escollir fer-ho fàcil i viure amb humiltat i senzillesa",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 27,
            label: "Discret((/a)) / Prudent",
            desarrollos: [
                "Va comprendre que moltes vegades hem de parlar perquè ens escoltin, però d'altres hem de callar perquè ens valorin, i axí, des del silenci, ho deia tot",
                "Tenint clar que també es pot amar en silenci, enyorar sense cridar-ho, enamorar-se sense contar-ho i ser feliç sense publicar-ho",
                "Sabent que semblar discret val tant com entendre una cosa y és probablemet molt més fàcil i menys compromès",
                "No gaudia sent el centre d'atenció però sabia ser el centre d'allò que era important",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
    ],
    checkboxesRecuerdo: [
        {
            id: 29,
            label: "Per les seves aficions",
            desarrollos: [
                "Amb tot el que li agradava fer i que identificava les seves prioritats, emplenant aquell buit de temps amb experiències inolvidables",
            ],
        },
        {
            id: 30,
            label: "Amb la família",
            desarrollos: [
                "Al vostre costat, units des de l'afecte, amb tots qui heu format aquest somni que va transformar en una realitat plena d'amor i de tendresa",
            ],
        },
        {
            id: 31,
            label: "Com una bona persona",
            desarrollos: [
                "Amb tot allò bo que hem descrit aquí avui i que ((el/la)) defineixen com el que ha estat per a tots vosaltres: una persona bona, una bona persona",
                "Fidel a la seva essència, a la seva manera de veure, de sentir i d'entendre la vida",
                "Amb la tranquilitat d'haber viscut en pau i harmonia amb el seu propi ésser i el seu entorn",
            ],
        },
    ],
};
