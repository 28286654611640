export class ObjectTraversingError extends Error {}

function throwObjectTraversingError(propPath: string) {
    throw new ObjectTraversingError(`No existe la propiedad '${propPath}' en el objeto dado.`);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function accessObjectSubpropertyByString(baseObject: { [prop: string]: any }, propertyPath: string) {
    const nestedProperties = propertyPath.split(/[[\].]/g).filter((property: string) => property !== "");

    let targetValue;
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        targetValue = nestedProperties.reduce((penetratedObject: any, nextProp: string) => {
            return penetratedObject[nextProp];
        }, baseObject);
    } catch (error) {
        if (error instanceof TypeError) {
            throwObjectTraversingError(propertyPath);
        } else {
            throw error;
        }
    }

    if (targetValue === undefined) {
        throwObjectTraversingError(propertyPath);
    }

    return targetValue;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function assignPropertyByPath(object: { [prop: string]: any }, propertyPath: string, value: any) {
    if (propertyPath === "") {
        return value;
    }

    let subObject = object;
    const props = propertyPath.split(".");
    for (let i = 0; i < props.length - 1; i++) {
        if (subObject[props[i]] === undefined) {
            subObject[props[i]] = {};
        }

        subObject = subObject[props[i]];
    }

    subObject[props[props.length - 1]] = value;

    return object;
}
