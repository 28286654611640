import { computed, createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import BackendAPIConsumerFactory from "@/classes/BackendAPIConsumerFactory";
import BackendAPIConsumer from "@/classes/BackendAPIConsumer";
import { BACKEND_API_CONSUMER, T, LOCALE } from "@/symbols";
import { AppLocales } from "@/types/auxiliares-logica-app";

import "/node_modules/flag-icons/css/flag-icons.min.css";

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $backendApiConsumer: BackendAPIConsumer;
    }
}

const app = createApp(App).use(i18n).use(store).use(router);

const backendApiConsumer = BackendAPIConsumerFactory.create();

app.provide(BACKEND_API_CONSUMER, backendApiConsumer);
app.provide(T, i18n.global.t);
app.provide(
    LOCALE,
    computed({
        get() {
            return i18n.global.locale as AppLocales;
        },
        set(locale: AppLocales) {
            i18n.global.locale = locale;
        },
    })
);

app.config.globalProperties.$backendApiConsumer = backendApiConsumer;

app.mount("#app");
