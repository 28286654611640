
import { defineComponent } from "vue";

import SelectorAtributosCheckboxes from "@/components/SelectorAtributosCheckboxes.vue";
import CustomCheckBox from "@/components/CustomCheckBox.vue";

export default defineComponent({
    data() {
        return {
            //Solo ha de contener un valor, pero es un Set porque se utiliza en un CustomCheckBox
            rasgoTipologiaPersonaConflictiva: new Set<string>(),
        };
    },
    computed: {
        isPersonaConflictivaAux(): boolean {
            return this.rasgoTipologiaPersonaConflictiva.has("conflictiva");
        },
        eligioCeremoniaPersonalizadaBridge: {
            get(): boolean {
                return this.eligioCeremoniaPersonalizada;
            },
            set(newVal: boolean) {
                this.$emit("update:eligioCeremoniaPersonalizada", newVal);
            },
        },
    },
    watch: {
        isPersonaConflictivaAux(newVal: boolean) {
            this.$emit("update:isPersonaConflictiva", newVal);
        },
    },
    methods: {
        eligeCeremoniaPersonalizada() {
            this.eligioCeremoniaPersonalizadaBridge = true;
        },
    },
    mounted() {
        if (this.$store.state.respuestasEntrevista.isPersonaConflictiva) {
            this.rasgoTipologiaPersonaConflictiva.add("conflictiva");
            this.eligioCeremoniaPersonalizadaBridge = true;
        }
    },
    props: {
        difunto: {
            type: Object,
            required: true,
        },
        isPersonaConflictiva: {
            type: Boolean,
            required: true,
        },
        eligioCeremoniaPersonalizada: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["desarrollo-atributo", "update:isPersonaConflictiva", "update:eligioCeremoniaPersonalizada"],
    components: {
        CustomCheckBox,
        SelectorAtributosCheckboxes,
    },
});
