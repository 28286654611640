import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "image-uploader-list__el" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ImageUploader = _resolveComponent("ImageUploader")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createBlock(_component_draggable, {
    class: "image-uploader-list",
    modelValue: _ctx.listaFotos,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.listaFotos) = $event)),
    "item-key": "id"
  }, {
    item: _withCtx(({ element }) => [
      _createElementVNode("div", _hoisted_1, [
        (element.foto != null)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "delete-image-button",
              type: "button",
              onClick: ($event: any) => (_ctx.removeInput(element.id))
            }, [
              _createVNode(_component_ion_icon, {
                class: "delete-image-button__close-icon",
                name: "close-outline"
              })
            ], 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createVNode(_component_ImageUploader, {
          foto: element.foto,
          "onUpdate:foto": ($event: any) => (_ctx.store.commit('updateFoto', { id: element.id, content: $event }))
        }, null, 8, ["foto", "onUpdate:foto"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}