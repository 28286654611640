import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "option-repertorio" }
const _hoisted_2 = { class: "option-repertorio--info-pieza" }
const _hoisted_3 = { class: "option-repertorio--titulo" }
const _hoisted_4 = { class: "option-repertorio--autor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.option.tituloPieza), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.option.autor), 1)
    ]),
    (_ctx.option.isReproducible)
      ? (_openBlock(), _createBlock(_component_ion_icon, {
          key: 0,
          name: "musical-notes",
          class: "option-repertorio--icono-reproducible"
        }))
      : _createCommentVNode("", true)
  ]))
}