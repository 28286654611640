import { Store } from "vuex";
import {
    accessObjectSubpropertyByString,
    assignPropertyByPath,
    ObjectTraversingError,
} from "@/modules/ProgrammaticObjectTraversing";

export function generaMutationGenerica<State>(fromKey?: string) {
    let nombre = "updateGenericoState";
    if (fromKey !== undefined) {
        nombre = "update_" + fromKey.replace(/\./g, "_");
    }

    return {
        nombre,
        handler: (state: State, payload: { prop: string; value: unknown }) => {
            let baseObject = state;
            if (fromKey !== undefined) {
                baseObject = accessObjectSubpropertyByString(state, fromKey);

                if (baseObject === undefined) {
                    throw new Error(`No existe key '${fromKey}' como base para la mutation genérica.`);
                }
            }

            if (payload.prop === undefined) {
                throw new Error(
                    `La payload de la mutación '${nombre}' requiere que se especifique la key de una subprop para actualizar.`
                );
            }

            if (payload.value === undefined) {
                throw new Error(
                    `La payload de la mutación '${nombre}' requiere que se especifique el valor a asignar a la subprop '${payload.prop}'.`
                );
            }

            assignPropertyByPath(baseObject, payload.prop, payload.value);
        },
    };
}

export function generaComputedParaProps<State>(
    props: string[],
    store: Store<State>,
    nombreMutationGenerica: string,
    basePath?: string
) {
    const computeds: { [key: string]: { get: () => unknown; set: (val: unknown) => void } } = {};

    let baseComputedName = "";
    if (basePath !== undefined && basePath !== "") {
        baseComputedName = basePath.replace(/\./g, "_") + "_";
    }
    const computedNames = props.map((propName) => {
        return baseComputedName + propName.replace(/\./g, "_");
    });

    let basePropPath = "";
    if (basePath !== undefined && basePath !== "") {
        basePropPath = basePath + ".";
    }
    for (let i = 0; i < computedNames.length; i++) {
        const propPath = basePropPath + props[i];
        try {
            accessObjectSubpropertyByString(store.state, propPath);
        } catch (error) {
            if (error instanceof ObjectTraversingError) {
                throw new Error(
                    `No existe la propiedad '${propPath}' en el State de la Store, para la cual se quiere crear un Computed prop getter.`
                );
            } else {
                throw error;
            }
        }

        const get = () => {
            return JSON.parse(JSON.stringify(accessObjectSubpropertyByString(store.state, propPath)));
        };

        const set = (newVal: unknown) => {
            const dereferencedNewVal = JSON.parse(JSON.stringify(newVal));
            store.commit(nombreMutationGenerica, {
                prop: props[i],
                value: dereferencedNewVal,
            });
        };

        computeds[computedNames[i]] = {
            get,
            set,
        };
    }

    return computeds;
}
