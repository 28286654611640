
import { computed, defineComponent, onMounted, PropType, Ref, ref } from "vue";

import { v4 as uuidv4 } from "uuid";

import FormEntrevistaBloqueado from "@/components/FormEntrevistaBloqueado.vue";

import { useStore } from "@/store/index";
import { Ceremonia, EntrevistaCompletada, SeccionRellenada } from "@/types/dominio-ceremonia-civil";
import { BACKEND_API_CONSUMER, LOCALE, strictInject, T } from "@/symbols";
import {
    AppLocales,
    FichaDifuntoForm,
    RespuestasEntrevistaForm,
    versionesEntrevista,
} from "@/types/auxiliares-logica-app";
import useConfigPorEntorno from "@/composables/useConfigPorEntorno";
import useAudiovisualGenerator from "@/composables/useAudiovisualGenerator";
import useGeneradorDocumentosResultado from "@/composables/useGeneradorDocumentosResultado";
import router from "@/router";

export default defineComponent({
    setup(props) {
        const t = strictInject(T);
        const locale = strictInject(LOCALE);
        const backendApiConsumer = strictInject(BACKEND_API_CONSUMER);
        const store = useStore();

        const idCeremoniaNueva = ref(uuidv4());
        const idCeremonia = computed(() => {
            if (props.idCeremoniaContinuada !== undefined) {
                return props.idCeremoniaContinuada;
            } else {
                return idCeremoniaNueva.value;
            }
        });

        function generaAudiovisualCrematorio(entrevista: EntrevistaCompletada) {
            const ceremonia: Ref<Ceremonia> = ref({
                uuidv4: idCeremonia.value,
                respuestasEntrevista: entrevista.respuestas,
                difunto: entrevista.difunto,
                titulo: "",
                seccionesRellenadas: [] as SeccionRellenada[],
            });

            const { configEntorno } = useConfigPorEntorno();
            const { generaAudiovisuales } = useAudiovisualGenerator(
                backendApiConsumer,
                locale.value,
                t,
                configEntorno,
                ceremonia,
                store.state.fotosDifuntoAudiovisual
                    .filter((foto) => foto.foto !== null)
                    .map((foto) => foto.foto) as File[]
            );

            generaAudiovisuales().then((linksAudiovisuales) => {
                if (linksAudiovisuales.linkCrematori) {
                    window.open(linksAudiovisuales.linkCrematori, "_blank");
                }
                location.reload();
            });
        }

        onMounted(() => {
            if (props.idioma !== undefined) {
                locale.value = props.idioma;
            }
        });

        const rutaTipoEntrevista = router.currentRoute.value.name;

        return {
            idCeremonia,
            generaAudiovisualCrematorio,
            rutaTipoEntrevista,
        };
    },
    props: {
        idCeremoniaContinuada: {
            required: false,
            type: String as PropType<Ceremonia["uuidv4"]>,
        },
        idioma: {
            required: false,
            type: String as PropType<AppLocales>,
        },
        fichaDifunto: {
            required: false,
            type: Object as PropType<FichaDifuntoForm>,
        },
        respuestasEntrevista: {
            required: false,
            type: Object as PropType<RespuestasEntrevistaForm>,
        },
        bloqueoCrematorioTerrassa: {
            required: false,
            type: Boolean,
            default: false,
        },
        bloqueoCrematorioMemora: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    components: {
        FormEntrevistaBloqueado,
    },
});
