import { createStore, Store, useStore as baseUseStore } from "vuex";

import { Usuario } from "@/interfaces/AuthenticatorI";
import { FotoDifuntoAudiovisual, Intervencion, PIEZA_NULL, POEMA_NULL } from "@/types/dominio-ceremonia-civil";
import { RespuestasEntrevistaForm, STARTING_ID_FOTOS } from "@/types/auxiliares-logica-app";
import { generaMutationGenerica } from "@/store/helpers-generadores";
import {
    accessObjectSubpropertyByString,
    ObjectTraversingError,
    assignPropertyByPath,
} from "@/modules/ProgrammaticObjectTraversing";

export interface State {
    session: {
        user: Usuario;
        startedAt: string;
    } | null;
    respuestasEntrevista: RespuestasEntrevistaForm;
    fotosDifuntoAudiovisual: FotoDifuntoAudiovisual[];
}

export function useStore() {
    return baseUseStore() as Store<State>;
}

const mutationRespuestasEntrevista = generaMutationGenerica("respuestasEntrevista");
export const nombreMutationRespuestasEntrevista = mutationRespuestasEntrevista.nombre;

export default createStore({
    strict: process.env.VUE_APP_ENVIRONMENT === "localhost",
    state: {
        session: null,
        respuestasEntrevista: {
            caracter: {},
            isPersonaConflictiva: false,
            eligeCeremoniaPersonalizada: false,
            actitud: {},
            vinculos: {},
            menciones: [{ parentesco: "", nombre: "" }] as Intervencion[],
            aficiones: {},
            recuerdo: {},
            servicioMusical: {
                musicaContratada: false,
                repertorio: {
                    pieza1: { value: { placeholder: "", value: PIEZA_NULL }, isPredefinedOption: false },
                    pieza2: { value: { placeholder: "", value: PIEZA_NULL }, isPredefinedOption: false },
                    pieza3: { value: { placeholder: "", value: PIEZA_NULL }, isPredefinedOption: false },
                    pieza4: { value: { placeholder: "", value: PIEZA_NULL }, isPredefinedOption: false },
                },
            },
            audiovisualAutomatico: {
                idPoemaEscogido: POEMA_NULL,
                sinPoemaEnCeremonial: false,
                atributosAdicionales: {
                    usaSoloAtributosAdicionales: false,
                    listaAtributos: [],
                },
                fotos: [],
                codigoTemaEscogido: "",
                fraseDespedida: "",
            },
            informacionExtra: "",
            intervenciones: [{ parentesco: "", nombre: "" }] as Intervencion[],
            enfoque: -1,
        },
        fotosDifuntoAudiovisual: [{ id: STARTING_ID_FOTOS, foto: null }],
    } as State,

    mutations: {
        startSession(state, payload) {
            state.session = payload.session;
        },
        clearSession(state) {
            state.session = null;
        },
        inicializaRespuestasEntrevista(state, payload) {
            state.respuestasEntrevista = payload.respuestasEntrevista;
        },
        updateGenericoState(state, payload: { prop: string; value: unknown }) {
            if (payload.prop === undefined) {
                throw new Error(
                    `La payload requiere la propiedad 'prop' en la que se especifique la key de una subprop del State a actualizar.`
                );
            }

            try {
                accessObjectSubpropertyByString(state, payload.prop);
            } catch (error) {
                if (error instanceof ObjectTraversingError) {
                    throw new Error(
                        `No se pudo modificar '${payload.prop}'. No existe tal propiedad en el State de la Store.`
                    );
                } else {
                    throw error;
                }
            }

            if (payload.value === undefined) {
                throw new Error(
                    `La payload requiere la propiedad 'value' en la que se especifique el valor a asignar a la subprop indicada del State.`
                );
            }

            assignPropertyByPath(state, payload.prop, payload.value);
        },
        [mutationRespuestasEntrevista.nombre]: mutationRespuestasEntrevista.handler,
        updateFoto(state, payload: { id: number; content: File }) {
            const upFoto = state.fotosDifuntoAudiovisual.find((foto) => foto.id === payload.id);
            if (upFoto !== undefined) {
                upFoto.foto = payload.content;
            } else {
                throw new Error(`Se encomendó una mutación a una foto con índice inexistente '${payload.id}'`);
            }
        },
        updateListaFotos(state, payload) {
            state.fotosDifuntoAudiovisual = payload;
        },
    },

    actions: {},
    modules: {},
});
