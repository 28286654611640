import { ComponentCustomProperties, inject, InjectionKey, Ref } from "vue";
import BackendAPIConsumer from "./classes/BackendAPIConsumer";
import { AppLocales } from "./types/auxiliares-logica-app";

export const BACKEND_API_CONSUMER: InjectionKey<BackendAPIConsumer> = Symbol("BackendAPIConsumer");
export const T: InjectionKey<ComponentCustomProperties["$t"]> = Symbol("t");
export const LOCALE: InjectionKey<Ref<AppLocales>> = Symbol("locale");

export function strictInject<T>(key: InjectionKey<T>) {
    const injected = inject(key);

    if (injected === undefined) {
        throw new Error("No se asignó un valor para el valor 'provided': " + key.description);
    }

    return injected;
}
