import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ac766e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-entrevista--preguntas-principales" }
const _hoisted_2 = { class: "form-entrevista--fila-botones" }
const _hoisted_3 = {
  class: "button-continua-generacion",
  type: "submit"
}
const _hoisted_4 = { class: "form-entrevista--informacion-extra-difunto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormEntrevistaPreguntaDatosGenerales = _resolveComponent("FormEntrevistaPreguntaDatosGenerales")!
  const _component_AccordionItem = _resolveComponent("AccordionItem")!
  const _component_FormEntrevistaPreguntaEnfoque = _resolveComponent("FormEntrevistaPreguntaEnfoque")!
  const _component_FormEntrevistaPreguntaServiciosExternos = _resolveComponent("FormEntrevistaPreguntaServiciosExternos")!
  const _component_FormEntrevistaPreguntaCaracter = _resolveComponent("FormEntrevistaPreguntaCaracter")!
  const _component_SelectorAtributosCheckboxes = _resolveComponent("SelectorAtributosCheckboxes")!
  const _component_FormEntrevistaPreguntaVinculos = _resolveComponent("FormEntrevistaPreguntaVinculos")!
  const _component_FormEntrevistaPreguntaMenciones = _resolveComponent("FormEntrevistaPreguntaMenciones")!
  const _component_FormEntrevistaPreguntaAficiones = _resolveComponent("FormEntrevistaPreguntaAficiones")!
  const _component_FormEntrevistaPreguntaRecuerdo = _resolveComponent("FormEntrevistaPreguntaRecuerdo")!
  const _component_FormEntrevistaPreguntaIntervenciones = _resolveComponent("FormEntrevistaPreguntaIntervenciones")!
  const _component_FormEntrevistaPreguntaAtributosAdicionales = _resolveComponent("FormEntrevistaPreguntaAtributosAdicionales")!
  const _component_AccordionContainer = _resolveComponent("AccordionContainer")!

  return (_openBlock(), _createElementBlock("form", {
    class: "form-entrevista",
    onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AccordionContainer, {
        class: "accordion",
        itemsIdsList: _ctx.accordionItemsIds
      }, {
        [_ctx.getItemId(0)]: _withCtx((slotProps) => [
          _createVNode(_component_AccordionItem, {
            id: _ctx.accordionItemsIds[0],
            "ordinal-title": "1",
            title: _ctx.$t('formEntrevista.datosGenerales.tituloPregunta'),
            onDespliegaItem: ($event: any) => (slotProps.despliegaItem(_ctx.accordionItemsIds[0])),
            isDesplegado: slotProps.isDesplegado,
            class: "accordion-item__fondo-resaltado"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormEntrevistaPreguntaDatosGenerales, {
                fichaDifunto: _ctx.fichaDifunto,
                "onUpdate:fichaDifunto": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fichaDifunto) = $event))
              }, null, 8, ["fichaDifunto"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onDespliegaItem", "isDesplegado"])
        ]),
        [_ctx.getItemId(1)]: _withCtx((slotProps) => [
          _createVNode(_component_AccordionItem, {
            id: _ctx.accordionItemsIds[1],
            "ordinal-title": "2",
            title: _ctx.$t('formEntrevista.enfoque.tituloPregunta'),
            onDespliegaItem: ($event: any) => (slotProps.despliegaItem(_ctx.accordionItemsIds[1])),
            isDesplegado: slotProps.isDesplegado,
            class: "accordion-item__fondo-resaltado"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormEntrevistaPreguntaEnfoque, {
                enfoqueReligioso: _ctx.respuestasEntrevista_enfoque,
                "onUpdate:enfoqueReligioso": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.respuestasEntrevista_enfoque) = $event))
              }, null, 8, ["enfoqueReligioso"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onDespliegaItem", "isDesplegado"])
        ]),
        [_ctx.getItemId(2)]: _withCtx((slotProps) => [
          _createVNode(_component_AccordionItem, {
            id: _ctx.accordionItemsIds[2],
            "ordinal-title": "3",
            title: _ctx.$t('formEntrevista.serviciosExternos.tituloPregunta'),
            onDespliegaItem: ($event: any) => (slotProps.despliegaItem(_ctx.accordionItemsIds[2])),
            isDesplegado: slotProps.isDesplegado,
            class: "accordion-item__fondo-resaltado"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormEntrevistaPreguntaServiciosExternos, {
                servicioMusical: _ctx.respuestasEntrevista_servicioMusical,
                "onUpdate:servicioMusical": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.respuestasEntrevista_servicioMusical) = $event)),
                idPoema: _ctx.respuestasEntrevista_audiovisualAutomatico_idPoemaEscogido,
                "onUpdate:idPoema": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.respuestasEntrevista_audiovisualAutomatico_idPoemaEscogido) = $event)),
                sinPoemaEnCeremonial: 
                                _ctx.respuestasEntrevista_audiovisualAutomatico_sinPoemaEnCeremonial
                            ,
                "onUpdate:sinPoemaEnCeremonial": _cache[4] || (_cache[4] = ($event: any) => ((
                                _ctx.respuestasEntrevista_audiovisualAutomatico_sinPoemaEnCeremonial
                            ) = $event)),
                codigoTema: _ctx.respuestasEntrevista_audiovisualAutomatico_codigoTemaEscogido,
                "onUpdate:codigoTema": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.respuestasEntrevista_audiovisualAutomatico_codigoTemaEscogido) = $event)),
                fraseDespedida: _ctx.respuestasEntrevista_audiovisualAutomatico_fraseDespedida,
                "onUpdate:fraseDespedida": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.respuestasEntrevista_audiovisualAutomatico_fraseDespedida) = $event))
              }, null, 8, ["servicioMusical", "idPoema", "sinPoemaEnCeremonial", "codigoTema", "fraseDespedida"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onDespliegaItem", "isDesplegado"])
        ]),
        [_ctx.getItemId(3)]: _withCtx((slotProps) => [
          _createVNode(_component_AccordionItem, {
            id: _ctx.accordionItemsIds[3],
            "ordinal-title": "4",
            title: _ctx.$t('formEntrevista.caracter.tituloPregunta'),
            onDespliegaItem: ($event: any) => (slotProps.despliegaItem(_ctx.accordionItemsIds[3])),
            isDesplegado: slotProps.isDesplegado
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormEntrevistaPreguntaCaracter, {
                difunto: _ctx.difunto,
                isPersonaConflictiva: _ctx.respuestasEntrevista_isPersonaConflictiva,
                "onUpdate:isPersonaConflictiva": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.respuestasEntrevista_isPersonaConflictiva) = $event)),
                eligioCeremoniaPersonalizada: _ctx.respuestasEntrevista_eligioCeremoniaPersonalizada,
                "onUpdate:eligioCeremoniaPersonalizada": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.respuestasEntrevista_eligioCeremoniaPersonalizada) = $event))
              }, null, 8, ["difunto", "isPersonaConflictiva", "eligioCeremoniaPersonalizada"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onDespliegaItem", "isDesplegado"])
        ]),
        [_ctx.getItemId(4)]: _withCtx((slotProps) => [
          _createVNode(_component_AccordionItem, {
            id: _ctx.accordionItemsIds[4],
            "ordinal-title": "5",
            title: _ctx.$t('formEntrevista.actitud.tituloPregunta'),
            onDespliegaItem: ($event: any) => (slotProps.despliegaItem(_ctx.accordionItemsIds[4])),
            isDesplegado: slotProps.isDesplegado
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SelectorAtributosCheckboxes, {
                categoriaAtributos: "checkboxesActitud",
                vuexStatePath: "respuestasEntrevista.actitud",
                difunto: _ctx.difunto,
                pregunta: _ctx.$t('formEntrevista.actitud.pregunta')
              }, null, 8, ["difunto", "pregunta"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onDespliegaItem", "isDesplegado"])
        ]),
        [_ctx.getItemId(5)]: _withCtx((slotProps) => [
          _createVNode(_component_AccordionItem, {
            id: _ctx.accordionItemsIds[5],
            "ordinal-title": "6",
            title: _ctx.$t('formEntrevista.vinculos.tituloPregunta'),
            onDespliegaItem: ($event: any) => (slotProps.despliegaItem(_ctx.accordionItemsIds[5])),
            isDesplegado: slotProps.isDesplegado
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormEntrevistaPreguntaVinculos, { difunto: _ctx.difunto }, null, 8, ["difunto"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onDespliegaItem", "isDesplegado"])
        ]),
        [_ctx.getItemId(6)]: _withCtx((slotProps) => [
          _createVNode(_component_AccordionItem, {
            id: _ctx.accordionItemsIds[6],
            "ordinal-title": "7",
            title: _ctx.$t('formEntrevista.menciones.tituloPregunta'),
            onDespliegaItem: ($event: any) => (slotProps.despliegaItem(_ctx.accordionItemsIds[6])),
            isDesplegado: slotProps.isDesplegado
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormEntrevistaPreguntaMenciones, {
                oneTimeInput: _ctx.respuestasEntrevista_menciones,
                onCumplimentaMenciones: _cache[9] || (_cache[9] = ($event: any) => (_ctx.respuestasEntrevista_menciones = $event))
              }, null, 8, ["oneTimeInput"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onDespliegaItem", "isDesplegado"])
        ]),
        [_ctx.getItemId(7)]: _withCtx((slotProps) => [
          _createVNode(_component_AccordionItem, {
            id: _ctx.accordionItemsIds[7],
            "ordinal-title": "8",
            title: _ctx.$t('formEntrevista.aficiones.tituloPregunta'),
            onDespliegaItem: ($event: any) => (slotProps.despliegaItem(_ctx.accordionItemsIds[7])),
            isDesplegado: slotProps.isDesplegado
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormEntrevistaPreguntaAficiones, { difunto: _ctx.difunto }, null, 8, ["difunto"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onDespliegaItem", "isDesplegado"])
        ]),
        [_ctx.getItemId(8)]: _withCtx((slotProps) => [
          _createVNode(_component_AccordionItem, {
            id: _ctx.accordionItemsIds[8],
            "ordinal-title": "9",
            title: _ctx.$t('formEntrevista.recuerdo.tituloPregunta'),
            onDespliegaItem: ($event: any) => (slotProps.despliegaItem(_ctx.accordionItemsIds[8])),
            isDesplegado: slotProps.isDesplegado
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormEntrevistaPreguntaRecuerdo, { difunto: _ctx.difunto }, null, 8, ["difunto"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onDespliegaItem", "isDesplegado"])
        ]),
        [_ctx.getItemId(9)]: _withCtx((slotProps) => [
          _createVNode(_component_AccordionItem, {
            id: _ctx.accordionItemsIds[9],
            "ordinal-title": "10",
            title: _ctx.$t('formEntrevista.intervenciones.tituloPregunta'),
            onDespliegaItem: ($event: any) => (slotProps.despliegaItem(_ctx.accordionItemsIds[9])),
            isDesplegado: slotProps.isDesplegado
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormEntrevistaPreguntaIntervenciones, {
                oneTimeInput: _ctx.respuestasEntrevista_intervenciones,
                onCumplimentaIntervenciones: _cache[10] || (_cache[10] = ($event: any) => (_ctx.respuestasEntrevista_intervenciones = $event))
              }, null, 8, ["oneTimeInput"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onDespliegaItem", "isDesplegado"])
        ]),
        [_ctx.getItemId(10)]: _withCtx((slotProps) => [
          _createVNode(_component_AccordionItem, {
            id: _ctx.accordionItemsIds[10],
            title: _ctx.$t('formEntrevista.extras.tituloPregunta'),
            onDespliegaItem: ($event: any) => (slotProps.despliegaItem(_ctx.accordionItemsIds[10])),
            isDesplegado: slotProps.isDesplegado,
            class: "accordion-item__fondo-secundario"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormEntrevistaPreguntaAtributosAdicionales, {
                listaAtributos: 
                                _ctx.respuestasEntrevista_audiovisualAutomatico_atributosAdicionales_listaAtributos
                            ,
                "onUpdate:listaAtributos": _cache[11] || (_cache[11] = ($event: any) => ((
                                _ctx.respuestasEntrevista_audiovisualAutomatico_atributosAdicionales_listaAtributos
                            ) = $event)),
                soloAdicionales: 
                                _ctx.respuestasEntrevista_audiovisualAutomatico_atributosAdicionales_usaSoloAtributosAdicionales
                            ,
                "onUpdate:soloAdicionales": _cache[12] || (_cache[12] = ($event: any) => ((
                                _ctx.respuestasEntrevista_audiovisualAutomatico_atributosAdicionales_usaSoloAtributosAdicionales
                            ) = $event))
              }, null, 8, ["listaAtributos", "soloAdicionales"])
            ]),
            _: 2
          }, 1032, ["id", "title", "onDespliegaItem", "isDesplegado"])
        ]),
        _: 2
      }, 1032, ["itemsIdsList"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, _toDisplayString(_ctx.$t("formEntrevista.generaCeremonia")), 1),
        _createElementVNode("button", {
          class: "button-guarda-entrevista-incompleta",
          type: "button",
          onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.guardaEntrevistaIncompleta && _ctx.guardaEntrevistaIncompleta(...args)))
        }, _toDisplayString(_ctx.$t("formEntrevista.guardaEntrevista.botonGuardar")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t("formEntrevista.infoAdicional")) + ":", 1),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.respuestasEntrevista_informacionExtra) = $event))
      }, null, 512), [
        [_vModelText, _ctx.respuestasEntrevista_informacionExtra]
      ])
    ])
  ], 32))
}