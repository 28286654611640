
import { defineComponent } from "vue";
import { getEnv } from "@/modules/helpers";

export default defineComponent({
    data() {
        return {
            locales: getEnv("VUE_APP_I18N_SUPPORTED_LOCALES").split(","),
            localeFlagMapping: {
                es: "es",
                ca: "es-ct",
                en: "gb",
                fr: "fr",
            },
        } as {
            locales: string[];
            localeFlagMapping: {
                [key: string]: string;
            };
        };
    },
    methods: {
        changeLanguageTo(locale: string) {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
            }
        },
    },
});
