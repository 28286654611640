import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "form-entrevista--preguntas-principales" }
const _hoisted_2 = {
  key: 0,
  class: "blocking-overlay"
}
const _hoisted_3 = {
  key: 0,
  class: "blocking-overlay"
}
const _hoisted_4 = {
  key: 0,
  class: "blocking-overlay",
  style: {"top":"initial","height":"120px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ServiciosExternosReperorio = _resolveComponent("ServiciosExternosReperorio")!
  const _component_AccordionItem = _resolveComponent("AccordionItem")!
  const _component_ServiciosExternosPoema = _resolveComponent("ServiciosExternosPoema")!
  const _component_ServiciosExternosAudiovisualExtras = _resolveComponent("ServiciosExternosAudiovisualExtras")!
  const _component_AccordionContainer = _resolveComponent("AccordionContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AccordionContainer, {
      class: "accordion",
      itemsIdsList: _ctx.accordionItemsIds
    }, {
      [1]: _withCtx((slotProps) => [
        _createVNode(_component_AccordionItem, {
          id: 1,
          "ordinal-title": "3.1",
          title: _ctx.$t('formEntrevista.serviciosExternos.repertorio.subtituloPregunta'),
          onDespliegaItem: ($event: any) => (slotProps.despliegaItem(1)),
          isDesplegado: slotProps.isDesplegado,
          class: _normalizeClass({
                        'accordion-item__fondo-secundario': !_ctx.bloqueoCrematorioTerrassa,
                        'accordion-item__fondo-bloqueado': _ctx.bloqueoCrematorioTerrassa,
                        'blocking-overlay-frame': _ctx.bloqueoCrematorioTerrassa,
                    })
        }, {
          default: _withCtx(() => [
            (_ctx.bloqueoCrematorioTerrassa)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2))
              : _createCommentVNode("", true),
            _createVNode(_component_ServiciosExternosReperorio, {
              servicioMusical: _ctx.servicioMusicalBridge,
              "onUpdate:servicioMusical": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.servicioMusicalBridge) = $event))
            }, null, 8, ["servicioMusical"])
          ]),
          _: 2
        }, 1032, ["title", "onDespliegaItem", "isDesplegado", "class"])
      ]),
      [2]: _withCtx((slotProps) => [
        _createVNode(_component_AccordionItem, {
          id: 2,
          "ordinal-title": "3.2",
          title: _ctx.$t('formEntrevista.serviciosExternos.poema.subtituloPregunta'),
          onDespliegaItem: ($event: any) => (slotProps.despliegaItem(2)),
          isDesplegado: slotProps.isDesplegado,
          class: _normalizeClass({
                        'accordion-item__fondo-secundario': !_ctx.bloqueoCrematorioTerrassa,
                        'accordion-item__fondo-bloqueado': _ctx.bloqueoCrematorioTerrassa,
                        'blocking-overlay-frame': _ctx.bloqueoCrematorioTerrassa,
                    })
        }, {
          default: _withCtx(() => [
            (_ctx.bloqueoCrematorioTerrassa)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : _createCommentVNode("", true),
            _createVNode(_component_ServiciosExternosPoema, {
              idPoema: _ctx.idPoemaEscogidoBridge,
              "onUpdate:idPoema": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.idPoemaEscogidoBridge) = $event)),
              sinPoemaEnCeremonial: _ctx.sinPoemaEnCeremonialBridge,
              "onUpdate:sinPoemaEnCeremonial": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sinPoemaEnCeremonialBridge) = $event))
            }, null, 8, ["idPoema", "sinPoemaEnCeremonial"])
          ]),
          _: 2
        }, 1032, ["title", "onDespliegaItem", "isDesplegado", "class"])
      ]),
      [3]: _withCtx((slotProps) => [
        _createVNode(_component_AccordionItem, {
          id: 3,
          "ordinal-title": "3.3",
          title: _ctx.$t('formEntrevista.serviciosExternos.personalizacionAudiovisual.subtituloPregunta'),
          onDespliegaItem: ($event: any) => (slotProps.despliegaItem(3)),
          isDesplegado: slotProps.isDesplegado,
          class: _normalizeClass({
                        'accordion-item__fondo-secundario': !_ctx.bloqueoCrematorioTerrassa,
                        'accordion-item__fondo-semibloqueado': _ctx.bloqueoCrematorioTerrassa,
                        'blocking-overlay-frame': _ctx.bloqueoCrematorioTerrassa,
                    })
        }, {
          default: _withCtx(() => [
            (_ctx.bloqueoCrematorioTerrassa)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4))
              : _createCommentVNode("", true),
            _createVNode(_component_ServiciosExternosAudiovisualExtras, {
              codigoTema: _ctx.codigoTemaEscogido,
              "onUpdate:codigoTema": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.codigoTemaEscogido) = $event)),
              fraseDespedida: _ctx.fraseDespedidaBridge,
              "onUpdate:fraseDespedida": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fraseDespedidaBridge) = $event))
            }, null, 8, ["codigoTema", "fraseDespedida"])
          ]),
          _: 2
        }, 1032, ["title", "onDespliegaItem", "isDesplegado", "class"])
      ]),
      _: 2
    }, 1032, ["itemsIdsList"])
  ]))
}