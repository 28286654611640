import { Ref } from "vue";

import Alerta from "@/modules/Alerta";
import { generaDocumentos } from "@/modules/ceremonia-downloader";
import { Config } from "@/modules/config-por-entorno";
import { Ceremonia, SeccionRellenada } from "@/types/dominio-ceremonia-civil";
import { saveAs } from "file-saver";
import { GeneracionAudiovisuales } from "@/types/auxiliares-logica-app";
import { ImpossibleCodePathError } from "@/exceptions/ImpossibleCodePathError";

function compilaSeccionesCeremonia(textoSecciones: string[]) {
    let textoCompleto = "";
    for (const seccion of textoSecciones) {
        textoCompleto = `${textoCompleto}\n\n${seccion}`;
    }

    return textoCompleto.trim();
}

export default function useGeneradorDocumentosResultado(
    ceremonia: Ref<Ceremonia>,
    rellenamientoPorSeccion: Ref<SeccionRellenada[]>,
    generaAudiovisualesFunc: () => Promise<GeneracionAudiovisuales>,
    configEntorno: Config
) {
    function completaCeremonia() {
        return generaAudiovisualesFunc().then((linksAudiovisuales) => {
            const textoCompleto = compilaSeccionesCeremonia(
                rellenamientoPorSeccion.value.map((rellenamiento) => rellenamiento.textoRellenado)
            );
            const rellenamientoPersonal = rellenamientoPorSeccion.value.find(
                (rellenamiento) => rellenamiento.idSeccion === "parte-personal"
            );
            let partePersonal = "";
            if (rellenamientoPersonal) {
                partePersonal = rellenamientoPersonal.textoRellenado;
            } else {
                throw new ImpossibleCodePathError("Tendría que existir una sección relleneda con id 'parte-personal'.");
            }

            const nombreCompletoDifunto = `${ceremonia.value.difunto.nombre} ${ceremonia.value.difunto.apellidos}`;
            const documentosResultado = [
                {
                    nombre: "ceremoniaCompleta",
                    deps: {
                        ceremonia: ceremonia.value,
                        textoCompleto: textoCompleto,
                    },
                },
                {
                    nombre: "ceremoniaCompletaSeccionada",
                    deps: {
                        ceremonia: ceremonia.value,
                    },
                },
                {
                    nombre: "linkAudiovisual",
                    deps: {
                        linksAudiovisuales: linksAudiovisuales,
                        nombreCompletoDifunto: nombreCompletoDifunto,
                    },
                },
                {
                    nombre: "partePersonal",
                    deps: {
                        partePersonal: partePersonal,
                        linkAudiovisual: linksAudiovisuales.linkEntrega,
                        nombreCompletoDifunto: nombreCompletoDifunto,
                    },
                },
            ];

            const documentosSeleccionados = documentosResultado.filter(
                (doc) => configEntorno.generacion.descargaDocumentos.indexOf(doc.nombre) !== -1
            );

            return generaDocumentos(nombreCompletoDifunto, documentosSeleccionados).then((docs) => {
                for (const doc of docs) {
                    if (doc.error !== undefined) {
                        Alerta.error("Error generando un documento", doc.error.usrErrorMsg);
                        console.error(doc.error.errorCause);
                    } else {
                        saveAs(doc.blob, doc.nombreArchivo);
                    }
                }
            });
        });
    }

    return {
        completaCeremonia,
    };
}
