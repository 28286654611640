import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "multimedia-page" }
const _hoisted_2 = { class: "multimedia-box" }
const _hoisted_3 = { class: "titulo-archivo" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AudiovisualProgressBar = _resolveComponent("AudiovisualProgressBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.t("espacioMultimedia.tituloAudiovisual-" + _ctx.tipoArchivo, {
                        nombreDifunto: _ctx.nombreDifunto,
                    })), 1),
      (_ctx.linkVideo)
        ? (_openBlock(), _createElementBlock("video", {
            key: 0,
            src: _ctx.linkVideo,
            class: "video-player",
            controls: ""
          }, null, 8, _hoisted_4))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.generating)
              ? (_openBlock(), _createBlock(_component_AudiovisualProgressBar, {
                  key: 0,
                  progress: _ctx.progressDetails
                }, null, 8, ["progress"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.t("espacioMultimedia.msgEnCola")), 1)
                ], 64))
          ], 64)),
      _createElementVNode("button", {
        disabled: _ctx.linkVideo === '',
        class: "download-button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.descargaVideo && _ctx.descargaVideo(...args)))
      }, _toDisplayString(_ctx.t("espacioMultimedia.botonDescarga")), 9, _hoisted_5)
    ])
  ]))
}