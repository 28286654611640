import { Request } from "@/classes/BackendAPIConsumer";

export class NetworkError extends Error {}
export class Error403 extends Error {}
export class Error404 extends Error {}
export class Error500 extends Error {}
export class UnhandledHttpError extends Error {}
export class MalformedJSONError extends Error {}

export class APIResponseError extends Error {
    constructor(message: string, public apiErrors: string[], public request: Request) {
        super(message);
        console.error(apiErrors);
        console.error(request);
    }
}
