import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "catalogo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CatalogoElemento = _resolveComponent("CatalogoElemento")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementos, (elemento) => {
      return (_openBlock(), _createBlock(_component_CatalogoElemento, {
        key: elemento.id,
        elemento: elemento,
        onElementoEscogido: _ctx.elementoEscogido
      }, null, 8, ["elemento", "onElementoEscogido"]))
    }), 128))
  ]))
}