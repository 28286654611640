import { ContenidoFuente } from "@/modules/RellenadorPlantillas";

//Están aquí todos juntos, aunque cada error pertenece claramente a alguno de los otros módulos del sistema de rellenado, por un problema de imports circulares que se daba al tenerlos todos separados

export class RellenadoPlantillaError extends Error {
    constructor(message: string, sourcePlaceholder: string) {
        super(message);
        this.stack = "\nPlaceholder problemático: " + sourcePlaceholder;
    }
}
export class IncorrespondenciaContenidoFuenteError extends RellenadoPlantillaError {
    constructor(propName: string, sourcePlaceholder: string, searchingIn: ContenidoFuente) {
        super(
            `No se encontró la propiedad '${propName}', obtenida del Placeholder '${sourcePlaceholder}', dentro del objeto ContenidoFuente: ${JSON.stringify(
                searchingIn
            )}`,
            sourcePlaceholder
        );
    }
}

export class InterpretePlantillaError extends RellenadoPlantillaError {
    constructor(message: string, sourcePlaceholder: string) {
        super(message, sourcePlaceholder);
    }
}

export class PlaceholderFuncionIntexistenteError extends InterpretePlantillaError {
    constructor(functionName: string, sourcePlaceholder: string) {
        super(
            `No se encontró ninguna función con el nombre '${functionName}', extraído del Placeholder '${sourcePlaceholder}', en el repositorio de funciones de Placeholder.`,
            sourcePlaceholder
        );
    }
}

export class PlaceholderFuncionArgumentoInvalido extends InterpretePlantillaError {
    constructor(sourcePlaceholder: string) {
        super(
            `El argumento pasado al Placeholder de función '${sourcePlaceholder}' no tiene el formato esperado. Un Placeholder de función, si tiene arguento, debe ser un Placeholder cualquiera válido.`,
            sourcePlaceholder
        );
    }
}

export function contextualizaErrorRellenadoRecursivo(recibido: RellenadoPlantillaError, procesandoPlaceholder: string) {
    recibido.stack += "\n Dentro de: " + procesandoPlaceholder;
    return recibido;
}
