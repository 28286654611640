
import { defineComponent, PropType, Ref, ref } from "vue";
import { useStore } from "@/store/index";

import SeccionCeremonia from "@/components/SeccionCeremonia.vue";

import useAudiovisualGenerator from "@/composables/useAudiovisualGenerator";
import useConfigPorEntorno from "@/composables/useConfigPorEntorno";
import useGeneradorDocumentosResultado from "@/composables/useGeneradorDocumentosResultado";
import { strictInject, BACKEND_API_CONSUMER, T, LOCALE } from "@/symbols";

import { Ceremonia, RespuestasEntrevista, SeccionRellenada } from "@/types/dominio-ceremonia-civil";
import { Difunto } from "@/modules/Difunto";
import useSeccionesPlantillas from "@/composables/useSeccionesPlantillas";

export default defineComponent({
    setup(props) {
        const t = strictInject(T);
        const locale = strictInject(LOCALE);
        const backendApiConsumer = strictInject(BACKEND_API_CONSUMER);
        const store = useStore();

        const anotacionesDesplegadas = ref(false);

        const { areSeccionesCargadas, listadoPlantillas, seccionesNombradas, rellenamientoPorSeccion } =
            useSeccionesPlantillas(t, locale.value, backendApiConsumer, props.difunto, props.respuestasEntrevista);

        const tituloCeremonia = t("composicionCeremonia.tituloCeremonia", {
            nombreDifunto: props.difunto.nombre,
        });

        const ceremonia: Ref<Ceremonia> = ref({
            uuidv4: props.idCeremonia,
            respuestasEntrevista: props.respuestasEntrevista,
            difunto: props.difunto,
            titulo: tituloCeremonia,
            seccionesRellenadas: rellenamientoPorSeccion.value as SeccionRellenada[],
        });

        const { configEntorno } = useConfigPorEntorno();
        const { generaAudiovisuales } = useAudiovisualGenerator(
            backendApiConsumer,
            locale.value,
            t,
            configEntorno,
            ceremonia,
            store.state.fotosDifuntoAudiovisual.filter((foto) => foto.foto !== null).map((foto) => foto.foto) as File[]
        );

        const { completaCeremonia } = useGeneradorDocumentosResultado(
            ceremonia,
            rellenamientoPorSeccion as Ref<SeccionRellenada[]>,
            generaAudiovisuales,
            configEntorno
        );

        return {
            anotacionesDesplegadas,
            areSeccionesCargadas,
            listadoPlantillas,
            seccionesNombradas,
            configEntorno,
            rellenamientoPorSeccion,
            completaCeremonia,
        };
    },
    components: {
        SeccionCeremonia,
    },
    props: {
        idCeremonia: {
            required: true,
            type: String,
        },
        difunto: {
            required: true,
            type: Object as PropType<Difunto>,
        },
        respuestasEntrevista: {
            required: true,
            type: Object as PropType<RespuestasEntrevista>,
        },
    },
});
