import {
    EspeciePlaceholder,
    sintaxisPlaceholdersCeremonia,
    sintaxisOperadoresPlaceholder,
} from "./SintaxisPlaceholders";

export type OcurrenciaPlaceholder = {
    placeholderEntero: string;
    contenidoPlaceholder: string;
    especie: EspeciePlaceholder;
};

// Placeholders directos son los Placeholders que no son de tipo Función o Condicional y que no están dentro de uno.
function buscaPlaceholdersDirectosEnPlantilla(textoPlantilla: string) {
    const placeholdersEncontrados: OcurrenciaPlaceholder[] = [];

    for (const especiePlaceholder of sintaxisPlaceholdersCeremonia) {
        const placeholdersDeTipoEncontrados = textoPlantilla.matchAll(especiePlaceholder.sintaxis);

        for (const matchPlaceholder of placeholdersDeTipoEncontrados) {
            placeholdersEncontrados.push({
                placeholderEntero: matchPlaceholder[0],
                contenidoPlaceholder: matchPlaceholder[1],
                especie: especiePlaceholder,
            });
        }
    }

    return placeholdersEncontrados;
}

function buscaOperadoresEnPlantilla(textoPlantilla: string) {
    const placeholdersEncontrados: OcurrenciaPlaceholder[] = [];

    for (const especiePlaceholder of sintaxisOperadoresPlaceholder) {
        const placeholdersDeTipoEncontrados = textoPlantilla.matchAll(especiePlaceholder.sintaxis);

        for (const matchPlaceholder of placeholdersDeTipoEncontrados) {
            const placeholderEntero = matchPlaceholder[0];

            // Los Operadores encontrados deben ir ofuscándose durante su búsqueda puesto que los Operadores Condicionales pueden contener Operadores de función, y no interesa reconocer como Placeholders raíz estas funciones internas
            textoPlantilla = textoPlantilla.replace(placeholderEntero, "¡¡¡PLACEHOLDER ELIMINADO!!!");

            placeholdersEncontrados.push({
                placeholderEntero: placeholderEntero,
                contenidoPlaceholder: matchPlaceholder[1],
                especie: especiePlaceholder,
            });
        }
    }

    return placeholdersEncontrados;
}

// Los Placeholders raíz son aquellos que han de tener un PlaceholderSubstitucion (con su callbackSubstitucion) correspondiente. Cada uno de los cuales debe iniciar una substitución --si es necesario un recorrido de recursivo de rellenado de Plantillas y Placeholders dentro de ellas--, reemplazando el Placeholder raíz por "texto final (sin placeholders)" para la ceremonia.
export function buscaPlaceholdersRaizEnPlantilla(textoCeremonia: string) {
    let ocurrenciasTotales: OcurrenciaPlaceholder[] = [];

    const ocurrenciasOperadores = buscaOperadoresEnPlantilla(textoCeremonia);
    ocurrenciasTotales = ocurrenciasTotales.concat(ocurrenciasOperadores);

    // Se ofuscan los Placeholders de Función y Condicionales para que los Placeholders que puedan contenter no sean reconocidos en la 'buscaPlaceholdersDirectosEnPlantilla', como Placeholders independientes, y que puedan ser manejados por la lógica de resolución de los de Función o Condicional
    // Nótese que este texto de Plantilla modificado queda confinado a esta función, la función caller de esta mantiene la Plantilla original
    for (const ocurrenciaOperador of ocurrenciasOperadores) {
        textoCeremonia = textoCeremonia.replace(ocurrenciaOperador.placeholderEntero, "¡¡¡PLACEHOLDER ELIMINADO!!!");
    }

    const ocurrenciasPlaceholdersDirectos = buscaPlaceholdersDirectosEnPlantilla(textoCeremonia);
    ocurrenciasTotales = ocurrenciasTotales.concat(ocurrenciasPlaceholdersDirectos);

    return ocurrenciasTotales;
}
