import { Usuario } from "@/interfaces/AuthenticatorI";
import store from "@/store/index";

const session = {
    init(user: Usuario, startedAt?: Date): void {
        store.commit("startSession", { session: { user: user, startedAt: startedAt ?? new Date() } });

        window.localStorage.setItem("session", JSON.stringify(store.state.session));
    },
    restoreSession(): Usuario | null {
        const storedSession = window.localStorage.getItem("session");

        if (storedSession === null) {
            return null;
        } else {
            const parsedSession = JSON.parse(storedSession) as typeof store.state.session;

            if (parsedSession !== null) {
                const sessionStarted = new Date(parsedSession.startedAt);
                const currentTime = new Date();

                const sessionLifetime = Number(process.env.VUE_APP_SESSION_LIFETIME);
                const sessionExpireDate = new Date(sessionStarted.getTime() + sessionLifetime * 60 * 1000);
                if (currentTime > sessionExpireDate) {
                    this.clearSession();

                    return null;
                } else {
                    this.init(parsedSession.user, new Date(parsedSession.startedAt));
                    return parsedSession.user;
                }
            } else {
                throw new Error("Se serializó una sesión null.");
            }
        }
    },
    clearSession(): void {
        store.commit("clearSession");
        window.localStorage.removeItem("session");
    },
    getUser(): Usuario {
        const session = store.state.session;
        if (session === null) {
            throw new Error("Método solo accesible desde sesión activa.");
        } else {
            return session.user;
        }
    },
    isLogged(): boolean {
        if (store.state.session === null) {
            return false;
        } else {
            return true;
        }
    },
    isValid(): boolean {
        if (store.state.session === null) {
            const restoredSession = this.restoreSession();
            if (restoredSession === null) {
                return false;
            }
        }

        return true;
    },
};

export default session;
