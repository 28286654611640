import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a4f72e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-entrevista--alternativas-atributos" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlternativaSelectorControlador = _resolveComponent("AlternativaSelectorControlador")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.simple)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.$t("alternativasSelectoresPregunta.cabecera")) + ":", 1))
      : _createCommentVNode("", true),
    _createElementVNode("p", null, _toDisplayString(_ctx.enlaceComputed), 1),
    _createVNode(_TransitionGroup, { name: "alternativas-selectores" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.opcionesPreguntaSeleccionadas, (selectorId) => {
          return (_openBlock(), _createBlock(_component_AlternativaSelectorControlador, {
            key: selectorId,
            alternativaInicial: _ctx.valoresAlternativosSelectores[selectorId],
            selector: _ctx.getSelector(selectorId),
            simple: _ctx.simple,
            onInput: ($event: any) => (_ctx.actualizaTextoAlternativo(selectorId, $event))
          }, null, 8, ["alternativaInicial", "selector", "simple", "onInput"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}