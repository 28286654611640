import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/reloj-cargando.png'


const _hoisted_1 = { id: "composicion-page" }
const _hoisted_2 = { class: "side-bar" }
const _hoisted_3 = { class: "composicion-box" }
const _hoisted_4 = { class: "composicion-cerem--title" }
const _hoisted_5 = { class: "composicion-cerem--secciones-container" }
const _hoisted_6 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_SeccionCeremonia = _resolveComponent("SeccionCeremonia")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      (_ctx.respuestasEntrevista.informacionExtra)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["composicion-cerem--info-extra", { 'composicion-cerem--info-extra__desplegado': _ctx.anotacionesDesplegadas }])
          }, [
            _createElementVNode("label", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.anotacionesDesplegadas = !_ctx.anotacionesDesplegadas))
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t("composicionCeremonia.anotacionesEntrevista")), 1),
              _createVNode(_component_ion_icon, {
                class: "info-extra-icono-despliegue",
                name: "arrow-forward"
              })
            ]),
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.anotacionesDesplegadas = !_ctx.anotacionesDesplegadas))
            }, _toDisplayString(_ctx.respuestasEntrevista.informacionExtra), 1)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("composicionCeremonia.tituloCeremonia", { nombreDifunto: _ctx.difunto.nombre })), 1),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.areSeccionesCargadas)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.seccionesNombradas, (seccion, index) => {
              return (_openBlock(), _createBlock(_component_SeccionCeremonia, {
                key: index,
                seccion: seccion,
                cumplimentacionDifunto: { difunto: _ctx.difunto, entrevista: _ctx.respuestasEntrevista },
                listadoPlantillas: _ctx.listadoPlantillas,
                rellenamientoSeccion: _ctx.rellenamientoPorSeccion[index],
                "onUpdate:rellenamientoSeccion": ($event: any) => ((_ctx.rellenamientoPorSeccion[index]) = $event)
              }, null, 8, ["seccion", "cumplimentacionDifunto", "listadoPlantillas", "rellenamientoSeccion", "onUpdate:rellenamientoSeccion"]))
            }), 128))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _imports_0,
              alt: _ctx.$t('composicionCeremonia.msgCargando')
            }, null, 8, _hoisted_6))
      ]),
      _createElementVNode("button", {
        class: "button-continua-generacion",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.completaCeremonia && _ctx.completaCeremonia(...args)))
      }, _toDisplayString(_ctx.$t("composicionCeremonia.botonCompleta")), 1)
    ])
  ]))
}