import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComposicionCeremonia = _resolveComponent("ComposicionCeremonia")!

  return (_openBlock(), _createBlock(_component_ComposicionCeremonia, {
    respuestasEntrevista: _ctx.respuestasEntrevista,
    difunto: _ctx.difunto,
    idCeremonia: _ctx.idCeremonia
  }, null, 8, ["respuestasEntrevista", "difunto", "idCeremonia"]))
}