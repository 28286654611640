
import { computed, defineComponent, onMounted, PropType, ref } from "vue";

import { useRouter } from "vue-router";

import { v4 as uuidv4 } from "uuid";

import FormEntrevista from "@/components/FormEntrevista.vue";

import { Ceremonia, EntrevistaCompletada } from "@/types/dominio-ceremonia-civil";
import { LOCALE, strictInject } from "@/symbols";
import {
    AppLocales,
    FichaDifuntoForm,
    RespuestasEntrevistaForm,
    versionesEntrevista,
} from "@/types/auxiliares-logica-app";

export default defineComponent({
    setup(props) {
        const router = useRouter();
        const locale = strictInject(LOCALE);

        const idCeremoniaNueva = ref(uuidv4());
        const idCeremonia = computed(() => {
            if (props.idCeremoniaContinuada !== undefined) {
                return props.idCeremoniaContinuada;
            } else {
                return idCeremoniaNueva.value;
            }
        });

        function iniciaComposicionCeremonia(eventEntrevistaCompletada: EntrevistaCompletada) {
            const respuestasDesproxyadas = Object.assign({}, eventEntrevistaCompletada.respuestas);

            router.push({
                name: "composicion-ceremonia",
                params: {
                    idCeremonia: JSON.stringify(idCeremonia.value),
                    difunto: JSON.stringify(eventEntrevistaCompletada.difunto),
                    respuestasEntrevista: JSON.stringify(respuestasDesproxyadas),
                },
            });
        }

        onMounted(() => {
            if (props.idioma !== undefined) {
                locale.value = props.idioma;
            }
        });

        return {
            idCeremonia,
            iniciaComposicionCeremonia,
            rutaTipoEntrevista: versionesEntrevista.normal,
        };
    },
    props: {
        idCeremoniaContinuada: {
            required: false,
            type: String as PropType<Ceremonia["uuidv4"]>,
        },
        idioma: {
            required: false,
            type: String as PropType<AppLocales>,
        },
        fichaDifunto: {
            required: false,
            type: Object as PropType<FichaDifuntoForm>,
        },
        respuestasEntrevista: {
            required: false,
            type: Object as PropType<RespuestasEntrevistaForm>,
        },
    },
    components: {
        FormEntrevista,
    },
});
