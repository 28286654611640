
import { defineComponent } from "vue";

import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import session from "@/modules/session";
import Alerta from "@/modules/Alerta";
import useConfigPorEntorno from "@/composables/useConfigPorEntorno";

export default defineComponent({
    computed: {
        isLogged() {
            return session.isLogged();
        },
    },
    methods: {
        logout() {
            Alerta.confirmationModal(this.$t("logoutAlert.titulo"), {
                yesMsg: this.$t("generico.si"),
                noMsg: this.$t("generico.no"),
                yesCb: () => {
                    session.clearSession();
                    this.$router.push({ name: "Login" });
                    this.$backendApiConsumer.logout();
                },
            });
        },
        goMyAccount() {
            this.$router.push({ name: "cuenta" });
        },
        goPaginaPrincipal() {
            const configEntorno = useConfigPorEntorno().configEntorno;
            this.$router.push({ name: configEntorno.paginaPrincipal });
        },
    },
    components: {
        LanguageSwitcher,
    },
});
