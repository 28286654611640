
import { defineComponent } from "vue";

import CustomCheckBox from "@/components/CustomCheckBox.vue";
import AlternativasSelectoresPregunta from "@/components/AlternativasSelectoresPregunta.vue";

import { rellenaPlantilla } from "@/modules/RellenadorPlantillas";
import { InterpretePlantillaCeremonia } from "@/modules/interprete-plantillas-ceremonia/InterpretePlantillaCeremonia";
import {
    ButtonlikeSelector,
    TraduccionCheckboxes,
    checkboxesCastellano,
    checkboxesIngles,
    checkboxesCatala,
    checkboxesFrances,
} from "@/modules/FormEntrevistaSelectoresPorPregunta";
import { ConnotacionAtributo, AlternativasSelectoresT } from "@/types/dominio-ceremonia-civil";
import { accessObjectSubpropertyByString } from "@/modules/ProgrammaticObjectTraversing";

export default defineComponent({
    data() {
        return {
            alternativas: JSON.parse(
                JSON.stringify(accessObjectSubpropertyByString(this.$store.state, this.vuexStatePath))
            ),
            atributosSeleccionados: new Set<number>(
                Object.getOwnPropertyNames(
                    JSON.parse(JSON.stringify(accessObjectSubpropertyByString(this.$store.state, this.vuexStatePath)))
                ).map(Number)
            ),
        };
    },
    computed: {
        checkboxesAtributos(): ButtonlikeSelector[] {
            return this.getListadoCheckboxesCondicionado(this.categoriaAtributos as keyof TraduccionCheckboxes);
        },
    },
    watch: {
        alternativas: {
            handler(newVal: AlternativasSelectoresT) {
                this.$store.commit("updateGenericoState", {
                    prop: this.vuexStatePath,
                    value: JSON.parse(JSON.stringify(newVal)),
                });
            },
            deep: true,
        },
    },
    methods: {
        getConnotacion(enumVal: string, lista: ButtonlikeSelector[]): ButtonlikeSelector[] {
            if (Object.values(ConnotacionAtributo).includes(enumVal)) {
                return lista.filter(
                    (atributo) =>
                        atributo.connotacion === ConnotacionAtributo[enumVal as keyof typeof ConnotacionAtributo]
                );
            } else {
                throw new Error(`Connotación '${enumVal}' no existe en el enum. ConnotacionAtributo.`);
            }
        },
        rellenaPlantillaFromDifunto(plantilla: string) {
            return rellenaPlantilla(
                plantilla,
                { cumplimentacionDifunto: { difunto: this.difunto } },
                InterpretePlantillaCeremonia
            );
        },
        getListadoCheckboxesCondicionado(checkboxes: keyof TraduccionCheckboxes): ButtonlikeSelector[] {
            let listadoPorIdioma: ButtonlikeSelector[] = [];
            switch (this.$i18n.locale) {
                case "es":
                    listadoPorIdioma = checkboxesCastellano[checkboxes];
                    break;

                case "en":
                    listadoPorIdioma = checkboxesIngles[checkboxes];
                    break;

                case "ca":
                    listadoPorIdioma = checkboxesCatala[checkboxes];
                    break;

                case "fr":
                    listadoPorIdioma = checkboxesFrances[checkboxes];
                    break;

                default:
                    throw new Error("Listado de checkboxes no encontrado para el locale actual: " + this.$i18n.locale);
            }

            return listadoPorIdioma.map((checkboxAlt: ButtonlikeSelector) => {
                return {
                    ...checkboxAlt,
                    label: this.rellenaPlantillaFromDifunto(checkboxAlt.label),
                    desarrollos: checkboxAlt.desarrollos.map((desarrolloPlantilla) =>
                        this.rellenaPlantillaFromDifunto(desarrolloPlantilla)
                    ),
                };
            });
        },
    },
    props: {
        difunto: {
            type: Object,
            required: true,
        },
        categoriaAtributos: {
            type: String,
            required: true,
        },
        vuexStatePath: {
            type: String,
            required: true,
        },
        pregunta: {
            type: String,
        },
        enlace: {
            type: String,
        },
        simple: {
            type: Boolean,
        },
    },
    inheritAttrs: false,
    components: {
        CustomCheckBox,
        AlternativasSelectoresPregunta,
    },
});
