import * as configEntornos from "./config-por-entorno";

export type Config = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};
export function defaultConfig(): Config {
    return {
        generacion: {
            descargaDocumentos: [
                "ceremoniaCompleta",
                "ceremoniaCompletaSeccionada",
                "linkAudiovisual",
                "partePersonal",
            ],
            audiovisuales: {
                preceremonial: true,
                entrega: true,
                crematori: false,
            },
        },
        paginaPrincipal: "entrevista",
        paramsEntrevista: {
            maxFotosAudiovisual: 12,
        },
    };
}

export type ConfigEntornos = {
    [entornoId: number]: Config;
};
export function configsPorEntorno(): ConfigEntornos {
    // El 29 no es Mémora, es SFB, pero tiene la misma config.
    const entornosMemora = [7, 14, 15, 16, 17, 18, 19, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 53, 29];
    const configMemora = defaultConfig();
    configMemora.paginaPrincipal = "version";

    const configsPorEntorno: ConfigEntornos = {
        3: {
            generacion: {
                descargaDocumentos: [
                    "ceremoniaCompleta",
                    "ceremoniaCompletaSeccionada",
                    "linkAudiovisual",
                    "partePersonal",
                ],
                audiovisuales: {
                    preceremonial: true,
                    entrega: true,
                    crematori: true,
                },
            },
            paginaPrincipal: "entrevista",
            paramsEntrevista: {
                maxFotosAudiovisual: 12,
            },
        },
        56: {
            generacion: {
                descargaDocumentos: ["ceremoniaCompleta", "ceremoniaCompletaSeccionada"],
                audiovisuales: {
                    preceremonial: false,
                    entrega: false,
                    crematori: false,
                },
            },
            paginaPrincipal: "entrevista",
            paramsEntrevista: {
                maxFotosAudiovisual: 12,
            },
        },
        28: {
            generacion: {
                descargaDocumentos: ["linkAudiovisual"],
                audiovisuales: {
                    preceremonial: false,
                    entrega: false,
                    crematori: true,
                },
            },
            paginaPrincipal: "terrassa-crematori",
            paramsEntrevista: {
                maxFotosAudiovisual: 2,
            },
        },
    };

    for (const entorno of entornosMemora) {
        configsPorEntorno[entorno] = configMemora;
    }

    return configsPorEntorno;
}

export default function getConfig(entornoId?: number) {
    const defConfig = configEntornos.defaultConfig();
    const entornosConfig = configEntornos.configsPorEntorno();

    let config = undefined;
    if (entornoId !== undefined) {
        config = entornosConfig[entornoId];
        if (config === undefined) {
            config = defConfig;
        }
    } else {
        config = defConfig;
    }

    return config;
}
