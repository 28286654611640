import * as TiposDifunto from "../modules/Difunto";
import { ButtonlikeSelector } from "@/modules/FormEntrevistaSelectoresPorPregunta";
import { Usuario } from "@/interfaces/AuthenticatorI";
import {
    AppLocales,
    FichaDifuntoForm,
    RespuestasEntrevistaForm,
    TemaFondoAudiovisual,
} from "@/types/auxiliares-logica-app";

export type Plantilla = {
    id: number;
    nombre: string;
    clases: string[];
    expresionFiltrado: string;
    titulo: string;
    autor: string;
    texto: string;
};

export type EntrevistaCompletada = {
    respuestas: RespuestasEntrevista;
    difunto: TiposDifunto.Difunto;
};

// El orden en el array es el orden en el que juntar todas las secciones al completar la cermonia. Los valores de los elementos se corresponden a una de las clases con la que están etiquetadas las plantillas de esa sección.
export const ORDEN_SECCIONES_CEREMONIA = ["intro", "reflexion", "parte-personal", "despedida"] as const;
export type IdSeccionCeremonia = typeof ORDEN_SECCIONES_CEREMONIA[number];

export type Ceremonia = {
    uuidv4: string;
    respuestasEntrevista: RespuestasEntrevista;
    difunto: TiposDifunto.Difunto;
    titulo: string;
    seccionesRellenadas: SeccionRellenada[];
};

export type SeccionRellenada = {
    idSeccion: IdSeccionCeremonia;
    plantillaUsada: Plantilla;
    textoRellenado: string;
};

export type Seccion = {
    idSeccion: IdSeccionCeremonia;
    nombre: string;
    plantillas: Plantilla[];
};

export type ContenidoFuenteCeremonia = {
    cumplimentacionDifunto: {
        difunto: TiposDifunto.Difunto;
        entrevista: RespuestasEntrevista;
    };
    listadoPlantillas: Plantilla[];
};

export const SEPARADOR_LABEL_DESARROLLABLE = " / ";

export type Desarrollable = {
    id: ButtonlikeSelector["id"];
    label: string;
    textoDesarrollo: string;
};

export type AlternativasSelectoresT = {
    [id: number]: {
        texto: string;
    };
};

export enum ConnotacionAtributo {
    Positiva,
    Negativa,
}

export enum Enfoque {
    Laica,
    LaicaPadre,
    Religiosa,
}

export const MAX_INTERVENCIONES = 4;
export const MAX_MENCIONES = 10;
export type Intervencion = {
    parentesco: string;
    nombre: string;
};

export type ServicioMusical = {
    musicaContratada: boolean;
    repertorio: {
        pieza1: PiezaMusicalAppTextos;
        pieza2: PiezaMusicalAppTextos;
        pieza3: PiezaMusicalAppTextos;
        pieza4: PiezaMusicalAppTextos;
    };
};

export const MAX_ATRIBUTOS_ADICIONALES = 7;
export type AtributosAdicionales = {
    usaSoloAtributosAdicionales: boolean;
    listaAtributos: string[];
};

export type Poema = {
    id_grabacion: number;
    id_nominal: number;
    title: string;
    author: string;
    audio_file: string;
    text_file: string;
    gender: string;
    lang: AppLocales;
};

export const POEMA_NULL = -1;

export type AudiovisualAutomatico = {
    idPoemaEscogido: Poema["id_grabacion"];
    sinPoemaEnCeremonial: boolean;
    atributosAdicionales: AtributosAdicionales;
    fotos: string[];
    codigoTemaEscogido: TemaFondoAudiovisual["codigoTema"];
    fraseDespedida: string;
};

export type FotoDifuntoAudiovisual = {
    // Los valores positivos (x > 0) son el id del registro de la foto en el backend, los valores negativos (x < 0) son para las fotos que no aún no están guardadas en la BDD. Cualquier valor (tanto positivo como negativo) debe ser único y sirve para referenciar la foto para reordenar o eliminarla en la interfaz gráfica del frontend.
    id: number;
    foto: null | File;
};

export type AudiovisualAutomaticoGeneracion = {
    idCeremonia: Ceremonia["uuidv4"];
    nombreDifunto: string;
    atributos: string[];
    textoDespedida: string;
    idPoema?: Poema["id_grabacion"];
    idsPiezasMusicales?: PiezaMusicalAppTextos["id"][];
    fotosDifuntoCodificadas?: string[];
    codigoTema: TemaFondoAudiovisual["codigoTema"];
    fraseDespedida: string;
};

export type Menciones = {
    familia?: Intervencion[];
    amigos?: string[];
    otros?: string[];
};

export type RespuestasEntrevista = {
    eligeCeremoniaPersonalizada: boolean;
    enfoque: Enfoque;
    aficiones: Desarrollable[];
    caracter: Desarrollable[];
    actitud: Desarrollable[];
    vinculos: Desarrollable[];
    menciones: Menciones;
    recuerdo: Desarrollable[];
    informacionExtra: string;
    intervenciones: Intervencion[];
    servicioMusical: ServicioMusical;
    audiovisualAutomatico: AudiovisualAutomatico;
};

export const PIEZA_NULL = -1;
// PIEZA_SIN_REGISTRO: Para cuando se introduce un valor libre en el campo de la pieza musical y dicho valor no existe en la lista de repertorio que se recibe desde ERP
export const PIEZA_SIN_REGISTRO = -2;
export type PiezaMusicalERP = {
    id: number;
    nombre: string;
    autor: string;
    isReproducible: boolean;
};
export type PiezaMusicalAppTextos = { id: number; titulo: string };

export type EntrevistaGuardada = {
    idCeremonia: Ceremonia["uuidv4"];
    propietario: Usuario["id"];
    idioma: AppLocales;
    fecha: Date;
    rutaVersionEntrevista: string;
    completa: boolean;
    entrevista: {
        fichaDifunto: FichaDifuntoForm;
        respuestasEntrevista: RespuestasEntrevistaForm;
    };
};

// Se formatea la entrevista en el front porque si se hace el json_encode en el
// backend (al trabajar PHP con arrays asociativos) no se respetan los objetos
// vacíos, se transforman a arrays vacíos, y al deserializarlos y devolverlos
// al frontend los tipos no son compatibles y la lógica no funciona bien.
export type EntrevistaBackend = Omit<EntrevistaGuardada, "entrevista"> & { entrevista: string };
export function formateoEntrevistaBackFront(entrevista: EntrevistaBackend): EntrevistaGuardada {
    const parsed = JSON.parse(entrevista.entrevista);

    const entrevistaFront: EntrevistaGuardada = {
        ...entrevista,
        entrevista: parsed,
    };

    return entrevistaFront;
}

export const PARENTESCOS = {
    familia: [
        "formEntrevista.intervenciones.parentescoPadre",
        "formEntrevista.intervenciones.parentescoMadre",
        "formEntrevista.intervenciones.parentescoHijo",
        "formEntrevista.intervenciones.parentescoHija",
        "formEntrevista.intervenciones.parentescoNieto",
        "formEntrevista.intervenciones.parentescoNieta",
        "formEntrevista.intervenciones.parentescoHermano",
        "formEntrevista.intervenciones.parentescoHermana",
        "formEntrevista.intervenciones.parentescoMarido",
        "formEntrevista.intervenciones.parentescoMujer",
        "formEntrevista.intervenciones.parentescoPareja",
    ],
    amigos: ["formEntrevista.intervenciones.parentescoAmigo"],
    otros: ["formEntrevista.intervenciones.parentescoOtro"],
};
