import { TraduccionCheckboxes } from "@/modules/FormEntrevistaSelectoresPorPregunta";
import { ConnotacionAtributo } from "@/types/dominio-ceremonia-civil";

export const alternativasCastellano: TraduccionCheckboxes = {
    checkboxesCaracter: [
        {
            id: 1,
            label: "Abiert((o/a))",
            desarrollos: [
                "Siempre dispuest((o/a)) a aprender cosas nuevas, a extraer de la vida todo aquello que nos pueda dar",
                "Activ((a/o)) y vital, con esas ganas de hacer cosas, de vivir la vida intensamente, de sentir que la felicidad no es el objetivo, es el camino",
                "Con esa capacidad para adaptarse a cada una de las realidades de la vida, con esa fuerza, esa vitalidad que ((lo/la)) hacían tan especial",
                "Dando valor a lo que realmente es importante, aprender a vivir y a compartir la vida creciendo día a día",
                "Sociable, le encantaba conocer a la gente, relacionarse, sentir, conectar con aquello que nos une los unos con los otros",
                "Siempre abiert((o/a)) a profundizar, comunicar, charlar, entrar en el otro para conocer y compartir lo mejor de cada uno",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 2,
            label: "Alegre",
            desarrollos: [
                "Con esa sonrisa que siempre tenía preparada para todos vosotros, propia de quien aprende que la felicidad no ocurre por casualidad sino por elección",
                "Siempre con esas ganas de sonreírle a la vida, encontrando en cada momento la alegría de vivir",
                "Sabiendo que la alegría es el arte de ser feliz y que la felicidad no se encuentra, se crea",
                "Que entendió que la verdadera alegría está en las cosas simples de la vida, una palabra, un gesto, esa sonrisa que sabía dibujar en sus labios y en el de los suyos",
                "Que entendía que la alegría no es más que un placer que se multiplica al compartirlo con los demás",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 3,
            label: "Positiv((o/a))",
            desarrollos: [
                "Sabiendo que cuando hay ganas, cuando la actitud acompaña, todo se puede",
                "Siempre mirando hacia adelante consciente que la dificultad del camino es una oportunidad disfrazada",
                "Consciente que mantener la actitud adecuada causa una reacción en cadena de pensamientos, sucesos y resultados positivos",
                "Consciente que no somos un producto de nuestras circunstancias sino de nuestras propias decisiones",
                "Prefiriendo siempre ver el lado bueno que todas las cosas tienen",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 4,
            label: "Reservad((o/a))",
            desarrollos: [
                "Le costaba expresar lo que sentía, pero sabía que lo que mostraba era lo verdadero",
                "Sabiendo que ser medido es un arte, no malgastaba ninguna palabra de más, prefería las justas",
                "Prescindiendo de la elocuencia para expresar en palabras todo lo que era capaz de sentir",
                "Dando valor a lo que sentía más allá de encontrar la manera de expresarlo",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
        {
            id: 6,
            label: "Introvertid((o/a))",
            desarrollos: [
                "Con esa habilidad que tenía para observar y reconocer de forma sencilla la esencia de las cosas",
                "Fiel a su mundo interior, a su manera de entender lo que le rodeaba, sin pedir nada ni molestar a nadie",
                "Encontrando en la profundidad de su ser la compañía necesaria para rehuir de su soledad",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
        {
            id: 32,
            label: "De carácter",
            desarrollos: ["Con esa personalidad tan contundente que ((él/ella)) tenía"],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
    ],
    checkboxesVinculos: [
        {
            id: 7,
            label: "Familiar",
            desarrollos: [
                "La familia siempre fue el valor más preciado, un sueño hecho realidad que se gestó al lado de su amor y se consolidó con el tiempo, creciendo y alimentándose de respeto y ternura",
                "Que entendió que la familia era el tesoro más grande que uno puede conseguir",
                "Que comprendió que la familia es donde la vida comienza y el amor nunca termina, y que ser parte de una familia significa que amarás y serás amado siempre y sin condición",
                "Construyó una familia, un lugar a donde ir, alguien a quien amar, el mejor de  todos los regalos que la vida le pudo dar",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 10,
            label: "Amig((o/a)) de sus amigos",
            desarrollos: [
                "Siempre tenía una mano extendida para quien la necesitara coger, una sonrisa para poder animar, una mirada para saber comprender, una lágrima para unir al dolor del otro y una palabra para hacer saber que siempre estaba allí",
                "Entendió que una amistad no crece por la presencia de las personas sino por la magia de saber que aunque no las ves las llevas en el corazón",
                "Se mantuvo fiel al pensamiento que la amistad es un amor que no se comunica por los sentidos, existe y por ello debemos valorarla, mimarla y cuidarla como un pequeño y frágil tesoro",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 33,
            label: "Querid((o/a)) en su entorno (Otros)",
            desarrollos: ["Querid((o/a)) por todos con los que convivía y compartía su día a día"],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: false,
        },
        {
            id: 11,
            label: "Independiente / Solitari((o/a))",
            desarrollos: [
                "Que le gustaba estar con su propia compañía, disfrutando de sus pensamientos, con sus proyectos, sus sueños…",
                "Que invertía su tiempo en entender que lo importante en la vida era seguir viviendo y dejando vivir",
                "Que se relacionaba desde su silencio diciendo mucho más de lo que decía y haciéndose entender mucho más de lo que hablaba",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
    ],
    checkboxesAficiones: [
        {
            id: 12,
            label: "El Arte",
            desarrollos: [
                "Su sensibilidad por la belleza, su pasión por la creación humana, eran fuente de la fascinación que le impulsaba",
                "La <b>música</b>, su música que tanto le gustaba de escuchar, de sentir, de disfrutar, emociones mezcladas con realidades presentes y pasadas que conforman una pasión única",
                "La <b>pintura</b>, esos trazos sobre el lienzo que imitaba en su imaginación entre pinceles, paisajes y retratos",
                "La <b>literatura</b>, sus libros, horas y horas de lectura que transportaba su mente a esos lugares llenos de personajes con sus historias, sus vidas, pasiones y sentimientos",
                "El <b>cine</b>, la magia del celuloide que tantas emociones fue capaz de regalarle con sus escenas llena de alegrías y penas, de sonrisas y lágrimas",
                "El <b>teatro</b>, esos textos que parecía que imposible retener, los nervios de las actuaciones, los ensayos",
            ],
            desarrolloFijo: 0,
        },
        {
            id: 13,
            label: "La Cocina",
            desarrollos: [
                "Recordadle con esa facilidad que tenía para la cocina y esos platos únicos, con los que alimentaba el estómago pero también vuestro corazón",
                "Seguramente una excusa para teneros a todos cerca, alrededor de una mesa y disfrutando de lo que más le gustaba hacer, esos platos inolvidables llenos de amor",
            ],
        },
        {
            id: 14,
            label: "El Deporte",
            desarrollos: [
                "Disfrutaba con todos los deportes que seguía",
                "El fútbol, su pasión, una cita obligada, sus esperanzas puestas en once jugadores y un balón que no siempre le daban las alegrías que esperaba pero que celebraba con fuerza cada vez que saltaban al campo",
                "El básquet, le encantaba verlo, sentirlo, vivirlo...",
                "El tenis, ese silencio roto por el impacto de la pelota en la raqueta, los puntos que no terminan...",
                "El motor, las carreras, los circuitos, las curvas, las vueltas de reconocimiento, los adelantamientos imposibles...",
            ],
        },
        {
            id: 15,
            label: "La Naturaleza",
            desarrollos: [
                "El mar, las aguas cristalinas, las olas, la playa, la arena, los paseos, todo lo que significaba estar cerca del mar, era un motivo para sonreír",
                "La montaña, esos paseos entre los árboles, senderos largos repletos de tierra y piedras, el sonido de los animales, el olor de las flores, paisajes únicos que le conectaban con lo más profundo de su ser",
                "Su huerto, su tierra, esa tierra que cultivaba con sus propias manos para recibir los alimentos que recogía y compartía con todos vosotros",
                "Disfrutando de la ternura que solo la compañía de nuestros amigos de cuatro patas nos pueden proporcionar",
            ],
        },
        {
            id: 28,
            label: "El Trabajo",
            desarrollos: [
                "Siempre comprometido con proyectos nuevos que llenaban las horas, los días y sobre todo ese espacio para su creatividad",
                "El trabajo no como medio de vida solamente sino como pequeñas motivaciones que daban sentido a su día a día",
                "Aquellas expectativas profesionales que daban respuesta a las ganas de crecer, de superarse como persona",
            ],
        },
        {
            id: 20,
            label: "Las Manualidades",
            desarrollos: [
                'El bricolaje, era lo que llamamos un "manitas", con esa habilidad para hacer, construir, rehacer y reconstruir, y todo con sus propias manos, desde cero y cuidando todos los detalles',
                "La costura, coser, ese arte que precisa de las manos para crear de forma metódica esos detalles que decoran esos recuerdos",
            ],
        },
    ],
    checkboxesActitud: [
        {
            id: 24,
            label: "Trabajador((/a)) / Luchador((/a))",
            desarrollos: [
                "Que entendió que la vida le da las batallas más difíciles a los mejores luchadores, y sin duda supo batallar para tirar adelante su vida",
                "Que supo comprender que el verdadero luchador no es el que pelea con sus puños por un trofeo sino el que lucha con el corazón por alcanzar sus sueños, y luchó y peleó con el corazón hasta que pudo ver su sueño hecho realidad",
                "Que comprendió que el denominador común para alcanzar el éxito es el trabajo y que el éxito no es más que trabajar duro por algo que amamos",
                "Que siempre supo que era más importante aprender a levantarse que rendirse ante la adversidad",
                "Le tocó vivir en una etapa difícil de la historia de nuestro país, corrían rumores de guerra, realidades de postguerra, dificultades de todo tipo que como tantos coetáneos tuvieron que superar, pero siempre miró hacia adelante, con esa capacidad de superación, con esas ganas de construir una realidad mejor para sí y para los suyos.",
                "Vivió una de las realidades más difíciles de entender de nuestra historia reciente, una guerra que dejó una postguerra, momentos difíciles que tuvo que superar con entereza, con esa capacidad para superarse ante la adversidad, pero nunca miró hacia atrás, siempre buscó un presente mejor para poder construir el futuro que merecía.",
                "Tuvo que dar ese paso adelante en un momento de máxima dificultad, un presente teñido por el paso de la guerra y la crudeza de la postguerra que no entiende de matices. Realidades que debió superar con la entereza del que sabe que el esfuerzo del día a día permitirá encontrar el camino de la tranquilidad.",
                "Vino en busca de mejores oportunidades, lucha, esfuerzo y superación fueron sus compañeros de viaje. Un viaje lleno de dificultades que tenían la recompensa en la estabilidad, que tenían la meta en la seguridad del que se sabe conocedor de sus limitaciones pero con la motivación necesaria para seguir adelante.",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 25,
            label: "Generos((o/a))",
            desarrollos: [
                "Entendió que la generosidad no es un acto puntual, es una forma de vida",
                "Generos((o/a)), no solamente en lo material sino en lo más importante, en el amor, en el dar y recibir, sin condiciones, sin pedir nada a cambio",
                "Que supo darse a los demás de manera desinteresada, compartiendo lo que era, lo que tenía, transmitiendo esa sensación de paz y de amor que todos habéis recibido",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 26,
            label: "Sencill((o/a)) / Humilde",
            desarrollos: [
                "Que no necesitaba grandes lujos en su vida, sólo pequeñas cosas que la hacían más grande",
                "Sabiendo que en el carácter, en la forma, en el estilo, en todas las cosas, la excelencia suprema es la sencillez",
                "Que sabía valorar las pequeñas cosas de la vida, sin grandilocuencias ni parafernalias que ocultaran la auténtica verdad",
                "Que supo ver que todo es muy difícil antes de ser sencillo, y fiel a su mandato eligió hacerlo fácil y vivir con humildad y sencillez",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 27,
            label: "Discret((o/a)) / Prudente",
            desarrollos: [
                "Comprendió que muchas veces tenemos que hablar para que nos escuchen, pero otras veces debemos callar para que nos valoren, y así, desde el silencio, lo decía todo",
                "Teniendo claro que también se puede amar en silencio, extrañar sin gritarlo, enamorarse sin contarlo y ser feliz sin publicarlo",
                "Sabiendo que parecer discreto vale tanto como entender una cosa y es probablemente mucho más fácil y menos comprometido",
                "No disfrutaba siendo el centro de atención pero sabía ser el centro de aquello de lo que era importante",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
    ],
    checkboxesRecuerdo: [
        {
            id: 29,
            label: "Por sus aficiones",
            desarrollos: [
                "Con todo lo que le gustaba hacer y que identificaba sus prioridades, llenando ese espacio de tiempo con experiencias inolvidables",
            ],
        },
        {
            id: 30,
            label: "Con la familia",
            desarrollos: [
                "A vuestro lado, unidos desde el cariño, con todos los que habéis formado ese sueño que supo transformar en una realidad llena de amor y ternura",
            ],
        },
        {
            id: 31,
            label: "Como una buena persona",
            desarrollos: [
                "Con todo lo bueno que hemos descrito hoy aquí y que la definen como lo que ha sido para todos vosotros: una persona buena, una buena persona",
                "Fiel a su esencia, a su manera de ver, de sentir y de entender la vida",
                "Con la tranquilidad de haber vivido en paz y harmonía con su propio ser y con su entorno ",
            ],
        },
    ],
};
