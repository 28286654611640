import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "language-switcher" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale, index) => {
      return (_openBlock(), _createElementBlock("span", {
        class: _normalizeClass(["fi language-switcher--bandera", [
                {
                    'language-switcher--bandera__seleccionada': _ctx.$i18n.locale === locale,
                },
                `fi-${_ctx.localeFlagMapping[locale]}`,
            ]]),
        key: index,
        onClick: ($event: any) => (_ctx.changeLanguageTo(locale))
      }, null, 10, _hoisted_2))
    }), 128))
  ]))
}