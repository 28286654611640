import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "backdrop"
}
const _hoisted_2 = {
  key: 1,
  class: "modal-catalogo"
}
const _hoisted_3 = { class: "modal-catalogo__header" }
const _hoisted_4 = { class: "modal-catalogo__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Catalogo = _resolveComponent("Catalogo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("header", _hoisted_3, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.titulo), 1),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
              class: "modal-catalogo__close-button"
            }, "X")
          ]),
          _createElementVNode("main", _hoisted_4, [
            _createVNode(_component_Catalogo, {
              elementos: _ctx.elementos,
              onElementoEscogido: _ctx.elementoEscogido
            }, null, 8, ["elementos", "onElementoEscogido"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}