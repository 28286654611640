
import { computed, defineComponent, onMounted, PropType, ref, Ref } from "vue";

import CustomRadioButton from "@/components/CustomRadioButton.vue";
import ListImageUploader from "@/components/ListImageUploader.vue";

import { TemaFondoAudiovisual } from "@/types/auxiliares-logica-app";
import { BACKEND_API_CONSUMER, T, strictInject } from "@/symbols";
import useConfigPorEntorno from "@/composables/useConfigPorEntorno";

export default defineComponent({
    setup(props, { emit }) {
        const t = strictInject(T);
        const backendApiConsumer = strictInject(BACKEND_API_CONSUMER);

        const configEntorno = useConfigPorEntorno().configEntorno;

        const listaTemas: Ref<TemaFondoAudiovisual[]> = ref([]);

        const codigoTemaEscogidoBridge = computed({
            get(): TemaFondoAudiovisual["codigoTema"] {
                return props.codigoTema;
            },
            set(newVal: TemaFondoAudiovisual["codigoTema"]) {
                emit("update:codigoTema", newVal);
            },
        });

        const fraseDespedidaBridge = computed({
            get() {
                return props.fraseDespedida;
            },
            set(newVal: string) {
                emit("update:fraseDespedida", newVal);
            },
        });

        onMounted(() => {
            backendApiConsumer.getListaTemas().then((temas) => {
                listaTemas.value = temas;

                if (props.codigoTema === "") {
                    const defaultRandomTemaIndex = Math.floor(Math.random() * (listaTemas.value.length - 1));
                    emit("update:codigoTema", listaTemas.value[defaultRandomTemaIndex].codigoTema);
                }
            });
        });

        const maxFotos = ref(configEntorno.paramsEntrevista.maxFotosAudiovisual);

        return {
            t,
            backendApiConsumer,
            listaTemas,
            fraseDespedidaBridge,
            codigoTemaEscogidoBridge,
            maxFotos,
        };
    },
    props: {
        codigoTema: {
            type: String as PropType<TemaFondoAudiovisual["codigoTema"]>,
            required: true,
        },
        fraseDespedida: {
            type: String as PropType<string>,
            required: true,
        },
    },
    emits: ["update:codigoTema", "update:fraseDespedida"],
    components: {
        CustomRadioButton,
        ListImageUploader,
    },
});
