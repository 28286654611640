
import { defineComponent } from "vue";

import CatalogoElemento from "./CatalogoElemento.vue";

export default defineComponent({
    methods: {
        elementoEscogido(idElemento: number) {
            this.$emit("elemento-escogido", idElemento);
        },
    },
    props: {
        elementos: {
            required: true,
            type: Array,
        },
    },
    components: {
        CatalogoElemento,
    },
    emits: ["elemento-escogido"],
});
