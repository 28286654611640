
import { defineComponent } from "vue";

import SelectorAtributosCheckboxes from "@/components/SelectorAtributosCheckboxes.vue";

export default defineComponent({
    props: {
        difunto: {
            type: Object,
            required: true,
        },
    },
    components: {
        SelectorAtributosCheckboxes,
    },
});
