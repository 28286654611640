import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-376635ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preguntas-grid" }
const _hoisted_2 = { class: "form-entrevista--pregunta-group pregunta-larga-grid" }
const _hoisted_3 = { class: "inline-radio-buttons" }
const _hoisted_4 = { class: "preguntas-grid pregunta-larga-grid" }
const _hoisted_5 = { class: "form-entrevista--pregunta-group" }
const _hoisted_6 = { class: "inline-radio-buttons" }
const _hoisted_7 = { class: "form-entrevista--pregunta-group" }
const _hoisted_8 = {
  ref: "playerRef",
  class: "audio-player",
  controls: ""
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomRadioButton = _resolveComponent("CustomRadioButton")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, _toDisplayString(_ctx.t("formEntrevista.serviciosExternos.poema.poemaEnAudiovisual")) + ":", 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_CustomRadioButton, {
          value: false,
          modelValue: _ctx.sinPoemaEnCeremonialBridge,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sinPoemaEnCeremonialBridge) = $event))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("formEntrevista.serviciosExternos.poema.poemaAmbos")), 1)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_CustomRadioButton, {
          value: true,
          modelValue: _ctx.sinPoemaEnCeremonialBridge,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sinPoemaEnCeremonialBridge) = $event))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("formEntrevista.serviciosExternos.poema.sinPoemaCeremonia")), 1)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", null, _toDisplayString(_ctx.t("formEntrevista.serviciosExternos.poema.generoRecitador")), 1),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_CustomRadioButton, {
            value: "mujer",
            modelValue: _ctx.generoRecitador,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.generoRecitador) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("formEntrevista.datosGenerales.femenino")), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_CustomRadioButton, {
            value: "hombre",
            modelValue: _ctx.generoRecitador,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.generoRecitador) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("formEntrevista.datosGenerales.masculino")), 1)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("audio", _hoisted_8, [
          _createElementVNode("source", {
            src: _ctx.srcPoemaAudioFile,
            type: "audio/mpeg"
          }, null, 8, _hoisted_9)
        ], 512)
      ]),
      (_ctx.listaPoemasFiltrados.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.listaPoemasFiltrados, (poema, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "form-entrevista--pregunta-group",
              key: index
            }, [
              _createElementVNode("label", null, _toDisplayString(poema.author), 1),
              _createVNode(_component_CustomRadioButton, {
                value: poema.id_grabacion,
                modelValue: _ctx.idPoemaEscogido,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.idPoemaEscogido) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(poema.title), 1)
                ]),
                _: 2
              }, 1032, ["value", "modelValue"])
            ]))
          }), 128))
        : (_openBlock(), _createElementBlock("label", _hoisted_10, _toDisplayString(_ctx.t("formEntrevista.serviciosExternos.poema.sinPoemas")), 1))
    ])
  ]))
}