
import { defineComponent } from "vue";

export default defineComponent({
    methods: {
        elementoEscogido() {
            this.$emit("elemento-escogido", this.elemento.id);
        },
    },
    props: {
        elemento: {
            required: true,
            type: Object,
        },
    },
    emits: ["elemento-escogido"],
});
