
import { computed, defineComponent, PropType, watch } from "vue";
import { useStore } from "@/store";

import draggable from "vuedraggable";

import ImageUploader from "@/components/ImageUploader.vue";

import { FotoDifuntoAudiovisual } from "@/types/dominio-ceremonia-civil";
import { STARTING_ID_FOTOS } from "@/types/auxiliares-logica-app";

export default defineComponent({
    setup(props) {
        const store = useStore();

        const listaFotos = computed({
            get() {
                return store.state.fotosDifuntoAudiovisual;
            },
            set(fotos: FotoDifuntoAudiovisual[]) {
                store.commit("updateListaFotos", fotos);
            },
        });

        watch(
            listaFotos,
            (newVal) => {
                if (newVal.length < props.maxFotos) {
                    const hayFotoLibre = newVal.find((foto) => foto.foto === null) !== undefined;

                    if (!hayFotoLibre) {
                        const lowestId = newVal.reduce(
                            (min, curr) => (curr.id < min ? curr.id : min),
                            STARTING_ID_FOTOS
                        );
                        const nextId = lowestId - 1;

                        const listaAddedFoto = newVal.concat([{ id: nextId, foto: null }]);

                        store.commit("updateListaFotos", listaAddedFoto);
                    }
                }
            },
            { deep: true }
        );

        const removeInput = (id: number) => {
            const listaFotos = store.state.fotosDifuntoAudiovisual;
            const indexToRemove = listaFotos.findIndex((foto) => foto.id === id);
            if (indexToRemove !== -1) {
                let mitadInicial: FotoDifuntoAudiovisual[];
                if (indexToRemove === 0) {
                    mitadInicial = [];
                } else {
                    mitadInicial = listaFotos.slice(0, indexToRemove);
                }

                const removedItemList = mitadInicial.concat(listaFotos.slice(indexToRemove + 1));

                store.commit("updateListaFotos", removedItemList);
            } else {
                throw new Error(`Se intentó eliminar foto con un id desconocido: '${id}'`);
            }
        };

        return {
            store,
            listaFotos,
            removeInput,
        };
    },
    components: {
        ImageUploader,
        draggable,
    },
    props: {
        maxFotos: {
            type: Number as PropType<number>,
            required: true,
        },
    },
});
