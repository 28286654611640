import { TraduccionCheckboxes } from "@/modules/FormEntrevistaSelectoresPorPregunta";
import { ConnotacionAtributo } from "@/types/dominio-ceremonia-civil";

export const alternativasFrances: TraduccionCheckboxes = {
    checkboxesCaracter: [
        {
            id: 1,
            label: "Ouvert",
            desarrollos: [
                "Toujours prêts à apprendre de nouvelles choses, à extraire de la vie tout ce qu'elle peut nous donner",
                "Actif et vital, avec cette envie de faire des choses, de vivre intensément, de sentir que le bonheur n'est pas le but, mais le chemin",
                "Avec cette capacité à s'adapter à chaque réalité de la vie, avec cette force, cette vitalité qui ((le/la)) rendait si spécial((/e))",
                "Donner de la valeur à ce qui est vraiment important, apprendre à vivre et à partager la vie en grandissant jour après jour",
                "Sociable, ((il/elle)) aimait les rencontres, les relations, les sentiments, les liens avec ce qui nous unit les uns aux autres",
                "Toujours ouverts à l'approfondissement, à la communication, à la discussion, à l'entrée dans l'autre pour connaître et partager le meilleur de chacun",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 2,
            label: "Joyeux",
            desarrollos: [
                "C'est avec ce sourire que j'ai toujours été prête pour vous tous, le sourire de quelqu'un qui apprend que le bonheur n'est pas le fruit du hasard mais celui d'un choix",
                "Avec toujours cette envie de sourire à la vie, de trouver à chaque instant la joie de vivre",
                "Savoir que la joie est l'art d'être heureux et que le bonheur ne se trouve pas, il se crée",
                "((Il/Elle)) avait compris que la vraie joie est dans les choses simples de la vie, un mot, un geste, ce sourire qu'((il/elle)) savait dessiner sur ses lèvres et sur celles de ses proches",
                "Qui a compris que la joie n'est rien d'autre qu'un plaisir qui se multiplie en le partageant avec d'autres",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 3,
            label: "Positi((f/ve))",
            desarrollos: [
                "Savoir que lorsqu'on en a envie, lorsqu'on a la bonne attitude, tout est possible",
                "Regarder toujours vers l'avant en sachant que les difficultés de la route sont des opportunités déguisées",
                "Conscient que le maintien d'une bonne attitude entraîne une réaction en chaîne de pensées, d'événements et de résultats positifs",
                "Conscients que nous ne sommes pas le produit de nos circonstances mais de nos propres décisions",
                "Préférant toujours voir le bon côté des choses",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 4,
            label: "Réservé((/e))",
            desarrollos: [
                "((Il/Elle)) lui était difficile d'exprimer ce qu'((il/elle)) ressentait, mais ((il/elle)) savait que ce qu'((il/elle)) montrait était la vérité",
                "Sachant que la mesure est un art, ((il/elle)) n'a pas gaspillé de mots supplémentaires, préférant les mots justes",
                "Se passer de l'éloquence pour exprimer en mots tout ce qu'((il/elle)) était capable de ressentir",
                "Donner de la valeur à ce que je ressens au-delà de trouver un moyen de l'exprimer",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
        {
            id: 6,
            label: "Introverti",
            desarrollos: [
                "Cette capacité lui permettait d'observer et de reconnaître de manière simple l'essence des choses",
                "Fidèle à son monde intérieur, à sa façon de comprendre ce qui l'entoure, sans rien demander ni déranger",
                "Trouver dans les profondeurs de son être la compagnie nécessaire pour échapper à sa solitude",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
        {
            id: 32,
            label: "De caractère",
            desarrollos: ["Avec cette forte personnalité qu'((il/elle)) avait"],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
    ],
    checkboxesVinculos: [
        {
            id: 7,
            label: "Familial",
            desarrollos: [
                "La famille a toujours été la valeur la plus précieuse, un rêve devenu réalité qui est né avec leur amour et s'est consolidé au fil du temps, en grandissant et en se nourrissant de respect et de tendresse",
                "Qu'((il/elle)) comprenait que la famille était le plus grand trésor que l'on puisse avoir",
                "Qu'((il/elle)) comprenait que la famille est le lieu où la vie commence et où l'amour ne s'arrête jamais, et que faire partie d'une famille signifie que l'on aime et que l'on est aimé toujours et inconditionnellement",
                "((Il/elle)) a construit une famille, un endroit où aller, quelqu'un à aimer, le meilleur de tous les cadeaux que la vie pouvait lui offrir",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 10,
            label: "L'ami de ses amis",
            desarrollos: [
                "((Il/Elle)) avait toujours une main tendue vers celui qui en avait besoin, un sourire pour encourager, un regard pour comprendre, une larme pour rejoindre la douleur de l'autre et un mot pour lui faire savoir qu'((il/elle)) était toujours là",
                "((Il/Elle)) avait compris qu'une amitié ne se développe pas par la présence de personnes, mais par la magie de savoir que, bien que vous ne les voyiez pas, vous les portez dans votre cœur",
                "((Il/Elle)) est resté fidèle à l'idée que l'amitié est un amour qui ne se communique pas par les sens, qu'elle existe et qu'((il/elle)) faut donc la valoriser, la choyer et en prendre soin comme d'un petit trésor fragile",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 33,
            label: "Aimés dans leur environnement (Autres)",
            desarrollos: ["Aimée de tous ceux avec qui ((il/elle)) a vécu et partagé sa vie quotidienne."],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: false,
        },
        {
            id: 11,
            label: "Indépendant / Solitaire",
            desarrollos: [
                "Qu'((il/elle)) aimait être en sa propre compagnie, profiter de ses pensées, de ses projets, de ses rêves..",
                "Qui a investi son temps pour comprendre que l'important dans la vie était de continuer à vivre et à laisser vivre",
                "Qu'((il/elle)) est sorti de son silence en disant beaucoup plus qu'((il/elle)) ne disait et en se faisant comprendre beaucoup plus qu'((il/elle)) ne parlait",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
    ],
    checkboxesAficiones: [
        {
            id: 12,
            label: "Les Arts",
            desarrollos: [
                "Sa sensibilité à la beauté, sa passion pour la création humaine sont à l'origine de la fascination qui l'anime",
                "La <b>musique</b>, sa musique qu'((il/elle)) aimait tant écouter, ressentir, apprécier, des émotions mêlées à des réalités présentes et passées qui constituent une passion unique",
                "La <b>peinture</b>, ces traits sur la toile qu'((il/elle)) imitait dans son imagination entre coups de pinceau, paysages et portraits",
                "La <b>littérature</b>, ses livres, des heures et des heures de lecture qui ont transporté son esprit dans ces lieux pleins de personnages avec leurs histoires, leurs vies, leurs passions et leurs sentiments",
                "Le <b>cinéma</b>, la magie du celluloïd qui a su lui donner tant d'émotions avec ses scènes pleines de joies et de peines, de sourires et de larmes",
                "Le <b>théâtre</b>, ces textes qui semblaient impossibles à retenir, les nerfs des représentations, les répétitions..",
            ],
            desarrolloFijo: 0,
        },
        {
            id: 13,
            label: "La Cuisine",
            desarrollos: [
                "Souvenez-vous d'((il/elle)), de son sens de la cuisine et de ces plats uniques avec lesquels ((il/elle)) a nourri non seulement votre estomac mais aussi votre cœur",
                "Un prétexte pour vous garder tous près de lui, autour d'une table, à savourer ce qu'((il/elle)) aimait le plus, ces plats inoubliables et pleins d'amour",
            ],
        },
        {
            id: 14,
            label: "Le Sport",
            desarrollos: [
                "((Il/Elle)) aimait tous les sports qu'((il/elle)) suivait",
                "Le football, sa passion, un rendez-vous obligé, ses espoirs placés dans onze joueurs et un ballon qui ne lui a pas toujours donné la joie qu'((il/elle)) attendait mais qu'((il/elle)) célébrait avec force chaque fois qu'ils entraient sur le terrain",
                "Le basket-ball, ((il/elle)) aimait le regarder, le sentir, le vivre..",
                "Le tennis, ce silence brisé par l'impact de la balle sur la raquette, ces points qui n'en finissent pas..",
                "Le moteur, les courses, les circuits, les virages, les tours de reconnaissance, les dépassements impossibles..",
            ],
        },
        {
            id: 15,
            label: "La Nature",
            desarrollos: [
                "La mer, l'eau cristalline, les vagues, la plage, le sable, les promenades, tout ce que signifie être près de la mer, était une raison de sourire",
                "Les montagnes, ces promenades à travers les arbres, les longs chemins pleins de terre et de pierres, le bruit des animaux, l'odeur des fleurs, des paysages uniques qui le reliaient au plus profond de lui-même",
                "Son jardin, sa terre, la terre qu'((il/elle)) a cultivée de ses propres mains pour recevoir la nourriture qu'((il/elle)) a recueillie et partagée avec vous tous",
                "Profiter de la tendresse que seule la compagnie de nos amis à quatre pattes peut nous apporter",
            ],
        },
        {
            id: 28,
            label: "Le Travail",
            desarrollos: [
                "Toujours engagé dans de nouveaux projets qui remplissaient les heures, les jours et surtout l'espace de sa créativité",
                "Le travail n'est pas seulement un moyen de subsistance, mais aussi une petite motivation qui donne un sens à leur vie quotidienne",
                "Ces attentes professionnelles répondent au désir de grandir, de s'améliorer en tant que personne",
            ],
        },
        {
            id: 20,
            label: "L'artisanat",
            desarrollos: [
                `Bricoleur, ((il/elle)) était ce qu'on appelle un((/e)) "((homme à tout faire/bricoleuse))", avec cette capacité à faire, construire, refaire et reconstruire, et tout cela de ses propres mains, à partir de rien et en prenant soin de chaque détail.`,
                "La couture, la couture, cet art qui exige des mains qu'elles créent méthodiquement les détails qui décorent ces souvenirs",
            ],
        },
    ],
    checkboxesActitud: [
        {
            id: 24,
            label: "Travailleur / Combattant",
            desarrollos: [
                "((Il/Elle)) a compris que la vie donne les batailles les plus difficiles aux meilleurs combattants, et sans aucun doute ((il/elle)) a su se battre pour aller de l'avant dans sa vie",
                "((Il/Elle)) a compris que le vrai combattant n'est pas celui qui se bat avec ses poings pour un trophée, mais celui qui se bat avec son cœur pour réaliser ses rêves, et ((il/elle)) s'est battu et s'est battu avec son cœur jusqu'à ce qu'((il/elle)) puisse voir son rêve devenir réalité",
                "((Il/Elle)) a compris que le dénominateur commun de la réussite est le travail et que la réussite n'est rien d'autre que le fait de travailler dur pour quelque chose que l'on aime",
                "((Il/Elle)) a toujours su qu'((il/elle)) était plus important d'apprendre à se relever que d'abandonner face à l'adversité",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 25,
            label: "Généreux",
            desarrollos: [
                "((Il/Elle)) a compris que la générosité n'est pas un acte ponctuel, mais un mode de vie",
                "Généreux, non seulement dans les choses matérielles, mais surtout dans l'amour, dans le fait de donner et de recevoir, inconditionnellement, sans rien demander en retour",
                "((Il/Elle)) a su se donner aux autres de manière désintéressée, partageant ce qu'((il/elle)) était, ce qu'((il/elle)) avait, transmettant ce sentiment de paix et d'amour que vous avez tous reçu",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 26,
            label: "Humble",
            desarrollos: [
                "Qu'((il/elle)) n'avait pas besoin de grands luxes dans sa vie, mais seulement de petites choses qui la rendaient plus grande",
                "Sachant que dans le caractère, dans la forme, dans le style, en toutes choses, l'excellence suprême est la simplicité",
                "Qui savait apprécier les petites choses de la vie, sans grandiloquence ni attirail cachant la vraie vérité",
                "((Il/Elle)) a su voir que tout est très difficile avant d'être simple, et fidèle à son mandat, ((il/elle)) a choisi la facilité et de vivre dans l'humilité et la simplicité",
            ],
            connotacion: ConnotacionAtributo.Positiva,
            incluyeAudiovisual: true,
        },
        {
            id: 27,
            label: "Discret / Prudent",
            desarrollos: [
                "((Il/Elle)) a compris que nous devons souvent parler pour être entendus, mais qu'à d'autres moments, nous devons nous taire pour être appréciés, et c'est ainsi que, depuis le silence, ((il/elle)) a tout dit",
                "Savoir qu'((il/elle)) est aussi possible d'aimer en silence, de regretter sans crier, de tomber amoureux sans le dire, et d'être heureux sans le publier",
                "Sachant que paraître discret équivaut à comprendre une chose et que c'est probablement beaucoup plus facile et moins compromettant",
                "((Il/Elle)) n'aimait pas être au centre de l'attention, mais ((il/elle)) savait être au centre de ce qui était important pour lui",
            ],
            connotacion: ConnotacionAtributo.Negativa,
            incluyeAudiovisual: false,
        },
    ],
    checkboxesRecuerdo: [
        {
            id: 29,
            label: "Pour ses hobbies",
            desarrollos: [
                "Avec tout ce qu'((il/elle)) aimait faire et qui identifiait ses priorités, en remplissant ce temps d'expériences inoubliables",
            ],
        },
        {
            id: 30,
            label: "Avec la famille",
            desarrollos: [
                "A vos côtés, unis dans l'affection, avec vous tous qui avez formé ce rêve que vous avez su transformer en une réalité pleine d'amour et de tendresse",
            ],
        },
        {
            id: 31,
            label: "En tant que bonne personne",
            desarrollos: [
                "Avec toutes les bonnes choses que nous avons décrites ici aujourd'hui et qui la définissent comme ce qu'elle a été pour vous tous: une bonne personne, une bonne personne",
                "Fidèle à son essence, à sa façon de voir, de sentir et de comprendre la vie",
                "Avec la tranquillité d'esprit d'avoir vécu en paix et en harmonie avec soi-même et son environnement",
            ],
        },
    ],
};
