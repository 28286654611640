
import { defineComponent } from "vue";

import Catalogo from "./Catalogo.vue";

export default defineComponent({
    methods: {
        elementoEscogido(idElemento: number) {
            this.$emit("elemento-escogido", idElemento);

            this.closeModal();
        },
        closeModal() {
            this.$emit("toggle-modal-visibility", false);
        },
    },
    props: {
        visible: {
            required: true,
            type: Boolean,
        },
        elementos: {
            required: true,
            type: Array,
        },
        titulo: {
            required: true,
            type: String,
        },
    },
    emits: ["toggle-modal-visibility", "elemento-escogido"],
    components: {
        Catalogo,
    },
});
