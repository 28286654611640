
import { defineComponent } from "vue";

import TransitionCollapsing from "./TransitionCollapsing.vue";

export default defineComponent({
    data() {
        return {};
    },
    methods: {
        despliegaItem() {
            this.$emit("despliega-item", this.id);
        },
    },
    props: {
        id: {
            required: true,
            type: Number,
        },
        title: {
            required: true,
            type: String,
        },
        ordinalTitle: {
            required: false,
            type: String,
        },
        isDesplegado: {
            required: true,
            type: Boolean,
        },
    },
    emits: ["despliega-item"],
    components: {
        TransitionCollapsing,
    },
    inheritAttrs: false,
});
