import { ComponentCustomProperties, Ref } from "vue";

import {
    Ceremonia,
    AudiovisualAutomaticoGeneracion,
    ServicioMusical,
    PIEZA_NULL,
    PIEZA_SIN_REGISTRO,
} from "@/types/dominio-ceremonia-civil";
import { convertFileToBase64, filtraDesarrollablesPresentables } from "@/modules/helpers";
import { getFullSelectorList } from "@/modules/FormEntrevistaSelectoresPorPregunta";
import { AppLocales, GeneracionAudiovisuales } from "@/types/auxiliares-logica-app";
import BackendAPIConsumer from "@/classes/BackendAPIConsumer";
import Alerta from "@/modules/Alerta";
import { Config } from "@/modules/config-por-entorno";

function construyeParamsAudiovisual(
    ceremonia: Ref<Ceremonia>,
    fotosCodificadas: Promise<string[]>,
    lang: AppLocales,
    translations: ComponentCustomProperties["$t"]
): Promise<AudiovisualAutomaticoGeneracion> {
    const selectoresBase = getFullSelectorList(lang);

    let atributos;
    if (ceremonia.value.respuestasEntrevista.audiovisualAutomatico.atributosAdicionales.usaSoloAtributosAdicionales) {
        atributos = ceremonia.value.respuestasEntrevista.audiovisualAutomatico.atributosAdicionales.listaAtributos;
    } else {
        const seleccionadosEntrevista = [
            ...filtraDesarrollablesPresentables(ceremonia.value.respuestasEntrevista.caracter, selectoresBase),
            ...filtraDesarrollablesPresentables(ceremonia.value.respuestasEntrevista.actitud, selectoresBase),
            ...filtraDesarrollablesPresentables(ceremonia.value.respuestasEntrevista.vinculos, selectoresBase),
        ];

        atributos = seleccionadosEntrevista.concat(
            ceremonia.value.respuestasEntrevista.audiovisualAutomatico.atributosAdicionales.listaAtributos
        );
    }

    const audiovisualParams: AudiovisualAutomaticoGeneracion = {
        idCeremonia: ceremonia.value.uuidv4,
        nombreDifunto: `${ceremonia.value.difunto.nombre} ${ceremonia.value.difunto.apellidos}`,
        atributos: atributos,
        textoDespedida: translations("audiovisual.textoDespedidaDefecto"),
        idPoema: ceremonia.value.respuestasEntrevista.audiovisualAutomatico.idPoemaEscogido,
        codigoTema: ceremonia.value.respuestasEntrevista.audiovisualAutomatico.codigoTemaEscogido,
        fraseDespedida: ceremonia.value.respuestasEntrevista.audiovisualAutomatico.fraseDespedida,
    };

    return fotosCodificadas.then((fotos) => {
        audiovisualParams.fotosDifuntoCodificadas = fotos;

        return audiovisualParams;
    });
}

function generacionPreceremonial(
    BACKEND_API_CONSUMER: BackendAPIConsumer,
    ceremonia: Ref<Ceremonia>,
    paramsAudiovisual: AudiovisualAutomaticoGeneracion,
    onError: (reason: Error) => void
) {
    const audiovisualParamsCopy = JSON.parse(JSON.stringify(paramsAudiovisual));
    if (ceremonia.value.respuestasEntrevista.audiovisualAutomatico.sinPoemaEnCeremonial) {
        delete audiovisualParamsCopy.idPoema;
    }

    BACKEND_API_CONSUMER.generaAudiovisualPreceremonial(audiovisualParamsCopy).catch(onError);
    return process.env.VUE_APP_BACKEND_URL + "/multimedia/preceremonial/" + ceremonia.value.uuidv4;
}

function generacionEntrega(
    BACKEND_API_CONSUMER: BackendAPIConsumer,
    ceremonia: Ref<Ceremonia>,
    paramsAudiovisual: AudiovisualAutomaticoGeneracion,
    onError: (reason: Error) => void
) {
    const servMusical = ceremonia.value.respuestasEntrevista.servicioMusical;
    const piezasRegistradasERP = [];
    if (servMusical.musicaContratada) {
        let piezaX: keyof ServicioMusical["repertorio"];
        for (piezaX in servMusical.repertorio) {
            const idPieza = servMusical.repertorio[piezaX].id;
            if (idPieza !== PIEZA_NULL && idPieza !== PIEZA_SIN_REGISTRO) {
                piezasRegistradasERP.push(idPieza);
            }
        }
    }

    paramsAudiovisual.idsPiezasMusicales = piezasRegistradasERP;

    BACKEND_API_CONSUMER.generaAudiovisualEntrega(paramsAudiovisual).catch(onError);

    return process.env.VUE_APP_BACKEND_URL + "/multimedia/entrega/" + ceremonia.value.uuidv4;
}

function generacionCrematori(
    BACKEND_API_CONSUMER: BackendAPIConsumer,
    ceremoniaId: Ceremonia["uuidv4"],
    paramsAudiovisual: AudiovisualAutomaticoGeneracion,
    onError: (reason: Error) => void
) {
    const audiovisualParamsCopy = JSON.parse(JSON.stringify(paramsAudiovisual));
    delete audiovisualParamsCopy.idPoema;

    BACKEND_API_CONSUMER.generaAudiovisualCrematori(audiovisualParamsCopy).catch(onError);
    return process.env.VUE_APP_BACKEND_URL + "/multimedia/crematorio/" + ceremoniaId;
}

export default function useAudiovisualGenerator(
    BACKEND_API_CONSUMER: BackendAPIConsumer,
    lang: AppLocales,
    translations: ComponentCustomProperties["$t"],
    configEntorno: Config,
    ceremonia: Ref<Ceremonia>,
    fotosDifunto: File[]
) {
    const fotosCodificadas = Promise.all(fotosDifunto.map(convertFileToBase64));

    const paramsAudiovisual = construyeParamsAudiovisual(ceremonia, fotosCodificadas, lang, translations);

    function generaAudiovisuales(): Promise<GeneracionAudiovisuales> {
        const onError = (err: Error) => {
            Alerta.error(translations("composicionCeremonia.audiovisualError"), err.message);
        };

        return paramsAudiovisual.then((params) => {
            let linkAudiovisualPrecerem = undefined;
            if (configEntorno.generacion.audiovisuales.preceremonial === true) {
                linkAudiovisualPrecerem = generacionPreceremonial(BACKEND_API_CONSUMER, ceremonia, params, onError);
            }

            let linkAudiovisualEntrega = undefined;
            if (configEntorno.generacion.audiovisuales.entrega === true) {
                linkAudiovisualEntrega = generacionEntrega(BACKEND_API_CONSUMER, ceremonia, params, onError);
            }

            let linkAudiovisualCrematori = undefined;
            if (configEntorno.generacion.audiovisuales.crematori === true) {
                linkAudiovisualCrematori = generacionCrematori(
                    BACKEND_API_CONSUMER,
                    ceremonia.value.uuidv4,
                    params,
                    onError
                );
            }

            return {
                linkPreceremonial: linkAudiovisualPrecerem,
                linkEntrega: linkAudiovisualEntrega,
                linkCrematori: linkAudiovisualCrematori,
            };
        });
    }

    return {
        generaAudiovisuales,
    };
}
