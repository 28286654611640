
import { defineComponent, onMounted, ref } from "vue";

import { saveAs } from "file-saver";
import { useRoute } from "vue-router";
import { BACKEND_API_CONSUMER, strictInject, T } from "@/symbols";

import AudiovisualProgressBar from "@/components/AudiovisualProgressBar.vue";

export default defineComponent({
    setup() {
        const t = strictInject(T);
        const backendApiConsumer = strictInject(BACKEND_API_CONSUMER);
        const route = useRoute();

        const linkVideo = ref("");
        const nombreDifunto = ref("");
        const generating = ref(false);
        const generationCompleted = ref(false);
        const progressDetails = ref({});

        const descargaVideo = () => {
            fetch(linkVideo.value)
                .then((response) => response.blob())
                .then((blob) => saveAs(blob, "audiovisual-" + route.params.tipoArchivo + ".mp4"));
        };

        onMounted(() => {
            let done = false;
            const getVideo = () => {
                if (!done) {
                    backendApiConsumer
                        .checkProcesoGeneracionAudiovisual(
                            route.params.tipoArchivo as string,
                            route.params.idMultimediaDifunto as string
                        )
                        .then((audiovisual) => {
                            nombreDifunto.value = audiovisual.nombreDifunto;
                            generating.value = audiovisual.generating;
                            generationCompleted.value = audiovisual.generationCompleted;

                            if (null !== audiovisual.progressDetails) {
                                progressDetails.value = audiovisual.progressDetails;
                            }

                            if (null !== audiovisual.src) {
                                linkVideo.value = audiovisual.src;
                                done = true;
                            }
                        });
                }
            };

            getVideo();
            setInterval(getVideo, 3000);
        });

        return {
            t,
            tipoArchivo: route.params.tipoArchivo,
            linkVideo,
            nombreDifunto,
            descargaVideo,
            generating,
            generationCompleted,
            progressDetails,
        };
    },
    components: {
        AudiovisualProgressBar,
    },
});
