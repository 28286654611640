
import { defineComponent } from "vue";

import ModalCatalogo from "@/components/ModalCatalogo.vue";
import Editor from "@/components/Editor.vue";
import { Seccion, Plantilla } from "@/types/dominio-ceremonia-civil";
import { ContenidoFuente, rellenaPlantilla } from "@/modules/RellenadorPlantillas";
import { InterpretePlantillaCeremonia } from "@/modules/interprete-plantillas-ceremonia/InterpretePlantillaCeremonia";

export default defineComponent({
    data() {
        return {
            plantillaEscogida: (this.seccion as Seccion).plantillas[0],
            texto: this.rellenamientoSeccion[1],
            modalCatalogoVisible: false,
            idElementoEscogidoModal: -1,
        };
    },
    watch: {
        idElementoEscogidoModal(idNuevoEl) {
            const plantillaEscogida = this.seccion.plantillas.find(
                (plantilla: Plantilla) => plantilla.id === idNuevoEl
            );

            if (plantillaEscogida !== undefined) {
                this.plantillaEscogida = plantillaEscogida;
            } else {
                throw new Error(
                    `No se encontró ningún elemento con el id provisto "${idNuevoEl}" en la sección "${this.seccion.nombreSeccion}" en respuesta al evento de elección de Elemento en el Catálogo.`
                );
            }
        },
        plantillaEscogida: {
            handler: function (plantillaUso) {
                this.texto = rellenaPlantilla(
                    plantillaUso.texto,
                    {
                        cumplimentacionDifunto: this.cumplimentacionDifunto,
                        listadoPlantillas: this.listadoPlantillas,
                    } as ContenidoFuente,
                    InterpretePlantillaCeremonia
                );
            },
            immediate: true,
        },
        texto: {
            handler: function () {
                this.$emit("update:rellenamientoSeccion", {
                    idSeccion: this.seccion.idSeccion,
                    plantillaUsada: this.plantillaEscogida,
                    textoRellenado: this.texto,
                });
            },
            immediate: true,
        },
    },
    methods: {
        toggleModal() {
            this.modalCatalogoVisible = !this.modalCatalogoVisible;
        },
    },
    components: {
        Editor,
        ModalCatalogo,
    },
    props: {
        seccion: {
            required: true,
            type: Object,
        },
        cumplimentacionDifunto: {
            required: true,
            type: Object,
        },
        listadoPlantillas: {
            required: true,
            type: Array,
        },
        rellenamientoSeccion: {
            required: true,
            type: Array,
        },
    },
    emits: ["update:rellenamientoSeccion"],
});
