
import { computed, defineComponent, PropType, ref } from "vue";

import { FotoDifuntoAudiovisual } from "@/types/dominio-ceremonia-civil";

export default defineComponent({
    setup(props, { emit }) {
        const imgInputRef = ref<HTMLInputElement | null>(null);

        function changeImage(e: Event) {
            const files = (e.target as HTMLInputElement).files;
            if (files) {
                emit("update:foto", files[0]);
            } else {
                throw new Error("Error subiendo imagen en el ImageUploader.");
            }
        }

        function subeImagen() {
            if (imgInputRef.value) {
                imgInputRef.value.click();
            }
        }

        const imgPreview = computed(() => (props.foto !== null ? URL.createObjectURL(props.foto) : ""));

        return {
            imgInputRef,
            imgPreview,
            changeImage,
            subeImagen,
        };
    },
    emits: ["update:foto"],
    props: {
        foto: {
            type: Object as PropType<FotoDifuntoAudiovisual["foto"]>,
            required: true,
        },
    },
});
