import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "form-entrevista--lista-inputs" }
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = { class: "inline-els" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t("formEntrevista.extras.labelAdjetivos")), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listaAux, (atributo, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("input", {
          key: index,
          class: "form-entrevista--respuesta-texto form-entrevista--respuesta-texto__inline",
          "onUpdate:modelValue": ($event: any) => ((_ctx.listaAux[index]) = $event),
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.escribeAtributo && _ctx.escribeAtributo(...args)))
        }, null, 40, _hoisted_2)), [
          [_vModelText, _ctx.listaAux[index]]
        ])
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.soloAdicionalesBridge) = $event))
      }, null, 512), [
        [_vModelCheckbox, _ctx.soloAdicionalesBridge]
      ]),
      _createElementVNode("label", null, _toDisplayString(_ctx.$t("formEntrevista.extras.labelCheckboxSustitucion")), 1)
    ])
  ], 64))
}