import TokenTipologiaCeremonia from "../classes/TokenTipologiaCeremonia";

type Filtrable<T> = T & { expresionFiltrado: string };

export function filtraElementosSegunTipologiaCeremonia<T>(
    tokenTipoCeremonia: TokenTipologiaCeremonia,
    elementos: Filtrable<T>[]
) {
    return elementos.filter((elemento: Filtrable<T>) => {
        return tokenTipoCeremonia.testAgainstExpression(elemento.expresionFiltrado);
    }) as T[];
}
