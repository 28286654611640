
import { defineComponent } from "vue";

import { useRoute, useRouter } from "vue-router";

import { versionesEntrevista } from "@/types/auxiliares-logica-app";
import { strictInject, T } from "@/symbols";

export default defineComponent({
    setup() {
        const t = strictInject(T);
        // const locale = strictInject(LOCALE);
        const route = useRoute();
        const router = useRouter();

        // onMounted(() => {
        //     const allowedLocales = getEnv("VUE_APP_I18N_SUPPORTED_LOCALES").split(",");
        //     const routeLocale = route.params.locale as string;
        //     if (routeLocale) {
        //         if (allowedLocales.includes(routeLocale)) {
        //             locale.value = routeLocale as AppLocales;
        //         } else {
        //             console.error(`Locale ${routeLocale} inexistente.`);
        //         }
        //     }
        // });

        // function goPostLoginPage() {
        //     const configEntorno = useConfigPorEntorno().configEntorno;
        //     router.push({ name: configEntorno.paginaPrincipal });
        // }

        type posiblesVersiones = typeof versionesEntrevista[keyof typeof versionesEntrevista];
        const escogeVersion = (version: posiblesVersiones) => {
            router.push({ name: version });
        };

        return {
            // apiUrl,
            t,
            // goPostLoginPage,
            escogeVersion,
            versionesEntrevista,
        };
    },
});
