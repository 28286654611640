import { accessObjectSubpropertyByString, ObjectTraversingError } from "@/modules/ProgrammaticObjectTraversing";
import { rellenaPlantilla, ContenidoFuente } from "@/modules/RellenadorPlantillas";
import {
    IncorrespondenciaContenidoFuenteError,
    RellenadoPlantillaError,
    contextualizaErrorRellenadoRecursivo,
} from "@/modules/errores-rellenador-plantillas-ceremonia";
import * as TiposDCeremonia from "@/types/dominio-ceremonia-civil";
import { AccionReferencial, SEPARADOR_LISTADO_PLACEHOLDERS } from "./SintaxisPlaceholders";
import { InterpretePlantillaCeremonia } from "./InterpretePlantillaCeremonia";
import * as TiposDifunto from "@/modules/Difunto";

const cumplimentacionDifuntoRootPropName = "cumplimentacionDifunto";
const listadoPlantillasRootPropName = "listadoPlantillas";

export const obtienePropCumplimentacionDifunto: AccionReferencial = (fullPlaceholder: string, propertyPath: string) => {
    return (contenidoFuente: ContenidoFuente) => {
        const fullPropPath = `${cumplimentacionDifuntoRootPropName}.${propertyPath}`;
        try {
            return accessObjectSubpropertyByString(contenidoFuente, fullPropPath);
        } catch (error) {
            if (error instanceof ObjectTraversingError) {
                throw new IncorrespondenciaContenidoFuenteError(fullPropPath, fullPlaceholder, contenidoFuente);
            } else {
                throw error;
            }
        }
    };
};

export const obtieneJSONPropCumplimentacionDifunto: AccionReferencial = (
    fullPlaceholder: string,
    propertyPath: string
) => {
    return (contenidoFuente: ContenidoFuente) => {
        const fullPropPath = `${cumplimentacionDifuntoRootPropName}.${propertyPath}`;
        try {
            return JSON.stringify(accessObjectSubpropertyByString(contenidoFuente, fullPropPath));
        } catch (error) {
            if (error instanceof ObjectTraversingError) {
                throw new IncorrespondenciaContenidoFuenteError(fullPropPath, fullPlaceholder, contenidoFuente);
            } else {
                throw error;
            }
        }
    };
};

export const obtienePlantillaPorNombre: AccionReferencial = (
    fullPlaceholder: string,
    nombrePlantillaTarget: string
) => {
    // Why this throws an error if ContenidoFuenteCeremonia complies with the index signature of ContenidoFuente (are compatible types)
    // Moreover:
    // #Function Parameter Bivariance: https://www.typescriptlang.org/docs/handbook/type-compatibility.html
    // https://stackoverflow.com/questions/39569016/typescript-subtyping-and-covariant-argument-types
    // return (contenidoFuente: ContenidoFuenteCeremonia) => {
    return (contenidoFuente: ContenidoFuente) => {
        const plantillaTarget = (contenidoFuente as TiposDCeremonia.ContenidoFuenteCeremonia).listadoPlantillas.find(
            (plantilla) => plantilla.nombre === nombrePlantillaTarget
        );

        if (plantillaTarget) {
            try {
                return rellenaPlantilla(plantillaTarget.texto, contenidoFuente, InterpretePlantillaCeremonia);
            } catch (e) {
                if (e instanceof RellenadoPlantillaError) {
                    throw contextualizaErrorRellenadoRecursivo(e, fullPlaceholder);
                } else {
                    throw e;
                }
            }
        } else {
            throw new IncorrespondenciaContenidoFuenteError(
                `${listadoPlantillasRootPropName}[x]["nombre"] == ${nombrePlantillaTarget}`,
                fullPlaceholder,
                contenidoFuente
            );
        }
    };
};

export const construyeListadoPlaceholdersDeClases: AccionReferencial = (
    fullPlaceholder: string,
    listadoClases: string
) => {
    return (contenidoFuente: ContenidoFuente) => {
        const clasesPlaceholder = listadoClases.split(",");

        const plantillasDeClases = (
            contenidoFuente as TiposDCeremonia.ContenidoFuenteCeremonia
        ).listadoPlantillas.filter((plantilla) => {
            for (const clase of plantilla.clases) {
                if (clasesPlaceholder.includes(clase)) {
                    return true;
                }
            }
        });

        if (plantillasDeClases.length === 0) {
            throw new IncorrespondenciaContenidoFuenteError(
                `${listadoPlantillasRootPropName}[x]["clases"].intersects(${clasesPlaceholder.join(", ")})`,
                fullPlaceholder,
                contenidoFuente
            );
        }

        return plantillasDeClases.map((plantilla) => `[# ${plantilla.nombre} #]`).join(SEPARADOR_LISTADO_PLACEHOLDERS);
    };
};

export const procesaOperadorCondicional: AccionReferencial = (
    fullPlaceholder: string,
    propContenidoFuenteCondicion: string,
    alternativaTruthy: string,
    alternativaFalsy?: string
) => {
    return (contenidoFuente: ContenidoFuente) => {
        const fullPropPath = `${cumplimentacionDifuntoRootPropName}.${propContenidoFuenteCondicion}`;
        let valorCondicion;
        try {
            valorCondicion = accessObjectSubpropertyByString(contenidoFuente, fullPropPath);
        } catch (error) {
            if (error instanceof ObjectTraversingError) {
                valorCondicion = false;
            } else {
                throw error;
            }
        }

        let condicionResuelta;
        if (valorCondicion instanceof Array) {
            condicionResuelta = valorCondicion.length > 0 ? true : false;
        } else if (valorCondicion instanceof Object) {
            condicionResuelta = Object.keys(valorCondicion).length > 0 ? true : false;
        } else {
            condicionResuelta = valorCondicion ? true : false;
        }

        let placeholderAlternativa;
        if (condicionResuelta) {
            placeholderAlternativa = alternativaTruthy;
        } else {
            if (alternativaFalsy !== undefined) {
                placeholderAlternativa = alternativaFalsy;
            } else {
                placeholderAlternativa = "";
            }
        }

        try {
            return rellenaPlantilla(placeholderAlternativa, contenidoFuente, InterpretePlantillaCeremonia);
        } catch (error) {
            if (error instanceof RellenadoPlantillaError) {
                throw contextualizaErrorRellenadoRecursivo(error, fullPlaceholder);
            } else {
                throw error;
            }
        }
    };
};

export const resuelveGenero: AccionReferencial = (
    fullPlaceholder: string,
    alternativaMasculina = "",
    alternativaFemenina = ""
) => {
    return (contenidoFuente: ContenidoFuente) => {
        if (
            (contenidoFuente as TiposDCeremonia.ContenidoFuenteCeremonia).cumplimentacionDifunto.difunto.sexo ===
            TiposDifunto.Sexo.Hombre
        ) {
            return alternativaMasculina;
        } else if (
            (contenidoFuente as TiposDCeremonia.ContenidoFuenteCeremonia).cumplimentacionDifunto.difunto.sexo ===
            TiposDifunto.Sexo.Mujer
        ) {
            return alternativaFemenina;
        } else {
            return `${alternativaMasculina}/${alternativaFemenina}`;
        }
    };
};
