import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "entrevista-page" }
const _hoisted_2 = { class: "entrevista-box" }
const _hoisted_3 = { class: "entrevista--header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormEntrevistaBloqueado = _resolveComponent("FormEntrevistaBloqueado")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("entrevista")), 1),
      _createVNode(_component_FormEntrevistaBloqueado, {
        onCompletaEntrevista: _ctx.generaAudiovisualCrematorio,
        "id-ceremonia": _ctx.idCeremonia,
        "continua-ficha-difunto": _ctx.fichaDifunto,
        "continua-respuestas-entrevista": _ctx.respuestasEntrevista,
        versionEntrevista: _ctx.rutaTipoEntrevista,
        bloqueoCrematorioTerrassa: _ctx.bloqueoCrematorioTerrassa,
        bloqueoCrematorioMemora: _ctx.bloqueoCrematorioMemora
      }, null, 8, ["onCompletaEntrevista", "id-ceremonia", "continua-ficha-difunto", "continua-respuestas-entrevista", "versionEntrevista", "bloqueoCrematorioTerrassa", "bloqueoCrematorioMemora"])
    ])
  ]))
}