
import { defineComponent, PropType } from "vue";

import { ButtonlikeSelector, findSelectorById } from "@/modules/FormEntrevistaSelectoresPorPregunta";
import { AlternativasSelectoresT } from "@/types/dominio-ceremonia-civil";

import AlternativaSelectorControlador from "@/components/AlternativaSelectorControlador.vue";

function escogeTextoAlternativaSelectorPorDefectoAleatoriamente(
    listaSelectores: ButtonlikeSelector[],
    targetSelectorId: number
) {
    const selector = findSelectorById(targetSelectorId, listaSelectores);
    const indexDesarrolloRandom = Math.floor(Math.random() * selector.desarrollos.length);
    return selector.desarrollos[indexDesarrolloRandom];
}

export default defineComponent({
    computed: {
        seleccionAtributosClon() {
            const copiedSet = new Set<number>();
            this.opcionesPreguntaSeleccionadas.forEach((val) => {
                copiedSet.add(val);
            });

            return copiedSet;
        },
        alternativasSelectoresPuente: {
            get(): AlternativasSelectoresT {
                return this.valoresAlternativosSelectores;
            },
            set(val: AlternativasSelectoresT) {
                this.$emit("update:valoresAlternativosSelectores", val);
            },
        },
        enlaceComputed(): string {
            if (this.enlace) {
                return this.enlace;
            } else {
                return this.$t("alternativasSelectoresPregunta.enlacePorDefecto");
            }
        },
    },
    methods: {
        actualizaTextoAlternativo(selectorId: number, texto: string) {
            this.alternativasSelectoresPuente[selectorId].texto = texto;
        },
        getSelector(selectorId: number): ButtonlikeSelector {
            return findSelectorById(selectorId, this.listadoValoresDefecto);
        },
        elementoDiferenteEntreArrays<T>(
            array1: T[],
            array2: T[]
        ): { elementoDiferente: T | null; procedenciaArray: 1 | 2 | null } {
            const diferenteEnPrimerArray = array1.filter((elemento) => !array2.includes(elemento));
            let diferenteEnSegundoArray: T[] = [];

            let elementoDiferente;
            let procedenciaArray: 1 | 2 | null;
            if (diferenteEnPrimerArray.length > 0) {
                elementoDiferente = diferenteEnPrimerArray[0];
                procedenciaArray = 1;
            } else {
                diferenteEnSegundoArray = array2.filter((elemento) => !array1.includes(elemento));

                if (diferenteEnSegundoArray.length > 0) {
                    elementoDiferente = diferenteEnSegundoArray[0];
                    procedenciaArray = 2;
                } else {
                    elementoDiferente = null;
                    procedenciaArray = null;
                }
            }

            if (diferenteEnPrimerArray.length > 1 || diferenteEnSegundoArray.length > 1) {
                throw new Error("Los arrays difieren en más de 1 elemento.");
            }

            return {
                elementoDiferente,
                procedenciaArray,
            };
        },
    },
    watch: {
        seleccionAtributosClon(newVal: Set<number>, oldVal: Set<number>) {
            const newAtributosList = Array.from(newVal);
            const oldAtributosList = Array.from(oldVal);

            const { elementoDiferente: selectorIdDiferente, procedenciaArray } =
                this.elementoDiferenteEntreArrays<number>(newAtributosList, oldAtributosList);

            if (selectorIdDiferente !== null) {
                if (procedenciaArray === 1) {
                    const newSelected = findSelectorById(selectorIdDiferente, this.listadoValoresDefecto);
                    if (newSelected.desarrolloFijo !== undefined) {
                        this.alternativasSelectoresPuente[selectorIdDiferente] = {
                            texto: newSelected.desarrollos[newSelected.desarrolloFijo],
                        };
                    } else {
                        this.alternativasSelectoresPuente[selectorIdDiferente] = {
                            texto: escogeTextoAlternativaSelectorPorDefectoAleatoriamente(
                                this.listadoValoresDefecto,
                                selectorIdDiferente
                            ),
                        };
                    }
                } else {
                    delete this.alternativasSelectoresPuente[selectorIdDiferente];
                }
            }
        },
    },
    props: {
        opcionesPreguntaSeleccionadas: {
            required: true,
            type: Object as PropType<Set<number>>,
        },
        listadoValoresDefecto: {
            required: true,
            type: Array as PropType<ButtonlikeSelector[]>,
        },
        valoresAlternativosSelectores: {
            required: true,
            type: Object,
        },
        enlace: {
            required: false,
            type: String,
        },
        simple: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ["update:valoresAlternativosSelectores"],
    components: {
        AlternativaSelectorControlador,
    },
});
