import * as TiposDifunto from "@/modules/Difunto";
import {
    RespuestasEntrevista,
    AlternativasSelectoresT,
    Intervencion,
    SeccionRellenada,
} from "@/types/dominio-ceremonia-civil";
import { SelectedValue } from "@/types/components/CustomSelectInput";

export type AppLocales = "es" | "ca" | "en";

export interface RespuestasEntrevistaForm
    extends Omit<
        RespuestasEntrevista,
        "caracter" | "aficiones" | "actitud" | "vinculos" | "recuerdo" | "menciones" | "servicioMusical"
    > {
    isPersonaConflictiva: boolean;
    enfoque: RespuestasEntrevista["enfoque"] | -1;
    caracter: AlternativasSelectoresT;
    aficiones: AlternativasSelectoresT;
    actitud: AlternativasSelectoresT;
    vinculos: AlternativasSelectoresT;
    recuerdo: AlternativasSelectoresT;
    menciones: Intervencion[];
    servicioMusical: ServicioMusicalForm;
}

export interface FichaDifuntoForm extends Omit<TiposDifunto.FichaDifunto, "edad"> {
    edad: number | undefined;
    sexo: TiposDifunto.FichaDifunto["sexo"] | -1;
    tipoMuerte: TiposDifunto.FichaDifunto["tipoMuerte"] | -1;
}

export type GeneracionAudiovisuales = {
    linkPreceremonial?: string;
    linkEntrega?: string;
    linkCrematori?: string;
};

export const STARTING_ID_FOTOS = -1;

export type TemaFondoAudiovisual = {
    codigoTema: string;
    nombre: string;
};

export interface SeccionRellenadaInit extends Omit<SeccionRellenada, "plantillaUsada"> {
    plantillaUsada: null;
}

export type ServicioMusicalForm = {
    musicaContratada: boolean;
    repertorio: {
        pieza1: SelectedValue;
        pieza2: SelectedValue;
        pieza3: SelectedValue;
        pieza4: SelectedValue;
    };
};

export const versionesEntrevista = {
    normal: "entrevista",
    terrassa: "terrassa-crematori",
    memora: "memora-crematori",
};

export type ProgressGeneracionAudiovisual = {
    nombreDifunto: string;
    generating: boolean;
    generationCompleted: boolean;
    progressDetails: null | AudiovisualProgressDetails;
    src: null | string;
};

export type AudiovisualProgressDetails = {
    step: number;
    percentage: number;
    totalSteps: number;
};

// Véase nota sobre Ids en @/types/dominio-ceremonia-civil.ts
export type FotoDifuntoBackend = {
    id: number;
    contenido: string;
    posicion: number;
};
