import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectorAtributosCheckboxes = _resolveComponent("SelectorAtributosCheckboxes")!

  return (_openBlock(), _createBlock(_component_SelectorAtributosCheckboxes, {
    categoriaAtributos: "checkboxesRecuerdo",
    vuexStatePath: "respuestasEntrevista.recuerdo",
    difunto: _ctx.difunto,
    pregunta: _ctx.$t('formEntrevista.recuerdo.pregunta'),
    enlace: _ctx.$t('formEntrevista.recuerdo.enlaceCheckboxes')
  }, null, 8, ["difunto", "pregunta", "enlace"]))
}