import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "accordion-item" }
const _hoisted_2 = {
  key: 0,
  class: "accordion-item--numero"
}
const _hoisted_3 = { class: "accordion-item--transition-wrapper" }
const _hoisted_4 = { class: "accordion-item--content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransitionCollapsing = _resolveComponent("TransitionCollapsing")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.despliegaItem && _ctx.despliegaItem(...args))),
      class: "accordion-item--lama"
    }, [
      (!!_ctx.ordinalTitle)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.ordinalTitle), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _mergeProps({
        class: ["accordion-item--titulo", { 'accordion-item--titulo__sin-numero': !_ctx.ordinalTitle }]
      }, _ctx.$attrs), _toDisplayString(_ctx.title), 17)
    ]),
    _createVNode(_component_TransitionCollapsing, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ], 512), [
          [_vShow, _ctx.isDesplegado]
        ])
      ]),
      _: 3
    })
  ]))
}