import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bc8cb9f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-entrevista--valor-alternativo-selector" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "valor-alternativo--container" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "valor-alternativo--controles" }
const _hoisted_6 = {
  key: 0,
  class: "desarrollos-posibles--transition-container"
}
const _hoisted_7 = { class: "desarrollos-posibles-selector" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_TransitionCollapsing = _resolveComponent("TransitionCollapsing")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.simple)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.selector.label) + ":", 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("textarea", {
        type: "text",
        value: _ctx.alternativaInicial.texto,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.actualizaTextoAlternativo && _ctx.actualizaTextoAlternativo(...args)))
      }, null, 40, _hoisted_4),
      _createElementVNode("section", _hoisted_5, [
        _createElementVNode("button", {
          type: "button",
          class: "valor-alternativo--control-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDesarrollos = !_ctx.openDesarrollos))
        }, [
          (_ctx.openDesarrollos)
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 0,
                class: "valor-alternativo--control-icon",
                name: "remove"
              }))
            : (_openBlock(), _createBlock(_component_ion_icon, {
                key: 1,
                class: "valor-alternativo--control-icon",
                name: "add"
              }))
        ])
      ])
    ]),
    _createVNode(_component_TransitionCollapsing, null, {
      default: _withCtx(() => [
        (_ctx.openDesarrollos)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("section", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selector.desarrollos, (desarrollo, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    onClick: ($event: any) => (_ctx.seleccionaDesarrolloAlternativo(index)),
                    class: _normalizeClass(["desarrollo", {
                            'desarrollo--seleccionado':
                                _ctx.emplazamientoDesarrollos.findIndex(
                                    (emplazamiento) => emplazamiento.desarrolloIndex === index
                                ) !== -1,
                        }])
                  }, [
                    _createElementVNode("span", { innerHTML: desarrollo }, null, 8, _hoisted_9)
                  ], 10, _hoisted_8))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}