import { ContenidoFuente, rellenaPlantilla } from "@/modules/RellenadorPlantillas";
import { InterpretePlantillaCeremonia } from "./InterpretePlantillaCeremonia";
import { AccionReferencial, SEPARADOR_LISTADO_PLACEHOLDERS, validaSintaxisLista } from "./SintaxisPlaceholders";
import { buscaPlaceholdersRaizEnPlantilla } from "./BuscadorPlaceholders";
import {
    PlaceholderFuncionIntexistenteError,
    PlaceholderFuncionArgumentoInvalido,
    RellenadoPlantillaError,
    contextualizaErrorRellenadoRecursivo,
} from "@/modules/errores-rellenador-plantillas-ceremonia";
import { getTypedErrorMessage, shuffle } from "../helpers";
import { Desarrollable, Intervencion } from "@/types/dominio-ceremonia-civil";

type FuncionPlaceholder = (contenidoFuente: ContenidoFuente, ...args: string[]) => string;

function desarrollaAtributo(atributo: Desarrollable) {
    return `${atributo.label}: ${atributo.textoDesarrollo}`;
}

const funcionDesarrolloAtributos = (contenidoFuente: ContenidoFuente, placeholderJSONAtributos: string) => {
    const arrayAtributos = JSON.parse(
        rellenaPlantilla(placeholderJSONAtributos, contenidoFuente, InterpretePlantillaCeremonia)
    ) as Desarrollable[];
    const numAtributosDado = arrayAtributos.length;
    if (numAtributosDado < 1) {
        throw new Error("Array de atributos a desarrollar vacío.");
    }

    const shuffledArrayAtributos = shuffle(arrayAtributos);
    const atributosDesarrollados: string[] = [];
    for (const atributo of shuffledArrayAtributos) {
        atributosDesarrollados.push(desarrollaAtributo(atributo));
    }

    return atributosDesarrollados.join(". ") + ".";
};

const funcionesDePlantilla: { [funcName: string]: FuncionPlaceholder } = {
    testFunc: () => {
        return "test-string";
    },
    testFuncIdentity: (contenidoFuente: ContenidoFuente, arg: string) => {
        return rellenaPlantilla(arg, contenidoFuente, InterpretePlantillaCeremonia);
    },
    testFuncConcat: (contenidoFuente: ContenidoFuente, ...args: string[]) => {
        return "".concat(...args.map((arg) => rellenaPlantilla(arg, contenidoFuente, InterpretePlantillaCeremonia)));
    },
    randomClass: (contenidoFuente: ContenidoFuente, placeholderPlantillaClase: string) => {
        const listadoPlaceholdersNombreDeClase = rellenaPlantilla(
            placeholderPlantillaClase,
            contenidoFuente,
            InterpretePlantillaCeremonia
        ).split(SEPARADOR_LISTADO_PLACEHOLDERS);

        const plantillaAleatoria =
            listadoPlaceholdersNombreDeClase[Math.floor(Math.random() * listadoPlaceholdersNombreDeClase.length)];

        return rellenaPlantilla(plantillaAleatoria, contenidoFuente, InterpretePlantillaCeremonia);
    },
    desarrolloAtributos: funcionDesarrolloAtributos,
    descomponeAutorTituloPieza: (contenidoFuente: ContenidoFuente, placeholderCumplimentacionPieza: string) => {
        const textoPieza = rellenaPlantilla(
            placeholderCumplimentacionPieza,
            contenidoFuente,
            InterpretePlantillaCeremonia
        );

        const [titulo, autor] = textoPieza.split("-").map((parte) => parte.trim());

        return `${autor} con su '${titulo}'`;
    },
    rellenaSubPlantilla: (
        contenidoFuente: ContenidoFuente,
        placeholderSubconjuntoContenido: string,
        placeholderPlantilla: string
    ) => {
        const subconjuntoContenidoFuenteString = rellenaPlantilla(
            placeholderSubconjuntoContenido,
            contenidoFuente,
            InterpretePlantillaCeremonia
        );

        let subconjuntoContenidoFuente;
        try {
            subconjuntoContenidoFuente = JSON.parse(subconjuntoContenidoFuenteString);
        } catch (error) {
            const errorMsg = getTypedErrorMessage(error);
            throw new RellenadoPlantillaError(
                `Problema convirtiendo a JSON la string obtenida al rellenar el supuesto Placeholder CumplimentacionDifuntoJSON '${placeholderSubconjuntoContenido}': ${errorMsg}`,
                placeholderSubconjuntoContenido
            );
        }

        return rellenaPlantilla(
            placeholderPlantilla,
            {
                listadoPlantillas: contenidoFuente.listadoPlantillas,
                cumplimentacionDifunto: {
                    ...subconjuntoContenidoFuente,
                },
            },
            InterpretePlantillaCeremonia
        );
    },
    listaFamiliares: (contenidoFuente: ContenidoFuente, placeholderJSONFamiliares: string) => {
        const parentescos = JSON.parse(
            rellenaPlantilla(placeholderJSONFamiliares, contenidoFuente, InterpretePlantillaCeremonia)
        ) as Intervencion[];

        let enumeracionFamiliares = "";
        for (let i = 0; i < parentescos.length; i++) {
            let usaPlantilla = "conector-intermedio-familiar";
            if (i === 0) {
                usaPlantilla = "conector-inicial-familiar";
            } else if (i === parentescos.length - 1) {
                usaPlantilla = "conector-final-familiar";
            }

            const segmentoEnumeracion = rellenaPlantilla(
                `[@ rellenaSubPlantilla({j familiar j},[# ${usaPlantilla} #]) @]`,
                {
                    listadoPlantillas: contenidoFuente.listadoPlantillas,
                    cumplimentacionDifunto: {
                        familiar: {
                            ...parentescos[i],
                        },
                    },
                },
                InterpretePlantillaCeremonia
            );

            enumeracionFamiliares += segmentoEnumeracion;
        }

        return enumeracionFamiliares;
    },
    listaParentescosDirecto: (contenidoFuente: ContenidoFuente, placeholderJSONRelativos: string) => {
        const personas = JSON.parse(
            rellenaPlantilla(placeholderJSONRelativos, contenidoFuente, InterpretePlantillaCeremonia)
        ) as string[];

        if (personas.length > 1) {
            const parteInicial = personas.slice(0, -1).map((persona) => `<b>${persona}</b>`);
            const parteFinal = personas.slice(-1).map((persona) => `<b>${persona}</b>`);

            return `${parteInicial.join(", ")} y ${parteFinal[0]}`;
        } else {
            return `<b>${personas[0]}</b>`;
        }
    },
};

function validaPlaceholdersArgs(args: string[]): boolean {
    if (args.length) {
        const argumentosValidados: string[] = [];

        args.forEach((argumento) => {
            const ocurrenciasPlaceholders = buscaPlaceholdersRaizEnPlantilla(argumento);

            if (ocurrenciasPlaceholders.length !== 1) {
                return false;
            } else {
                argumentosValidados.push(ocurrenciasPlaceholders[0].placeholderEntero);
            }
        });

        if (
            args.filter((arg) => argumentosValidados.includes(arg)).length === args.length &&
            argumentosValidados.length === args.length
        ) {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
}

export const ejecutaPlaceholderDeFuncion: AccionReferencial = (
    fullPlaceholder: string,
    nombreFuncion: string,
    listaArgumentos = ""
) => {
    return (contenidoFuente: ContenidoFuente) => {
        const argumentosSeparados = listaArgumentos ? listaArgumentos.split(SEPARADOR_LISTADO_PLACEHOLDERS) : [];
        if (!(validaSintaxisLista(listaArgumentos) && validaPlaceholdersArgs(argumentosSeparados))) {
            throw new PlaceholderFuncionArgumentoInvalido(fullPlaceholder);
        }

        for (const funcion in funcionesDePlantilla) {
            if (funcion === nombreFuncion) {
                try {
                    return funcionesDePlantilla[funcion](contenidoFuente, ...argumentosSeparados);
                } catch (e) {
                    if (e instanceof RellenadoPlantillaError) {
                        throw contextualizaErrorRellenadoRecursivo(e, fullPlaceholder);
                    } else {
                        throw e;
                    }
                }
            }
        }

        throw new PlaceholderFuncionIntexistenteError(nombreFuncion, fullPlaceholder);
    };
};
