import { BackendRequesterI } from "@/interfaces/BackendRequesterI";
import { AuthenticatorI, Usuario } from "@/interfaces/AuthenticatorI";
import { AudiovisualGeneratorI } from "@/interfaces/AudiovisualGeneratorI";
import { DominioCeremoniaRetrieverI } from "@/interfaces/DominioCeremoniaRetrieverI";
import { BackendResponse } from "@/interfaces/BackendRequesterI";
import {
    Plantilla,
    PiezaMusicalERP,
    Poema,
    AudiovisualAutomaticoGeneracion,
    Ceremonia,
    EntrevistaGuardada,
    EntrevistaBackend,
    formateoEntrevistaBackFront,
    FotoDifuntoAudiovisual,
} from "@/types/dominio-ceremonia-civil";
import { APIResponseError } from "@/exceptions/BackendRequesterErrors";
import {
    AppLocales,
    FichaDifuntoForm,
    FotoDifuntoBackend,
    ProgressGeneracionAudiovisual,
    RespuestasEntrevistaForm,
    TemaFondoAudiovisual,
} from "@/types/auxiliares-logica-app";
import { convertFileToBase64, convertBase64ToBlob } from "@/modules/helpers";

export type Request = {
    endpoint: string;
    params: object;
    options: object;
};

export default class BackendAPIConsumer implements AuthenticatorI, DominioCeremoniaRetrieverI, AudiovisualGeneratorI {
    constructor(private apiUrl: string, private backendRequester: BackendRequesterI) {}

    getAllPlantillas() {
        return this.request("/plantillas/get", {}, {})
            .then(this.checkErrors)
            .then((response) => {
                return response.data.plantillas as Plantilla[];
            });
    }

    login(username: string, password: string) {
        return this.request("/user/authcheck", { username, password }, { method: "post" })
            .then(this.checkErrors)
            .then((response) => {
                return response.data.usuario as Usuario;
            });
    }

    logout() {
        return this.request("/user/logout", {}, { method: "get" }).then(this.checkErrors);
    }

    getListaRepertorio() {
        return this.request("/repertorio", {}, { method: "get" })
            .then(this.checkErrors)
            .then((response) => {
                return response.data.piezas as PiezaMusicalERP[];
            });
    }

    getListaPoemas() {
        return this.request("/audiovisual/poemas/lista", {}, { method: "get" })
            .then(this.checkErrors)
            .then((response: BackendResponse) => {
                return response.data.poemas as Poema[];
            });
    }

    getPoemaAudioFileSrcById(poemaId: Poema["id_grabacion"]): string {
        return `${this.apiUrl}/audiovisual/poemas/audio/get/${poemaId}`;
    }

    getListaTemas() {
        return this.request("/audiovisual/temas/lista", {}, { method: "get" })
            .then(this.checkErrors)
            .then((response: BackendResponse) => {
                return response.data.temas as TemaFondoAudiovisual[];
            });
    }

    getPortadaTemaImg(codigoTema: TemaFondoAudiovisual["codigoTema"]): string {
        return `${this.apiUrl}/audiovisual/temas/portada/get/${codigoTema}`;
    }

    generaAudiovisualPreceremonial(params: AudiovisualAutomaticoGeneracion) {
        return this.request(`/audiovisual/genera/preceremonial/${params.idCeremonia}`, params, { method: "post" })
            .then(this.checkErrors)
            .then((response) => {
                return response;
            });
    }

    generaAudiovisualEntrega(params: AudiovisualAutomaticoGeneracion) {
        return this.request(`/audiovisual/genera/entrega/${params.idCeremonia}`, params, { method: "post" })
            .then(this.checkErrors)
            .then((response) => {
                return response;
            });
    }

    generaAudiovisualCrematori(params: AudiovisualAutomaticoGeneracion) {
        return this.request(`/audiovisual/genera/crematori/${params.idCeremonia}`, params, { method: "post" })
            .then(this.checkErrors)
            .then((response) => {
                return response;
            });
    }

    checkProcesoGeneracionAudiovisual(tipoArchivo: string, idMultimediaDifunto: Ceremonia["uuidv4"]) {
        return this.request(`/audiovisual/get/${tipoArchivo}/${idMultimediaDifunto}`, {}, { method: "get" })
            .then(this.checkErrors)
            .then((response) => {
                return response.data as ProgressGeneracionAudiovisual;
            });
    }

    listaEntrevistasGuardadas() {
        return this.request("/entrevista/index", {}, { method: "get" })
            .then(this.checkErrors)
            .then((response) => {
                return (response.data.entrevistas as EntrevistaBackend[]).map((entrevista) => {
                    return formateoEntrevistaBackFront(entrevista);
                });
            });
    }

    upsertEntrevista(
        idCeremonia: string,
        idioma: AppLocales,
        rutaVersionEntrevista: string,
        isCompleta: boolean,
        fichaDifunto: FichaDifuntoForm,
        respuestasEntrevista: RespuestasEntrevistaForm
    ) {
        const entrevista = {
            idCeremonia,
            idioma,
            rutaVersionEntrevista,
            completa: isCompleta,
            entrevista: JSON.stringify({
                fichaDifunto,
                respuestasEntrevista,
            }),
        };

        return this.request("/entrevista/upsert", entrevista, { method: "post" })
            .then(this.checkErrors)
            .then((response) => {
                return formateoEntrevistaBackFront(response.data.entrevista as EntrevistaBackend);
            });
    }

    transferEntrevistasLocalStorage(entrevistasCompletadasJson: string) {
        return this.request("/entrevista/transfer-local-storage", { entrevistasCompletadasJson }, { method: "post" })
            .then(this.checkErrors)
            .then((response) => {
                return response;
            });
    }

    upsertFotos(fotos: FotoDifuntoAudiovisual[], idCeremonia: Ceremonia["uuidv4"]) {
        type FotoBackend = {
            contenido: string;
            idCeremonia: string;
            id?: number;
        };
        const enviaFotos: FotoBackend[] = [];

        const conversionPromises = [];
        for (const foto of fotos) {
            if (foto.foto !== null) {
                conversionPromises.push(
                    convertFileToBase64(foto.foto).then((base64Image) => {
                        enviaFotos.push({
                            id: foto.id,
                            contenido: base64Image,
                            idCeremonia: idCeremonia,
                        });
                    })
                );
            } else {
                throw new Error(
                    "Se está intentando subir una foto vacía. Filtra las fotos sin contenido antes de llamar a este método 'upsertFotos'."
                );
            }
        }

        return Promise.all(conversionPromises).then(() => {
            return this.request("/fotos/upsert", enviaFotos, { method: "POST" }).then(this.checkErrors);
        });
    }

    getFotosCeremonia(idCeremonia: Ceremonia["uuidv4"]) {
        return this.request(`/fotos/get/${idCeremonia}`, {}, { method: "GET" })
            .then(this.checkErrors)
            .then((response) => {
                const fotosBackend = response.data.fotos as FotoDifuntoBackend[];
                let fotosFrontend: FotoDifuntoAudiovisual[] = [];
                if (fotosBackend.length > 0) {
                    fotosBackend.sort((foto1, foto2) =>
                        foto1.posicion < foto2.posicion ? -1 : foto1.posicion === foto2.posicion ? 0 : 1
                    );

                    fotosFrontend = fotosBackend.map((foto) => {
                        return {
                            id: foto.id,
                            foto: new File(
                                [convertBase64ToBlob(foto.contenido)],
                                `nessundorma-app-textos-ceremonia-${idCeremonia}-foto-${foto.id}.jpg`
                            ),
                        };
                    });
                }

                return fotosFrontend;
            });
    }

    request(endpoint: string, params: object, options: object) {
        return this.backendRequester
            .request(`${this.apiUrl}${endpoint}`, params, options)
            .then((response: BackendResponse) => {
                return {
                    request: {
                        endpoint,
                        params,
                        options,
                    },
                    response,
                };
            });
    }

    private checkErrors({ request, response }: { request: Request; response: BackendResponse }) {
        if (response.error) {
            throw new APIResponseError("Una petición a la TextosBackendAPI sufrió un error.", response.errors, request);
        } else {
            return response;
        }
    }
}
