import getConfig from "@/modules/config-por-entorno";
import session from "@/modules/session";

export default function useConfigPorEntorno() {
    const user = session.getUser();
    const entornoId = Number(Object.keys(user.entornos)[0]);
    const configEntorno = getConfig(entornoId);

    return {
        configEntorno,
    };
}
