export default {
  "generico": {
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "errors": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
      "fatal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur fatale"])},
      "fatalMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur d'application interne s'est produite. Veuillez contacter les administrateurs."])}
    },
    "cargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
    "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de résultats."])}
  },
  "login": {
    "msgAcceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ACCÈS À"])}
  },
  "formLogin": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "entra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer"])},
    "loginFallido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'accès"])},
    "credencialesIncorrectas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations d'identification incorrectes. Veuillez réessayer."])},
    "errorRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de réseau"])},
    "errorRedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur de réseau s'est produite. Vérifiez votre connexion et réessayez plus tard."])},
    "validationMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur et le mot de passe ne peuvent pas être vides."])}
  },
  "entrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview"])},
  "formEntrevista": {
    "datosGenerales": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données générales"])},
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "apodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surnom"])},
      "edad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge"])},
      "comunidadNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
      "comunidadExtranjero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étranger"])},
      "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
      "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
      "femenino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
      "masculino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
      "tipoMuerte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de décès"])},
      "tipoMuerteEsperada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendue"])},
      "tipoMuerteInesperada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inattendue"])}
    },
    "serviciosExternos": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de musique/audiovisuel"])},
      "repertorio": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répertoire"])},
        "musica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musique"])},
        "siMusica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractée"])},
        "noMusica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Louer de la musique"])},
        "consultaRepertorio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter le répertoire"])},
        "pieza1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce 1"])},
        "pieza2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce 2"])},
        "pieza3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce 3"])},
        "pieza4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce 4 (optionnel)"])}
      },
      "poema": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poèmes"])},
        "poemaEnAudiovisual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un poème pour l'audiovisuel"])},
        "sinPoemaCeremonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uniquement pour l'audiovisuel de type 'Livraison' (PAS 'Cérémonie')"])},
        "poemaAmbos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour 'Cérémonie' et 'Livraison'"])},
        "sinPoemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de poèmes trouvés."])},
        "generoRecitador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre du récitant"])}
      },
      "personalizacionAudiovisual": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisation de l'audiovisuel"])},
        "fotosLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photographies"])},
        "temasLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thème de fond"])},
        "fraseDespedidaLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phrase d'adieu"])},
        "fraseDespedidaPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous aimerons toujours"])}
      }
    },
    "caracter": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractère"])},
      "pregunta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["D'une manière générale, comment définiriez-vous la personne de ", _interpolate(_named("nombre")), ", en tant que personne ouverte, en tant que personne réservée... Comment la définiriez-vous?"])},
      "personaGris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne grise"])},
      "personaGrisPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'après ce que l'on m'a dit, c'est à vous de décider si vous voulez que je dise quelque chose de spécifique ou si vous préférez que nous ayons une cérémonie neutre, sans entrer dans des détails personnalisés..."])},
      "generaCeremoniaNeutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génère une cérémonie neutre"])},
      "seguirPersonalizada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poursuivre la cérémonie personnalisée"])}
    },
    "actitud": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attitude"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ce qui concerne leur façon d'aborder la vie, on peut dire que..."])}
    },
    "vinculos": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens affectifs"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ce qui concerne ses relations avec sa famille et ses amis, que pensez-vous que l'on puisse mettre en évidence? Qu'((il/elle)) était plutôt..."])}
    },
    "menciones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous que nous nommions une personne importante pour lui/elle, sa famille, ses amis ? "])}
    },
    "aficiones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loisirs"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviez-vous des loisirs particuliers, tels que la lecture, la musique, le sport... ?"])},
      "enlaceCheckboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il/Elle a apprécié..."])}
    },
    "recuerdo": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les raisons de se souvenir"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous deviez choisir un aspect important pour lui/elle et pour lequel il/elle aimerait que l'on se souvienne de lui/elle, quel serait-il/elle?"])},
      "enlaceCheckboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenons-nous de lui/elle..."])}
    },
    "intervenciones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventions"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noms des personnes qui participeront à la cérémonie:"])},
      "parentesco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation"])},
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "parentescoPadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Père"])},
      "parentescoMadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mère"])},
      "parentescoMarido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Époux"])},
      "parentescoMujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épouse"])},
      "parentescoPareja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaire"])},
      "parentescoHijo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fils"])},
      "parentescoHija": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fille"])},
      "parentescoNieto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petit-fils"])},
      "parentescoNieta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petit-fille"])},
      "parentescoHermano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frère"])},
      "parentescoHermana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soeur"])},
      "parentescoAmigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amie"])},
      "parentescoOtro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
    },
    "generaCeremonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GÉNÈRE LA CÉRÉMONIE"])},
    "guardaEntrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAUVER L'INTERVIEW"])},
    "infoAdicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations complémentaires"])},
    "enfoque": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approche personnalisée"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de cérémonie:"])},
      "laica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laïque"])},
      "laicaPadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laïque avec Notre Père"])},
      "religiosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religieux"])}
    },
    "extras": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisation additionnelle"])},
      "labelAdjetivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte libre (attributs, expressions courtes) pour l'audiovisuel:"])},
      "labelCheckboxSustitucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les attributs que vous inscrivez dans cette liste sont ajoutés aux adjectifs qui apparaissent dans l'audiovisuel. Si vous cochez la case, seuls ces attributs apparaîtront (exclusivement ceux de cette liste, et non ceux des questions précédentes : caractère, attitude, etc.)"])},
      "sinAdjetivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de texte supplémentaire."])},
      "buttonAddAtributo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un attribut"])},
      "addAtributoPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrire ici"])}
    },
    "formValidationAlert": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs incomplets"])},
      "mensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez prendre connaissance des changements obligatoires suivants:"])}
    },
    "guardaEntrevista": {
      "botonGuardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAUVER L'INTERVIEW"])},
      "falloGuardadoAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'interview n'a pas pu être sauvegardé."])},
      "exitoGuardadoAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'interview a été sauvegardé avec succès."])}
    }
  },
  "alternativasSelectoresPregunta": {
    "cabecera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement d'attributs"])},
    "enlacePorDefecto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il/Elle a été"])}
  },
  "composicionCeremonia": {
    "anotacionesEntrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes d'interview:"])},
    "tituloCeremonia": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cérémonie pour ", _interpolate(_named("nombreDifunto"))])},
    "botonCompleta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TERMINER LA CÉRÉMONIE"])},
    "msgCargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
    "secciones": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
      "reflexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réflexion"])},
      "parte-personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part personnelle"])},
      "despedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adieu"])}
    },
    "audiovisualError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la génération de l'audiovisuel."])},
    "plantillasInsuficientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles insuffisants"])},
    "plantillasInsuficientesMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucun modèle n'a été trouvé pour la section ", _interpolate(_named("nombreSeccion")), " et la typologie de la cérémonie."])}
  },
  "seccionCeremonia": {
    "botonCambiaPlantilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de modèle"])}
  },
  "catalogoElemento": {
    "botonUsar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation"])}
  },
  "espacioMultimedia": {
    "tituloAudiovisual-preceremonial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cérémonie audiovisuelle pour ", _interpolate(_named("nombreDifunto"))])},
    "tituloAudiovisual-entrega": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Livraison audiovisuelle pour ", _interpolate(_named("nombreDifunto"))])},
    "tituloAudiovisual-crematorio": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Crématorium audiovisuel pour ", _interpolate(_named("nombreDifunto"))])},
    "msgEnCola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un autre audiovisuel est en train d'être généré en ce moment, lorsqu'il sera terminé, la génération de celui-ci commencera et vous pourrez voir les progrès réalisés."])},
    "botonDescarga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger l'audiovisuelle"])},
    "progressBarStep": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Étape ", _interpolate(_named("currStep")), " de ", _interpolate(_named("totalSteps"))])}
  },
  "logoutAlert": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous vous déconnecter?"])}
  },
  "miCuenta": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "continuaEntrevistas": {
      "tituloIncompletas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interviews en cours"])},
      "tituloCompletas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interviews réalisées"])},
      "labelFechaInicioEntrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début de l'interview: "])},
      "labelFechaComplecion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin: "])},
      "labelNombreDifunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du défunt: "])},
      "sinNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans nom"])},
      "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune trouvée"])}
    }
  },
  "audiovisual": {
    "textoDespedidaDefecto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toujours dans nos coeurs"])}
  },
  "escogeVersionEntrevista": {
    "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel type de cérémonie souhaitez-vous organiser?"])},
    "tipoCompleta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complète"])},
    "tipoCrematori": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crématorium"])}
  }
}