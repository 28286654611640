import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "image-uploader",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.subeImagen && _ctx.subeImagen(...args)))
  }, [
    _createElementVNode("input", {
      type: "file",
      accept: "image/png, image/jpeg",
      ref: "imgInputRef",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeImage && _ctx.changeImage(...args))),
      style: {"display":"none"}
    }, null, 544),
    (_ctx.foto != null)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.imgPreview,
          alt: "Foto para audiovisual",
          class: "image-uploader__photo-preview"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createBlock(_component_ion_icon, {
          key: 1,
          class: "image-uploader__photo-placeholder",
          name: "image-outline"
        })),
    (_ctx.foto == null)
      ? (_openBlock(), _createBlock(_component_ion_icon, {
          key: 2,
          class: "image-uploader__add-icon",
          name: "add-circle"
        }))
      : _createCommentVNode("", true)
  ]))
}