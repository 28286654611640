import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-checkbox--check-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCheckbox && _ctx.toggleCheckbox(...args))),
    class: _normalizeClass(["custom-checkbox", {
            'custom-checkbox__checked': _ctx.isChecked,
        }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isChecked)
        ? (_openBlock(), _createBlock(_component_ion_icon, {
            key: 0,
            class: "custom-checkbox--check-icon",
            name: "checkmark-outline"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}