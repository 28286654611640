
import { defineComponent } from "vue";

import { Intervencion, MAX_INTERVENCIONES } from "@/types/dominio-ceremonia-civil";

export default defineComponent({
    data() {
        return {
            intervencionesAux: [{ parentesco: "", nombre: "" }] as Intervencion[],
        };
    },
    created() {
        this.intervencionesAux = this.oneTimeInput as Intervencion[];
    },
    methods: {
        filtraIntervencionesRellenadas(fromIntervenciones: Intervencion[]): Intervencion[] {
            return fromIntervenciones.filter((intervencion) => intervencion.parentesco && intervencion.nombre);
        },
    },
    watch: {
        intervencionesAux: {
            handler(newVal: Intervencion[]) {
                const newRellenadas = this.filtraIntervencionesRellenadas(newVal);

                this.$emit("cumplimenta-intervenciones", newRellenadas);

                if (newRellenadas.length < newVal.length - 1) {
                    const semiVacia = this.intervencionesAux.findIndex(
                        (intervencion) => !(intervencion.parentesco || intervencion.nombre)
                    );

                    const elimina =
                        semiVacia !== -1
                            ? semiVacia
                            : this.intervencionesAux.findIndex(
                                  (intervencion) => !(intervencion.nombre && intervencion.parentesco)
                              );

                    if (elimina !== -1) {
                        this.intervencionesAux.splice(elimina, 1);
                    } else {
                        throw new Error(
                            "Error de lógica pretendida imposible. Solo se debería entrar a eliminación de Intervención si hay campos de Intervención de sobras."
                        );
                    }
                } else if (newRellenadas.length === this.intervencionesAux.length) {
                    if (this.intervencionesAux.length < MAX_INTERVENCIONES) {
                        this.intervencionesAux.push({ parentesco: "", nombre: "" });
                    }
                }
            },
            deep: true,
        },
    },
    emits: ["cumplimenta-intervenciones"],
    props: {
        oneTimeInput: {
            type: Object,
            required: true,
        },
    },
});
