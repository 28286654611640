import Swal from "sweetalert2";

export default {
    error(titulo: string, mensaje: string) {
        Swal.fire({
            heightAuto: false,
            icon: "error",
            title: titulo,
            html: mensaje,
        });
    },

    warning(titulo: string, mensaje: string) {
        Swal.fire({
            icon: "warning",
            title: titulo,
            html: mensaje,
        });
    },

    success(msg: string) {
        Swal.fire({
            icon: "success",
            title: msg,
        });
    },

    confirmationModal(
        titulo: string,
        opciones: { yesMsg: string; noMsg: string; yesCb?: () => void; noCb?: () => void }
    ) {
        Swal.fire({
            title: titulo,
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: opciones.yesMsg,
            denyButtonText: opciones.noMsg,
        }).then((result) => {
            if (result.isConfirmed) {
                if (opciones.yesCb !== undefined) {
                    opciones.yesCb();
                }
            } else if (result.isDenied) {
                if (opciones.noCb !== undefined) {
                    opciones.noCb();
                }
            }
        });
    },
};
