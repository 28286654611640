import { Plantilla, Desarrollable, SEPARADOR_LABEL_DESARROLLABLE } from "@/types/dominio-ceremonia-civil";
import { ButtonlikeSelector } from "@/modules/FormEntrevistaSelectoresPorPregunta";

export function shuffle<T>(array: T[]) {
    const copiedBaseArray = [...array];
    const shuffled: T[] = [];

    while (copiedBaseArray.length > 0) {
        const randomIndex = Math.floor(Math.random() * copiedBaseArray.length);
        shuffled.push(copiedBaseArray.splice(randomIndex, 1)[0]);
    }

    return shuffled;
}

export function getPlantillasById(ids: number[], searchIn: Plantilla[]) {
    return searchIn.filter((plantilla) => ids.includes(plantilla.id));
}

export function getPlantillasByClases(clases: string[], searchIn: Plantilla[]) {
    return searchIn.filter((plantilla) => plantilla.clases.filter((clase) => clases.includes(clase)).length > 0);
}

export function getEnv(key: string): string {
    const envVar = process.env[key];
    if (envVar) {
        return envVar;
    } else {
        throw new Error("Falta variable de entorno: process.env." + key);
    }
}

export function filtraDesarrollablesPresentables(des: Desarrollable[], selectoresBase: ButtonlikeSelector[]): string[] {
    const desarrollablesPositivos = des.filter(
        (des) => selectoresBase.find((selector) => selector.id === des.id)?.incluyeAudiovisual
    );

    return desarrollablesPositivos
        .map((desarrollable) =>
            desarrollable.label.indexOf(SEPARADOR_LABEL_DESARROLLABLE) !== -1
                ? desarrollable.label.split(SEPARADOR_LABEL_DESARROLLABLE)
                : desarrollable.label
        )
        .flat();
}

export function convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onloadend = () => {
            const encodedString = fr.result as string | null;
            if (encodedString !== null && encodedString !== "") {
                resolve(encodedString);
            } else {
                reject(`Falló la conversión a Base64 de la img '${file.name}'`);
            }
        };
        fr.readAsDataURL(file);
    });
}

// From: https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
export function convertBase64ToBlob(base64Image: string) {
    const parts = base64Image.split(";base64,");
    const imageType = parts[0].split(":")[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: imageType });
}

export function unhighlightHTML(html: string) {
    return html.replace(/<\/?([bu]|(strong))>/g, "");
}

// https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
export function getTypedErrorMessage(error: unknown) {
    let errorMsg;
    if (error instanceof Error) {
        errorMsg = error.message;
    } else {
        errorMsg = String(error);
    }

    return errorMsg;
}
