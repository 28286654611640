import { rellenaPlantilla } from "@/modules/RellenadorPlantillas";
import { InterpretePlantillaCeremonia } from "@/modules/interprete-plantillas-ceremonia/InterpretePlantillaCeremonia";
import { Difunto } from "@/modules/Difunto";
import { AlternativasSelectoresT, Desarrollable } from "@/types/dominio-ceremonia-civil";
import { findSelectorById, getFullSelectorList } from "@/modules/FormEntrevistaSelectoresPorPregunta";

export function rellenaPlantillaFromDifunto(plantilla: string, difunto: Difunto) {
    return rellenaPlantilla(plantilla, { cumplimentacionDifunto: { difunto: difunto } }, InterpretePlantillaCeremonia);
}

export function componeDesarrollables(alterativasSelector: AlternativasSelectoresT, locale: string, difunto: Difunto) {
    const desarrollables: Desarrollable[] = [];
    for (const [key, value] of Object.entries(alterativasSelector)) {
        desarrollables.push({
            id: +key,
            label: rellenaPlantillaFromDifunto(findSelectorById(+key, getFullSelectorList(locale)).label, difunto),
            textoDesarrollo: value.texto,
        });
    }

    return desarrollables;
}
