
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        option: {
            required: true,
        },
    },
});
