
import { strictInject, T } from "@/symbols";
import { AudiovisualProgressDetails } from "@/types/auxiliares-logica-app";
import { defineComponent, PropType, Ref, ref, toRef, watch } from "vue";

export default defineComponent({
    setup(props) {
        const t = strictInject(T);

        const propProgress = toRef(props, "progress");
        let auxProgress: Ref<AudiovisualProgressDetails> = ref(propProgress.value);

        watch(
            propProgress,
            (newVal, oldVal) => {
                auxProgress.value = JSON.parse(JSON.stringify(newVal));

                if (newVal.step != oldVal.step) {
                    auxProgress.value.step = oldVal.step;
                    auxProgress.value.percentage = 100;

                    setTimeout(() => {
                        auxProgress.value = JSON.parse(JSON.stringify(newVal));
                    }, 1000);
                }
            },
            { deep: true }
        );

        return {
            t,
            genProgress: auxProgress,
        };
    },
    props: {
        progress: {
            required: true,
            type: Object as PropType<AudiovisualProgressDetails>,
        },
    },
});
