export default {
  "generico": {
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "errors": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atenció"])},
      "fatal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fatal"])},
      "fatalMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'ha produït un error intern de l'aplicació. Si us plau, posi's en contacte amb els administradors."])}
    },
    "cargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregant..."])},
    "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sense resultats."])}
  },
  "login": {
    "msgAcceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCÉS A"])}
  },
  "formLogin": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuari"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrasenya"])},
    "entra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entra"])},
    "loginFallido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha fallat l'inici de sessió"])},
    "credencialesIncorrectas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credencials incorrectes. Si us plau, torni-ho a intentar."])},
    "errorRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error de xarxa"])},
    "errorRedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'ha produït un error de xarxa. Comprovi la seva connexió a internet i provi més tard."])},
    "validationMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'han d'emplenar el nom d'usuari i el mot de pas."])}
  },
  "entrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrevista"])},
  "formEntrevista": {
    "datosGenerales": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dades generals"])},
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognoms"])},
      "apodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenom"])},
      "edad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edat"])},
      "comunidadNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunitat de naixement"])},
      "comunidadExtranjero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extranger"])},
      "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lloc de naixement"])},
      "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
      "femenino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femení"])},
      "masculino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculí"])},
      "tipoMuerte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipus de defunció"])},
      "tipoMuerteEsperada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperada"])},
      "tipoMuerteInesperada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inesperada"])}
    },
    "serviciosExternos": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servei musical/audiovisual"])},
      "repertorio": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repertori"])},
        "musica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Música"])},
        "siMusica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractada"])},
        "noMusica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amb música"])},
        "consultaRepertorio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta el repertori a"])},
        "pieza1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça 1"])},
        "pieza2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça 2"])},
        "pieza3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça 3"])},
        "pieza4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça 4 (opcional)"])}
      },
      "poema": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poemes"])},
        "poemaEnAudiovisual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escull el poema pels audiovisuals"])},
        "sinPoemaCeremonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Només per Entrega (NO Cerimònia)"])},
        "poemaAmbos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Cerimònia i Entrega"])},
        "sinPoemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No s'han trobat poemes."])},
        "generoRecitador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gènere del recitador:"])}
      },
      "personalizacionAudiovisual": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalització audiovisual"])},
        "fotosLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotografies"])},
        "temasLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema de fons"])},
        "fraseDespedidaLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frase de comiat"])},
        "fraseDespedidaPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sempre t'estimarem"])}
      }
    },
    "caracter": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caràcter"])},
      "pregunta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En general, com definiríeu el caràcter de ", _interpolate(_named("nombre")), ", com una persona oberta, alegre, reservada... ¿Com la definiríeu?"])},
      "personaGris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona Gris"])},
      "personaGrisPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pel que m'han dit, decideixin vostès si volen que diem alguna cosa en concret o si prefereixen una cerimònia neutral, sense entrar en detalls personalitzats..."])},
      "generaCeremoniaNeutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera Cerimònia Neutral"])},
      "seguirPersonalizada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contiunar amb Personalitzada"])}
    },
    "actitud": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actitud"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pel que fa a la seva manera d'afrontar la vida podríem dir que..."])}
    },
    "vinculos": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincles Afectius"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quant a la seva relació amb la família i amics, ¿Què creieu que podríem destacar? Que era una persona més aviat..."])}
    },
    "menciones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mencions"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voldrieu que nombressim alguna persona important per a ell/ella?, familia, amics?"])}
    },
    "aficiones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aficions"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tenia cap afició especial, com llegir, la música, l'esport...?"])},
      "enlaceCheckboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaudia de..."])}
    },
    "recuerdo": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moment per a ésser recordat"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si haguessin d'escollir un aspecte important per a ell(a) pel qual voldria ser recordat(da), ¿Quin seria?"])},
      "enlaceCheckboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordem-lo/la..."])}
    },
    "intervenciones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervencions"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de les persones que intervindran a la cerimònia:"])},
      "parentesco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parentiu"])},
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "parentescoPadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pare"])},
      "parentescoMadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mare"])},
      "parentescoMarido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marit"])},
      "parentescoMujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esposa"])},
      "parentescoPareja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parella"])},
      "parentescoHijo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill"])},
      "parentescoHija": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filla"])},
      "parentescoNieto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net"])},
      "parentescoNieta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neta"])},
      "parentescoHermano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germà"])},
      "parentescoHermana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germana"])},
      "parentescoAmigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amic/ga"])},
      "parentescoOtro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altres"])}
    },
    "generaCeremonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GENERA CERIMÒNIA"])},
    "infoAdicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informació addicional"])},
    "enfoque": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfocament personalitzat"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerimònia de tipus:"])},
      "laica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laica"])},
      "laicaPadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laica amb Pare nostre"])},
      "religiosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religiosa"])}
    },
    "extras": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalització addicional"])},
      "labelAdjetivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text (atributs, expressions curtes) lliures per l'audiovisual:"])},
      "labelCheckboxSustitucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els atributs que escriguis en aquesta llista s'afegeixen als items que surten a l'audiovisual, si selecciones el checkbox només sortiran aquests atributs (i no els de les preguntes anteriors, caràcter, etc.)"])},
      "sinAdjetivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sense text addicional."])},
      "buttonAddAtributo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afegeix atribut"])},
      "addAtributoPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriu aquí"])}
    },
    "formValidationAlert": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camps incomplerts"])},
      "mensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, revisi els següents camps obligatoris:"])}
    },
    "guardaEntrevista": {
      "botonGuardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUARDA ENTREVISTA"])},
      "falloGuardadoAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No s'ha pogut desar l'entrevista."])},
      "exitoGuardadoAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrevista desada exitosament."])}
    }
  },
  "alternativasSelectoresPregunta": {
    "cabecera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desenvolupament d'atributs"])},
    "enlacePorDefecto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Era..."])}
  },
  "composicionCeremonia": {
    "anotacionesEntrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anotacions entrevista:"])},
    "tituloCeremonia": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cerimònia per ", _interpolate(_named("nombreDifunto"))])},
    "botonCompleta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETA LA CERIMÒNIA"])},
    "msgCargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregant..."])},
    "secciones": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducció"])},
      "reflexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflexió"])},
      "parte-personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part Personal"])},
      "despedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comiat"])}
    },
    "audiovisualError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error durant la generació de l'audiovisual."])},
    "plantillasInsuficientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantilles insuficients"])},
    "plantillasInsuficientesMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No s'han trobat plantilles per a la secció ", _interpolate(_named("nombreSeccion")), " i la tipologia de cerimònia donada."])}
  },
  "seccionCeremonia": {
    "botonCambiaPlantilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canvia Plantilla"])}
  },
  "catalogoElemento": {
    "botonUsar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USA"])}
  },
  "espacioMultimedia": {
    "tituloAudiovisual-precremonial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Audiovisual Cerimònia per ", _interpolate(_named("nombreDifunto"))])},
    "tituloAudiovisual-entrega": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Audiovisual Entrega per ", _interpolate(_named("nombreDifunto"))])},
    "tituloAudiovisual-crematorio": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Audiovisual Crematori per ", _interpolate(_named("nombreDifunto"))])},
    "msgEnCola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara mateix s'està generant un altre audiovisual, quan acabi començarà la generació d'aquest i podrà veure el progrés."])},
    "botonDescarga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarrega"])},
    "progressBarStep": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pas ", _interpolate(_named("currStep")), " de ", _interpolate(_named("totalSteps"))])}
  },
  "logoutAlert": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desitja tancar la sessió?"])}
  },
  "miCuenta": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El meu compte"])},
    "continuaEntrevistas": {
      "tituloIncompletas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrevistes en progrés"])},
      "tituloCompletas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrevistes completes"])},
      "labelFechaInicioEntrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data d'inici de l'entrevista: "])},
      "labelFechaComplecion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data compleció: "])},
      "labelNombreDifunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom del difunt: "])},
      "sinNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sense nom"])},
      "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No s'ha trobat cap"])}
    }
  },
  "audiovisual": {
    "textoDespedidaDefecto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sempre al nostre cor"])}
  },
  "escogeVersionEntrevista": {
    "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quin tipus de cerimònia vols realitzar?"])},
    "tipoCompleta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETA"])},
    "tipoCrematori": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREMATORI"])}
  }
}