
import { defineComponent } from "vue";

import * as TiposDifunto from "@/modules/Difunto";
import CustomRadioButton from "@/components/CustomRadioButton.vue";

export default defineComponent({
    computed: {
        //Ficha Difunto
        sexosEnum() {
            return TiposDifunto.Sexo;
        },
        tiposMuerteEnum() {
            return TiposDifunto.TipoMuerte;
        },
        ficha: {
            get(): TiposDifunto.FichaDifunto {
                return this.fichaDifunto as TiposDifunto.FichaDifunto;
            },
            set(fichaDifunto: TiposDifunto.FichaDifunto) {
                this.$emit("update:fichaDifunto", fichaDifunto);
            },
        },
    },
    props: {
        fichaDifunto: Object,
    },
    emits: ["update:fichaDifunto"],
    components: {
        CustomRadioButton,
    },
});
