
import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        isChecked(): boolean {
            return this.modelValue.has(this.value);
        },
    },
    methods: {
        toggleCheckbox() {
            const selectedCheckboxes = this.modelValue;

            if (selectedCheckboxes.has(this.value)) {
                selectedCheckboxes.delete(this.value);
            } else {
                selectedCheckboxes.add(this.value);
            }

            this.$emit("update:modelValue", selectedCheckboxes);
        },
    },
    props: {
        value: {
            required: true,
        },
        modelValue: {
            required: true,
            type: Object, //'Set' data structure
        },
    },
    emits: ["update:modelValue"],
});
