export default {
  "generico": {
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "errors": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
      "fatal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatal error"])},
      "fatalMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An internal error occurred. Please, contact the administrators of the application."])}
    },
    "cargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])}
  },
  "login": {
    "msgAcceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCESS TO"])}
  },
  "formLogin": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "entra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "loginFallido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in failed"])},
    "credencialesIncorrectas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials. Please try again."])},
    "errorRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network Error"])},
    "errorRedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A network error occurred. Please, check your connection and try later."])},
    "validationMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username and password must be filled."])}
  },
  "entrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview"])},
  "formEntrevista": {
    "datosGenerales": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
      "apodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
      "edad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "comunidadNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autonomous Community"])},
      "comunidadExtranjero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign birthplace"])},
      "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthplace"])},
      "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
      "femenino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
      "masculino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
      "tipoMuerte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of defunction"])},
      "tipoMuerteEsperada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected"])},
      "tipoMuerteInesperada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unexpected"])}
    },
    "serviciosExternos": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musical/audiovisual services"])},
      "repertorio": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repertoire"])},
        "musica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
        "siMusica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchased"])},
        "noMusica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select music"])},
        "consultaRepertorio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review the repertoire at"])},
        "pieza1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piece 1"])},
        "pieza2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piece 2"])},
        "pieza3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piece 3"])},
        "pieza4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piece 4 (optional)"])}
      },
      "poema": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poems"])},
        "poemaEnAudiovisual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a poem for the video montage"])},
        "sinPoemaCeremonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only for Delivery (NO Ceremony)"])},
        "poemaAmbos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Ceremony and Delivery"])},
        "sinPoemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We couldn't find any poem."])},
        "generoRecitador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narrator gender:"])}
      },
      "personalizacionAudiovisual": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video montage customization"])},
        "fotosLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photographies"])},
        "temasLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backround theme"])},
        "fraseDespedidaLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farewell phrase"])},
        "fraseDespedidaPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will always love you"])}
      }
    },
    "caracter": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperament"])},
      "pregunta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["How would you define, in general, ", _interpolate(_named("nombre")), "'s personality? As someone joyful, positive, introverted..."])},
      "personaGris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark person"])},
      "personaGrisPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to what you have said, would you like me to say something specific during the ceremony or would you prefer to have a neutral ceremony, without personal details?"])},
      "generaCeremoniaNeutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Neutral Ceremony"])},
      "seguirPersonalizada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Customized"])}
    },
    "actitud": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attitude"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regarding his/her way of facing life we could say that..."])}
    },
    "vinculos": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emotional bonds"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When it comes to his/her relations with family and friends, ¿What could we say? That he/she was rather... "])}
    },
    "menciones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like us to mention someone important for him/her?, family, friends?"])}
    },
    "aficiones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobbies"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Did he/she had any special hobby, such as reading, music, sports...?"])},
      "enlaceCheckboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He/She enjoyed..."])}
    },
    "recuerdo": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Way to be recalled"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tuvieran que elegir un aspecto importante para él/ella por el que quisiera ser recordado, ¿Cuál sería?"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you had to choose any important facet of his/her life to be remembered for, which one would be?"])},
      "enlaceCheckboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's recall him/her..."])}
    },
    "intervenciones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speeches"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the people who will intervene during the ceremony:"])},
      "parentesco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship"])},
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "parentescoPadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Father"])},
      "parentescoMadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mother"])},
      "parentescoMarido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Husband"])},
      "parentescoMujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wife"])},
      "parentescoPareja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
      "parentescoHijo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son"])},
      "parentescoHija": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daughter"])},
      "parentescoNieto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grandson"])},
      "parentescoNieta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Granddaughter"])},
      "parentescoHermano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brother"])},
      "parentescoHermana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sister"])},
      "parentescoAmigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friend"])},
      "parentescoOtro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "generaCeremonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GENERATE CEREMONY"])},
    "infoAdicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
    "enfoque": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized focus"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiritual alignment of the ceremony:"])},
      "laica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secular"])},
      "laicaPadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secular with prayer"])},
      "religiosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religious"])}
    },
    "extras": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra customization"])},
      "labelAdjetivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open textboxes (adjectives and short expressions) for the audiovisual."])},
      "labelCheckboxSustitucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use exclusively these adjectives (otherwise they will get merged with those selected during the interview)."])},
      "sinAdjetivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No additional text."])},
      "buttonAddAtributo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add attribute"])},
      "addAtributoPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write here"])}
    },
    "formValidationAlert": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomplete fields"])},
      "mensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, review the following obligatory fields:"])}
    },
    "guardaEntrevista": {
      "botonGuardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAVE INTERVIEW"])},
      "falloGuardadoAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The interview couldn't be saved."])},
      "exitoGuardadoAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview saved successfully."])}
    }
  },
  "alternativasSelectoresPregunta": {
    "cabecera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaboration on personal attributes"])},
    "enlacePorDefecto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He/She generally was..."])}
  },
  "composicionCeremonia": {
    "anotacionesEntrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interview annotations:"])},
    "tituloCeremonia": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ceremony for ", _interpolate(_named("nombreDifunto"))])},
    "botonCompleta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETE CEREMONY"])},
    "msgCargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "secciones": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduction"])},
      "reflexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflection"])},
      "parte-personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Section"])},
      "despedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farewell"])}
    },
    "audiovisualError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during the generation of the audiovisual"])},
    "audiovisualWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiovisual Warning"])},
    "audiovisualWarningMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiovisual generation has been rejected because the user session is invalid."])},
    "plantillasInsuficientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are not enough Templates to complete the ceremony"])},
    "plantillasInsuficientesMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Couldn't find templates for the section ", _interpolate(_named("seccionCeremonia")), " and the given ceremony tipology."])}
  },
  "seccionCeremonia": {
    "botonCambiaPlantilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Template"])}
  },
  "catalogoElemento": {
    "botonUsar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USE"])}
  },
  "espacioMultimedia": {
    "tituloAudiovisual-preceremonial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ceremony Montage for ", _interpolate(_named("nombreDifunto"))])},
    "tituloAudiovisual-entrega": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delivery Montage for ", _interpolate(_named("nombreDifunto"))])},
    "tituloAudiovisual-crematorio": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Crematorium Montage for ", _interpolate(_named("nombreDifunto"))])},
    "msgEnCola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right now another video is being generated, when it finishes the generation of this one will start and the progress will be shown."])},
    "botonDescarga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "progressBarStep": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Step ", _interpolate(_named("currStep")), " of ", _interpolate(_named("totalSteps"))])}
  },
  "logoutAlert": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close session?"])}
  },
  "miCuenta": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
    "continuaEntrevistas": {
      "tituloIncompletas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interviews in progress"])},
      "tituloCompletas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed interviews"])},
      "labelFechaInicioEntrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of interview start: "])},
      "labelFechaComplecion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of completion: "])},
      "labelNombreDifunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of deceased: "])},
      "sinNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without name"])},
      "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found any"])}
    }
  },
  "audiovisual": {
    "textoDespedidaDefecto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Always in our heart"])}
  },
  "escogeVersionEntrevista": {
    "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which type of ceremony would you like to create?"])},
    "tipoCompleta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETE"])},
    "tipoCrematori": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREMATORIUM"])}
  }
}