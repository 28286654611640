
import { defineComponent, onMounted } from "vue";

import FormLogin from "@/components/FormLogin.vue"; // @ is an alias to /src
import { getEnv } from "@/modules/helpers";
import { useRoute, useRouter } from "vue-router";
import { LOCALE, strictInject, T } from "@/symbols";
import { AppLocales } from "@/types/auxiliares-logica-app";
import useConfigPorEntorno from "@/composables/useConfigPorEntorno";

export default defineComponent({
    setup() {
        const t = strictInject(T);
        const locale = strictInject(LOCALE);
        const route = useRoute();
        const router = useRouter();

        const apiUrl = process.env.VUE_APP_BACKEND_API_URL;

        onMounted(() => {
            const allowedLocales = getEnv("VUE_APP_I18N_SUPPORTED_LOCALES").split(",");
            const routeLocale = route.params.locale as string;
            if (routeLocale) {
                if (allowedLocales.includes(routeLocale)) {
                    locale.value = routeLocale as AppLocales;
                } else {
                    console.error(`Locale ${routeLocale} inexistente.`);
                }
            }
        });

        function goPostLoginPage() {
            const configEntorno = useConfigPorEntorno().configEntorno;
            router.push({ name: configEntorno.paginaPrincipal });
        }

        return {
            apiUrl,
            t,
            goPostLoginPage,
        };
    },
    components: {
        FormLogin,
    },
});
