
import { EntrevistaGuardada } from "@/types/dominio-ceremonia-civil";
import session from "@/modules/session";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { BACKEND_API_CONSUMER, strictInject, T } from "@/symbols";

type EntrevistaGuardadaView = {
    idCeremonia: EntrevistaGuardada["idCeremonia"];
    nombreDifunto: string;
    fecha: string;
};

export default defineComponent({
    setup() {
        const router = useRouter();
        const backendApiConsumer = strictInject(BACKEND_API_CONSUMER);
        const t = strictInject(T);

        const nombreUsuario = ref(session.getUser().nombre);
        const entrevistas = ref<EntrevistaGuardada[]>([]);
        const entrevistasEnProgreso = ref<EntrevistaGuardada[]>([]);
        const entrevistasCompletadas = ref<EntrevistaGuardada[]>([]);
        const isListaProgDesplegada = ref(false);
        const isListaCompsDesplegada = ref(false);

        const entrevistasView = (entrevistas: EntrevistaGuardada[]) => {
            return entrevistas
                .map((entrevista) => {
                    const parsedDate = new Date(entrevista.fecha);
                    const dia = String(parsedDate.getDate()).padStart(2, "0");
                    const mes = String(parsedDate.getMonth() + 1).padStart(2, "0");
                    const horas = String(parsedDate.getHours()).padStart(2, "0");
                    const minutos = String(parsedDate.getMinutes()).padStart(2, "0");
                    const viewDate = `${dia}/${mes}/${parsedDate.getFullYear()} ${horas}:${minutos}h`;

                    let nombreEnEntrevista = t("miCuenta.continuaEntrevistas.sinNombre");
                    if (
                        entrevista.entrevista.fichaDifunto.nombre !== "" ||
                        entrevista.entrevista.fichaDifunto.apellidos !== ""
                    ) {
                        nombreEnEntrevista = `${entrevista.entrevista.fichaDifunto.nombre} ${entrevista.entrevista.fichaDifunto.apellidos}`;
                    }

                    const viewEntrevista: EntrevistaGuardadaView = {
                        idCeremonia: entrevista.idCeremonia,
                        nombreDifunto: nombreEnEntrevista,
                        fecha: viewDate,
                    };

                    return viewEntrevista;
                })
                .reverse();
        };

        const ENTREVIST_LIST_LIMIT = 5;

        const listaProgreso = computed(() => {
            const totalProgreso = entrevistasView(entrevistasEnProgreso.value);
            if (isListaProgDesplegada.value) {
                return totalProgreso;
            } else {
                return totalProgreso.slice(0, ENTREVIST_LIST_LIMIT);
            }
        });

        const listaCompletadas = computed(() => {
            const totalCompletadas = entrevistasView(entrevistasCompletadas.value);
            if (isListaCompsDesplegada.value) {
                return totalCompletadas;
            } else {
                return totalCompletadas.slice(0, ENTREVIST_LIST_LIMIT);
            }
        });

        const continuaEntrevista = (entrevistaContinuar: EntrevistaGuardadaView) => {
            const entrevista = entrevistas.value.find(
                (entrevistaProgreso) => entrevistaProgreso.idCeremonia === entrevistaContinuar.idCeremonia
            );

            if (entrevista !== undefined) {
                router.push({
                    name: entrevista.rutaVersionEntrevista,
                    params: {
                        idCeremoniaContinuada: JSON.stringify(entrevista.idCeremonia),
                        idioma: JSON.stringify(entrevista.idioma),
                        fichaDifunto: JSON.stringify(entrevista.entrevista.fichaDifunto),
                        respuestasEntrevista: JSON.stringify(entrevista.entrevista.respuestasEntrevista),
                    },
                });
            } else {
                throw new Error(
                    `No se encontró ninguna Entrevista guardada con id '${entrevistaContinuar.idCeremonia}'`
                );
            }
        };

        onMounted(() => {
            backendApiConsumer.listaEntrevistasGuardadas().then((entrevistasBackend: EntrevistaGuardada[]) => {
                entrevistas.value = entrevistasBackend;

                const entrevistasCompletadasAux: EntrevistaGuardada[] = [];
                const entrevistasEnProgresoAux: EntrevistaGuardada[] = [];
                for (const entrevista of entrevistasBackend) {
                    if (entrevista.completa) {
                        entrevistasCompletadasAux.push(entrevista);
                    } else {
                        entrevistasEnProgresoAux.push(entrevista);
                    }
                }

                entrevistasEnProgreso.value = entrevistasEnProgresoAux;
                entrevistasCompletadas.value = entrevistasCompletadasAux;
            });

            let localStorage = window.localStorage;
            let entrevistasLocal = localStorage.getItem("entrevistas-completadas");
            if (null !== entrevistasLocal) {
                backendApiConsumer
                    .transferEntrevistasLocalStorage(entrevistasLocal)
                    .then(function (createdEntrevistasResponse) {
                        localStorage.setItem("old-entrevistas-completadas", entrevistasLocal ?? "");
                        localStorage.removeItem("entrevistas-completadas");
                    });
            }
        });

        return {
            t,
            nombreUsuario,
            continuaEntrevista,
            listaProgreso,
            listaCompletadas,
            isListaProgDesplegada,
            isListaCompsDesplegada,
        };
    },
});
