
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            itemDesplegadoActual: this.itemsIdsList[0],
        };
    },
    computed: {
        isItemDesplegado() {
            return (itemId: number) => {
                return itemId === this.itemDesplegadoActual;
            };
        },
    },
    methods: {
        despliegaItem(itemId: number) {
            if (itemId === this.itemDesplegadoActual) {
                this.itemDesplegadoActual = -1;
            } else {
                this.itemDesplegadoActual = itemId;
            }
        },
    },
    props: {
        itemsIdsList: {
            required: true,
            type: Array,
        },
    },
});
