import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "progress-bar" }
const _hoisted_2 = { class: "progress-bar__container" }
const _hoisted_3 = { class: "progress-bar__texto-pasos" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        style: _normalizeStyle({ width: _ctx.genProgress.percentage + '%' }),
        class: "progress-bar__progress"
      }, _toDisplayString(+_ctx.genProgress.percentage.toFixed(2)) + "% ", 5)
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t("espacioMultimedia.progressBarStep", {
                    currStep: _ctx.genProgress.step,
                    totalSteps: _ctx.genProgress.totalSteps,
                })), 1)
  ]))
}