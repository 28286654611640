export default {
  "generico": {
    "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "errors": {
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención"])},
      "fatal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fatal"])},
      "fatalMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error interno de la aplicación. Por favor, póngase en contacto con los administradores."])}
    },
    "cargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
    "sinResultados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin resultados."])}
  },
  "login": {
    "msgAcceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCESO A"])}
  },
  "formLogin": {
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "entra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entra"])},
    "loginFallido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login fallido"])},
    "credencialesIncorrectas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credenciales incorrectas. Por favor, inténtelo de nuevo."])},
    "errorRed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error de red"])},
    "errorRedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un eror de red. Compruebe su conexión e inténtelo más tarde."])},
    "validationMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre de usuario y la contraseña no pueden estar vacíos."])}
  },
  "entrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrevista"])},
  "formEntrevista": {
    "datosGenerales": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos Generales"])},
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "apellidos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellidos"])},
      "apodo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apodo"])},
      "edad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edad"])},
      "comunidadNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunidad nacimiento"])},
      "comunidadExtranjero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extranjero"])},
      "lugarNacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugar nacimiento"])},
      "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexo"])},
      "femenino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femenino"])},
      "masculino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculino"])},
      "tipoMuerte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de muerte"])},
      "tipoMuerteEsperada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperada"])},
      "tipoMuerteInesperada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inesperada"])}
    },
    "serviciosExternos": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio musical/audiovisual"])},
      "repertorio": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repertorio"])},
        "musica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Música"])},
        "siMusica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratada"])},
        "noMusica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con música"])},
        "consultaRepertorio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta el repertorio en"])},
        "pieza1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieza 1"])},
        "pieza2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieza 2"])},
        "pieza3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieza 3"])},
        "pieza4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieza 4 (opcional)"])}
      },
      "poema": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poemas"])},
        "poemaEnAudiovisual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escoge poema para los audiovisuales"])},
        "sinPoemaCeremonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo para Entrega (NO Ceremonia)"])},
        "poemaAmbos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para Ceremonia y Entrega"])},
        "sinPoemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron poemas."])},
        "generoRecitador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Género recitador:"])}
      },
      "personalizacionAudiovisual": {
        "subtituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalización audiovisual"])},
        "fotosLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotografías"])},
        "temasLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema de fondo"])},
        "fraseDespedidaLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frase de despedida"])},
        "fraseDespedidaPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te querremos siempre"])}
      }
    },
    "caracter": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carácter"])},
      "pregunta": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En general, cómo definirían el carácter de ", _interpolate(_named("nombre")), ", como una persona abierta, reservada. ¿Cómo la definirían?"])},
      "personaGris": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona Gris"])},
      "personaGrisPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por lo que me han dicho, decidan ustedes si quieren que diga algo concreto o prefieren que hagamos una ceremonia neutral, sin entrar en detalles personalizados..."])},
      "generaCeremoniaNeutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera Ceremonia Neutral"])},
      "seguirPersonalizada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir con Personalizada"])}
    },
    "actitud": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actitud"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cuanto a su manera de afrontar la vida podríamos decir que..."])}
    },
    "vinculos": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vínculos Afectivos"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cuanto a su relación con la familia y amigos, ¿Qué creéis que podríamos destacar? Que era una persona más bien..."])}
    },
    "menciones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menciones"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Os gustaría que nombráramos a alguien importante para él/ella?, familia, amigos? "])}
    },
    "aficiones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aficiones"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tenía alguna afición especial, como leer, la música, el deporte...?"])},
      "enlaceCheckboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disfrutaba de..."])}
    },
    "recuerdo": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momento para ser recordado"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tuvieran que elegir un aspecto importante para él/ella por el que quisiera ser recordado, ¿Cuál sería?"])},
      "enlaceCheckboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordémosle..."])}
    },
    "intervenciones": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervenciones"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de las personas que intervendrán en la ceremonia:"])},
      "parentesco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parentesco"])},
      "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "parentescoPadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padre"])},
      "parentescoMadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madre"])},
      "parentescoMarido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marido"])},
      "parentescoMujer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mujer"])},
      "parentescoPareja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareja"])},
      "parentescoHijo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hijo"])},
      "parentescoHija": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hija"])},
      "parentescoNieto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieto"])},
      "parentescoNieta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieta"])},
      "parentescoHermano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hermano"])},
      "parentescoHermana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hermana"])},
      "parentescoAmigo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amigo/a"])},
      "parentescoOtro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])}
    },
    "generaCeremonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GENERA CEREMONIA"])},
    "guardaEntrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUARDA ENTREVISTA"])},
    "infoAdicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información adicional"])},
    "enfoque": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfoque personalizado"])},
      "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceremonia de tipo:"])},
      "laica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laica"])},
      "laicaPadre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laica con Padre nuestro"])},
      "religiosa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Religiosa"])}
    },
    "extras": {
      "tituloPregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalización adicional"])},
      "labelAdjetivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto (atributos, expresiones cortas) libre para el audiovisual:"])},
      "labelCheckboxSustitucion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los atributos que escribas en esta lista se añaden a los adjetivos que aparecen en el audiovisual, si seleccionas el checkbox solo saldrán estos atributos (exclusivamente los de esta lista, y no los de preguntas anteriores: carácter, actitud, etc.)"])},
      "sinAdjetivos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin texto adicional."])},
      "buttonAddAtributo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añade atributo"])},
      "addAtributoPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe aquí"])}
    },
    "formValidationAlert": {
      "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos incompletos"])},
      "mensaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, revise los siguientes cambos obligatiorios:"])}
    },
    "guardaEntrevista": {
      "botonGuardar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUARDA ENTREVISTA"])},
      "falloGuardadoAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo guardar la entrevista."])},
      "exitoGuardadoAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrevista guardada con éxito."])}
    }
  },
  "alternativasSelectoresPregunta": {
    "cabecera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo atributos"])},
    "enlacePorDefecto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha sido..."])}
  },
  "composicionCeremonia": {
    "anotacionesEntrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anotaciones entrevista:"])},
    "tituloCeremonia": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ceremonia para ", _interpolate(_named("nombreDifunto"))])},
    "botonCompleta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETA LA CEREMONIA"])},
    "msgCargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
    "secciones": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introducción"])},
      "reflexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflexión"])},
      "parte-personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte Personal"])},
      "despedida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despedida"])}
    },
    "audiovisualError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error durante la generación del audiovisual."])},
    "plantillasInsuficientes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas insuficientes"])},
    "plantillasInsuficientesMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No se encontraron plantillas para la sección ", _interpolate(_named("nombreSeccion")), " y la tipología de ceremonia dada."])}
  },
  "seccionCeremonia": {
    "botonCambiaPlantilla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia Plantilla"])}
  },
  "catalogoElemento": {
    "botonUsar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar"])}
  },
  "espacioMultimedia": {
    "tituloAudiovisual-preceremonial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Audiovisual Ceremonia para ", _interpolate(_named("nombreDifunto"))])},
    "tituloAudiovisual-entrega": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Audiovisual Entrega para ", _interpolate(_named("nombreDifunto"))])},
    "tituloAudiovisual-crematorio": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Audiovisual Crematori para ", _interpolate(_named("nombreDifunto"))])},
    "msgEnCola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora mismo se está generando otro audiovisual, cuando acabe comenzará la generación de este y podrá ver el progreso."])},
    "botonDescarga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga"])},
    "progressBarStep": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Paso ", _interpolate(_named("currStep")), " de ", _interpolate(_named("totalSteps"))])}
  },
  "logoutAlert": {
    "titulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea cerrar sesión?"])}
  },
  "miCuenta": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta"])},
    "continuaEntrevistas": {
      "tituloIncompletas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrevistas en progreso"])},
      "tituloCompletas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrevistas completadas"])},
      "labelFechaInicioEntrevista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha inicio entrevista: "])},
      "labelFechaComplecion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha compleción: "])},
      "labelNombreDifunto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre difunto: "])},
      "sinNombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin nombre"])},
      "emptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontró ninguna"])}
    }
  },
  "audiovisual": {
    "textoDespedidaDefecto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siempre en nuestros corazones"])}
  },
  "escogeVersionEntrevista": {
    "pregunta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué tipo de ceremonia quieres realizar?"])},
    "tipoCompleta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPLETA"])},
    "tipoCrematori": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREMATORIO"])}
  }
}