
import { defineComponent } from "vue";

export default defineComponent({
    methods: {
        enter(element: HTMLElement) {
            const width = getComputedStyle(element).width;

            element.style.width = width;
            element.style.position = "absolute";
            element.style.visibility = "hidden";
            element.style.height = "auto";

            const height = getComputedStyle(element).height;

            element.style.width = "";
            element.style.position = "";
            element.style.visibility = "";
            element.style.height = "0";

            getComputedStyle(element).height;

            requestAnimationFrame(() => {
                element.style.height = height;
            });
        },
        afterEnter(element: HTMLElement) {
            element.style.height = "auto";
        },
        leave(element: HTMLElement) {
            const height = getComputedStyle(element).height;

            element.style.height = height;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;

            requestAnimationFrame(() => {
                element.style.height = "0";
            });
        },
    },
});
