
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from "vue";

import CustomCheckBox from "@/components/CustomCheckBox.vue";
import CustomSelectInput from "@/components/CustomSelectInput.vue";
import CustomSelectInputOptionRepertorio from "@/components/CustomSelectInputOptionRepertorio.vue";

import { BACKEND_API_CONSUMER, strictInject, T } from "@/symbols";
import { PIEZA_NULL, PiezaMusicalERP } from "@/types/dominio-ceremonia-civil";
import { SelectOption, SelectedValue } from "@/types/components/CustomSelectInput";
import { ServicioMusicalForm } from "@/types/auxiliares-logica-app";
import session from "@/modules/session";

interface RepertorioSelectOption extends SelectOption {
    isReproducible: boolean;
}

export default defineComponent({
    setup(props, { emit }) {
        const t = strictInject(T);
        const backendApiConsumer = strictInject(BACKEND_API_CONSUMER);

        const servicioMusicalBridge = computed({
            get() {
                return props.servicioMusical;
            },
            set(newServices: ServicioMusicalForm) {
                emit("update:servicioMusical", newServices);
            },
        });

        const musicaContratada = ref(new Set<string>());
        const isMusicaContratada = computed(() => {
            return musicaContratada.value.has("musica");
        });

        const defaultSelectValue: SelectedValue = {
            value: "",
            isPredefinedOption: false,
        };
        type RepEsc = {
            [piezaKey: string]: SelectedValue;
        };
        const repertorioEscogido: Ref<RepEsc> = ref({
            pieza1: defaultSelectValue,
            pieza2: defaultSelectValue,
            pieza3: defaultSelectValue,
            pieza4: defaultSelectValue,
        });

        watch(
            repertorioEscogido,
            (newVal: RepEsc) => {
                servicioMusicalBridge.value = {
                    ...servicioMusicalBridge.value,
                    repertorio: newVal as ServicioMusicalForm["repertorio"],
                };
            },
            { deep: true }
        );

        const listaRepertorio: Ref<PiezaMusicalERP[]> = ref([
            {
                id: PIEZA_NULL,
                nombre: t("generico.cargando"),
                autor: "",
                isReproducible: false,
            },
        ]);
        const listaRepertorioSelect: Ref<RepertorioSelectOption[]> = computed(() => {
            return listaRepertorio.value.map((pieza) => ({
                value: pieza.id,
                placeholder: `${pieza.nombre} - ${pieza.autor}`,
                tituloPieza: pieza.nombre,
                autor: pieza.autor,
                isReproducible: pieza.isReproducible,
            }));
        });

        onMounted(() => {
            backendApiConsumer.getListaRepertorio().then((piezas) => {
                listaRepertorio.value = piezas;

                const servicioMusical = props.servicioMusical;
                if (servicioMusical.musicaContratada) {
                    musicaContratada.value.add("musica");

                    repertorioEscogido.value.pieza1 = servicioMusical.repertorio.pieza1;
                    repertorioEscogido.value.pieza2 = servicioMusical.repertorio.pieza2;
                    repertorioEscogido.value.pieza3 = servicioMusical.repertorio.pieza3;
                    repertorioEscogido.value.pieza4 = servicioMusical.repertorio.pieza4;
                }
            });
        });

        watch(
            musicaContratada,
            (newVal: Set<string>) => {
                servicioMusicalBridge.value = {
                    ...servicioMusicalBridge.value,
                    musicaContratada: newVal.has("musica"),
                };
            },
            { deep: true }
        );

        // From: https://codepad.co/snippet/javascript-open-new-window-posting-parameters
        function openWindowWithPost(
            url: string,
            data: {
                [key: string]: string;
            }
        ) {
            const form = document.createElement("form");
            form.target = "_blank";
            form.method = "POST";
            form.action = url;
            form.style.display = "none";

            for (const key in data) {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = data[key];
                form.appendChild(input);
            }
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        }

        const openEMusic = () => {
            const urlEntraEmusic = process.env.VUE_APP_URL_LINK_EMUSIC;
            if (urlEntraEmusic) {
                const continueSessionParams: {
                    [key: string]: string;
                } = {
                    utoken: session.getUser().token,
                };
                const intendedUrl = process.env.VUE_APP_INTENDED_URL_EMUSIC;
                if (intendedUrl) {
                    continueSessionParams.intendedUrl = intendedUrl;
                }
                openWindowWithPost(urlEntraEmusic, continueSessionParams);
            } else {
                throw new Error('No está definida la variable de entorno "RL_LINK_EMUSIC".');
            }
        };

        return {
            t,
            musicaContratada,
            isMusicaContratada,
            listaRepertorio,
            listaRepertorioSelect,
            repertorioEscogido,
            openEMusic,
        };
    },
    emits: ["update:servicioMusical"],
    props: {
        servicioMusical: {
            type: Object as PropType<ServicioMusicalForm>,
            required: true,
        },
    },
    components: {
        CustomCheckBox,
        CustomSelectInput,
        CustomSelectInputOptionRepertorio,
    },
});
