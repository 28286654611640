
import { defineComponent, ref } from "vue";

import Alerta from "@/modules/Alerta";
import * as BackendRequesterE from "@/exceptions/BackendRequesterErrors";
import session from "@/modules/session";
import { BACKEND_API_CONSUMER, T, strictInject } from "@/symbols";

export default defineComponent({
    setup(props, { emit }) {
        const t = strictInject(T);
        const backendApiConsumer = strictInject(BACKEND_API_CONSUMER);

        const username = ref("");
        const password = ref("");

        const loginBackend = () => {
            backendApiConsumer
                .login(username.value, password.value)
                .then((usuario) => {
                    session.init(usuario);
                    emit("completaLogin");
                })
                .catch((err) => {
                    username.value = "";
                    password.value = "";

                    if (err instanceof BackendRequesterE.APIResponseError) {
                        Alerta.warning(t("formLogin.loginFallido"), t("formLogin.credencialesIncorrectas"));
                    } else if (err instanceof BackendRequesterE.NetworkError) {
                        Alerta.error(t("formLogin.errorRed"), t("formLogin.errorRedMsg"));
                    } else {
                        Alerta.error(t("generico.errors.fatal"), t("generico.errors.fatalMsg"));
                    }
                });
        };

        const validateLoginCredentials = () => {
            if ("" === username.value || "" === password.value) {
                return false;
            }

            return true;
        };

        const onSubmit = () => {
            if (validateLoginCredentials()) {
                loginBackend();
            } else {
                Alerta.warning(t("generico.errors.warning"), t("formLogin.validationMsg"));
            }
        };

        return {
            username,
            password,
            onSubmit,
        };
    },
    emits: ["completaLogin"],
});
