import { BackendRequesterI } from "../interfaces/BackendRequesterI";
import FetchBackendRequester from "../classes/FetchBackendRequester";
import BackendAPIConsumer from "../classes/BackendAPIConsumer";
import { getEnv } from "@/modules/helpers";

export default class BackendAPIConsumerFactory {
    static create() {
        const backendRequester: BackendRequesterI = new FetchBackendRequester();

        return new BackendAPIConsumer(getEnv("VUE_APP_BACKEND_API_URL"), backendRequester);
    }
}
