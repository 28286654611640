import { CallbackResolucionReferencia } from "@/modules/InterpretePlantilla.d";
import * as ResolucionContenidoFuente from "./funciones-resolucion-contenido-fuente";
import * as ResolucionPlaceholderFuncion from "./funciones-resolucion-placeholder-funcion";

export type AccionReferencial = (fullPlaceholder: string, ...capturedGroups: string[]) => CallbackResolucionReferencia;

export type EspeciePlaceholder = {
    sintaxis: RegExp; // delimitador + formato del contenido
    extractorReferentes: RegExp;
    accionReferencial: AccionReferencial;
};

// Los Operadores son los Placeholders que pueden tener otros Placeholders dentro. Su definición se separa aquí de los demás puesto que en esta implementación se han de procesar primero los de este tipo para que los Placeholders que pueden contener no sean reconocidos por el Interprete y que sean manejados por la lógica de substitución del propio Placeholder continente.
export const sintaxisOperadoresPlaceholder: EspeciePlaceholder[] = [
    // Los Operadores Condicionales deben procesarse primero para ofuscar las funciones que puedan contener
    {
        sintaxis: /\[if (\{ [.\w\d]+ \} \/then (?:\[|\{).+?(?:\}|\])(?: \/else (?:\[|\{).+?(?:\}|\]))?) endif\]/g,
        extractorReferentes: /^\{ ([.\w\d]+) \} \/then ((?:\[|\{).+?(?:\}|\]))(?: \/else ((?:\[|\{).+?(?:\}|\])))?$/,
        // Should be: (With Branch Reset Groups)
        // extractorReferentes: /^\{ ([.\w\d]+) \} \/then (?|(?:((?:\[|\{).+(?:\}|\])) \/else ((?:\[|\{).+(?:\}|\])))|(?:(?:((?:\[|\{).+(?:\}|\])))(?!(?: \/else))))$/,
        accionReferencial: ResolucionContenidoFuente.procesaOperadorCondicional,
    },
    {
        sintaxis: /\[@ ([\w\d]+\(.*?\)) @\](?!(?:(?:\) @\])|(?:,.*\) @\])))/g,
        extractorReferentes: /^([\w\d]+)\((.*)\)$/,
        accionReferencial: ResolucionPlaceholderFuncion.ejecutaPlaceholderDeFuncion,
    },
];

export const sintaxisPlaceholdersCeremonia: EspeciePlaceholder[] = [
    {
        sintaxis: /\{ ([.-\w\d]+) \}/g,
        extractorReferentes: /^([.-\w\d]+)$/,
        accionReferencial: ResolucionContenidoFuente.obtienePropCumplimentacionDifunto,
    },
    {
        sintaxis: /\[# ([-\w\d]+) #\]/g,
        extractorReferentes: /^([-\w\d]+)$/,
        accionReferencial: ResolucionContenidoFuente.obtienePlantillaPorNombre,
    },
    {
        // De momento se usa esta menos precisa (acepta una coma final en la lista)
        sintaxis: /\[\. ((?:[-\w\d]+,?)+) \.\]/g,
        // Por algún motivo la segunda alternativa ' \.\]' no se empareja con el final del Placeholder
        // sintaxis: /\[\. ((?:[\w\d]+(,(?=[\w\d])| \.\]))+) \.\]/g,
        // Esta funciona para detectar una lista de palabras separada por comas
        // ((?:[\w\d]+(?:,(?=[\w\d])|$))+)$
        extractorReferentes: /^(.+)$/,
        accionReferencial: ResolucionContenidoFuente.construyeListadoPlaceholdersDeClases,
    },
    {
        sintaxis: /\{j ([.-\w\d]+) j\}/g,
        extractorReferentes: /^([.-\w\d]+)$/,
        accionReferencial: ResolucionContenidoFuente.obtieneJSONPropCumplimentacionDifunto,
    },
    {
        sintaxis: new RegExp("\\(\\(([^/]*?/[^/]*?)\\)\\)", "g"),
        extractorReferentes: new RegExp("^([^/]+)?/([^/]+)?$"),
        accionReferencial: ResolucionContenidoFuente.resuelveGenero,
    },
];

export const SEPARADOR_LISTADO_PLACEHOLDERS = ",";
export function validaSintaxisLista(listaPlaceholders: string) {
    return new RegExp(`^(?:(?:\\[|\\{).+?(?:\\}|\\])(?:${SEPARADOR_LISTADO_PLACEHOLDERS}(?=(?:\\[|\\{)))?)*$`).test(
        listaPlaceholders
    );
}
