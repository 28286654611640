/* Módulo de definición de los valores para los selectores (agrupados por pregunta a la que
 * responden) del FormEntrevista.
 * Selectores se utiliza aquí para referirse a los input elements que no son de texto (no al
 * al <select>).
 */

import { ConnotacionAtributo } from "@/types/dominio-ceremonia-civil";
import { alternativasCastellano } from "@/modules/FormEntrevistaSelectoresPorPreguntaCastellano";
import { alternativasIngles } from "@/modules/FormEntrevistaSelectoresPorPreguntaIngles";
import { alternativasCatala } from "@/modules/FormEntrevistaSelectoresPorPreguntaCatala";
import { alternativasFrances } from "@/modules/FormEntrevistaSelectoresPorPreguntaFrances";
export {
    alternativasCastellano as checkboxesCastellano,
    alternativasIngles as checkboxesIngles,
    alternativasCatala as checkboxesCatala,
    alternativasFrances as checkboxesFrances,
};

export type ButtonlikeSelector = {
    id: number;
    label: string;
    desarrollos: string[];
    connotacion?: ConnotacionAtributo;
    incluyeAudiovisual?: boolean;
    desarrolloFijo?: ButtonlikeSelector["id"];
};

export type TraduccionCheckboxes = {
    checkboxesCaracter: ButtonlikeSelector[];
    checkboxesVinculos: ButtonlikeSelector[];
    checkboxesAficiones: ButtonlikeSelector[];
    checkboxesActitud: ButtonlikeSelector[];
    checkboxesRecuerdo: ButtonlikeSelector[];
};

export function getFullSelectorList(locale: string) {
    let idioma: TraduccionCheckboxes;
    switch (locale) {
        case "es":
            idioma = alternativasCastellano;
            break;

        case "en":
            idioma = alternativasIngles;
            break;

        case "ca":
            idioma = alternativasCatala;
            break;

        case "fr":
            idioma = alternativasFrances;
            break;

        default:
            throw new Error("Listado de checkboxes no encontrado para el locale actual: " + locale);
    }

    return [
        ...idioma.checkboxesCaracter,
        ...idioma.checkboxesVinculos,
        ...idioma.checkboxesAficiones,
        ...idioma.checkboxesActitud,
        ...idioma.checkboxesRecuerdo,
    ];
}

export function findSelectorById(selectorId: number, inSelectorList: ButtonlikeSelector[]): ButtonlikeSelector {
    const foundSelector = inSelectorList.find((foundSelector) => foundSelector.id === selectorId);

    if (foundSelector) {
        return foundSelector;
    } else {
        throw new Error(`No se encontró checkboxPregunta con id ${selectorId} en la lista dada de Selectores.`);
    }
}
