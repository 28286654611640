import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31894ea3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-entrevista--pregunta-group" }
const _hoisted_2 = { class: "grid-intervenciones" }
const _hoisted_3 = ["placeholder", "onUpdate:modelValue"]
const _hoisted_4 = ["placeholder", "onUpdate:modelValue"]
const _hoisted_5 = { id: "parentescos" }
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = ["value"]
const _hoisted_10 = ["value"]
const _hoisted_11 = ["value"]
const _hoisted_12 = ["value"]
const _hoisted_13 = ["value"]
const _hoisted_14 = ["value"]
const _hoisted_15 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.$t("formEntrevista.intervenciones.pregunta")), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.intervencionesAux, (intervencion, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            list: "parentescos",
            class: "form-entrevista--respuesta-texto-complementaria",
            placeholder: _ctx.$t('formEntrevista.intervenciones.parentesco'),
            "onUpdate:modelValue": ($event: any) => ((intervencion.parentesco) = $event)
          }, null, 8, _hoisted_3), [
            [_vModelText, intervencion.parentesco]
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-entrevista--respuesta-texto form-entrevista--respuesta-texto__inline",
            placeholder: _ctx.$t('formEntrevista.intervenciones.nombre'),
            "onUpdate:modelValue": ($event: any) => ((intervencion.nombre) = $event)
          }, null, 8, _hoisted_4), [
            [_vModelText, intervencion.nombre]
          ])
        ], 64))
      }), 128)),
      _createElementVNode("datalist", _hoisted_5, [
        _createElementVNode("option", {
          value: _ctx.$t('formEntrevista.intervenciones.parentescoPadre')
        }, null, 8, _hoisted_6),
        _createElementVNode("option", {
          value: _ctx.$t('formEntrevista.intervenciones.parentescoMadre')
        }, null, 8, _hoisted_7),
        _createElementVNode("option", {
          value: _ctx.$t('formEntrevista.intervenciones.parentescoHijo')
        }, null, 8, _hoisted_8),
        _createElementVNode("option", {
          value: _ctx.$t('formEntrevista.intervenciones.parentescoHija')
        }, null, 8, _hoisted_9),
        _createElementVNode("option", {
          value: _ctx.$t('formEntrevista.intervenciones.parentescoHermano')
        }, null, 8, _hoisted_10),
        _createElementVNode("option", {
          value: _ctx.$t('formEntrevista.intervenciones.parentescoHermana')
        }, null, 8, _hoisted_11),
        _createElementVNode("option", {
          value: _ctx.$t('formEntrevista.intervenciones.parentescoMarido')
        }, null, 8, _hoisted_12),
        _createElementVNode("option", {
          value: _ctx.$t('formEntrevista.intervenciones.parentescoMujer')
        }, null, 8, _hoisted_13),
        _createElementVNode("option", {
          value: _ctx.$t('formEntrevista.intervenciones.parentescoPareja')
        }, null, 8, _hoisted_14),
        _createElementVNode("option", {
          value: _ctx.$t('formEntrevista.intervenciones.parentescoAmigo')
        }, null, 8, _hoisted_15)
      ])
    ])
  ]))
}