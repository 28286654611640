import { InterpretePlantilla } from "@/modules/InterpretePlantilla.d";

export type ContenidoFuente = {
    [key: string]: string | number | boolean | ContenidoFuente | (string | number | boolean | ContenidoFuente)[];
};

export function rellenaPlantilla(textoPlantilla: string): string;
export function rellenaPlantilla(
    textoPlantilla: string,
    valsSubstitucion: ContenidoFuente,
    interprete: InterpretePlantilla
): string;
export function rellenaPlantilla(
    textoPlantilla: string,
    valsSubstitucion?: ContenidoFuente,
    interprete?: InterpretePlantilla
) {
    if (valsSubstitucion && interprete) {
        const placeholdersCallbacks = interprete.analiza(textoPlantilla);

        let plantillaInterpolada = textoPlantilla;
        for (const placeholderCallback of placeholdersCallbacks) {
            plantillaInterpolada = plantillaInterpolada.replace(
                placeholderCallback.placeholder,
                placeholderCallback.callbackSubstitucion(valsSubstitucion)
            );
        }

        return plantillaInterpolada;
    } else {
        return textoPlantilla;
    }
}
