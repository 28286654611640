import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "seccion-ceremonia" }
const _hoisted_2 = { class: "seccion-ceremonia--title" }
const _hoisted_3 = { class: "seccion-ceremonia-editor-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ModalCatalogo = _resolveComponent("ModalCatalogo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.seccion.nombre), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Editor, {
          texto: _ctx.texto,
          "onUpdate:texto": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.texto) = $event))
        }, null, 8, ["texto"]),
        _createElementVNode("button", {
          class: "seccion-ceremonia--modifica-button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleModal && _ctx.toggleModal(...args)))
        }, [
          _createVNode(_component_ion_icon, {
            class: "modifica-button--pencil-icon",
            name: "pencil-sharp"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("seccionCeremonia.botonCambiaPlantilla")), 1)
        ])
      ])
    ]),
    _createVNode(_component_ModalCatalogo, {
      visible: _ctx.modalCatalogoVisible,
      onToggleModalVisibility: _cache[2] || (_cache[2] = ($event: any) => (_ctx.modalCatalogoVisible = $event)),
      elementos: _ctx.seccion.plantillas,
      titulo: _ctx.seccion.nombreSeccion,
      onElementoEscogido: _cache[3] || (_cache[3] = ($event: any) => (_ctx.idElementoEscogidoModal = $event))
    }, null, 8, ["visible", "elementos", "titulo"])
  ], 64))
}