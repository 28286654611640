
import { defineComponent } from "vue";

import CustomRadioButton from "@/components/CustomRadioButton.vue";
import { Enfoque } from "@/types/dominio-ceremonia-civil";

export default defineComponent({
    computed: {
        enfoqueEnum() {
            return Enfoque;
        },
        enfoque: {
            get(): Enfoque {
                return this.enfoqueReligioso as Enfoque;
            },
            set(newEnfoque: Enfoque) {
                this.$emit("update:enfoqueReligioso", newEnfoque);
            },
        },
    },
    props: {
        enfoqueReligioso: Number,
    },
    emits: ["update:enfoqueReligioso"],
    components: {
        CustomRadioButton,
    },
});
