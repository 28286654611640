import { InterpretePlantilla, PlaceholderSubstitucion } from "@/modules/InterpretePlantilla.d";
import {
    OcurrenciaPlaceholder,
    buscaPlaceholdersRaizEnPlantilla,
} from "@/modules/interprete-plantillas-ceremonia/BuscadorPlaceholders";
import { InterpretePlantillaError } from "@/modules/errores-rellenador-plantillas-ceremonia";

function extraeReferentesDelPlaceholder(ocurrenciaPlaceholder: OcurrenciaPlaceholder): string[] {
    const matchesReferente = ocurrenciaPlaceholder.contenidoPlaceholder.match(
        ocurrenciaPlaceholder.especie.extractorReferentes
    );

    let referentesCapturedGroups: string[] = [];
    if (matchesReferente) {
        for (const matchReferente of matchesReferente) {
            referentesCapturedGroups.push(matchReferente);
        }

        referentesCapturedGroups = referentesCapturedGroups.slice(1);
    } else {
        throw new InterpretePlantillaError(
            `Error imposible en el InterpretePlantillaCeremonia. No se pudieron extraer referentes del contenido del Placeholder '${ocurrenciaPlaceholder.placeholderEntero}'. Sin embargo el formato del Placeholder se comprueba previamente con una regex que contempla todas sus partes y la necesidad de estos referentes, por tanto de no estar presentes esas partes esta segunda regex (la de capturar los referentes, los valores a buscar en ContenidoFuente) no tendría que haberse ejecutado. Dicho de otra forma: la comprobación del tipo de Placeholder asegura que el contenido del mismo sea válido para su interpretación.`,
            ocurrenciaPlaceholder.placeholderEntero
        );
    }

    return referentesCapturedGroups;
}

function construyePlaceholderSubstitucion(
    ocurrenciaPlaceholder: OcurrenciaPlaceholder,
    referentesContenido: string[]
): PlaceholderSubstitucion {
    return {
        placeholder: ocurrenciaPlaceholder.placeholderEntero,
        callbackSubstitucion: ocurrenciaPlaceholder.especie.accionReferencial(
            ocurrenciaPlaceholder.placeholderEntero,
            ...referentesContenido
        ),
    };
}

export const InterpretePlantillaCeremonia: InterpretePlantilla = {
    analiza(textoCeremonia: string) {
        const placeholderCallbacks: PlaceholderSubstitucion[] = [];

        const ocurrenciasPlaceholders = buscaPlaceholdersRaizEnPlantilla(textoCeremonia);
        ocurrenciasPlaceholders.forEach((ocurrenciaPlaceholder) => {
            placeholderCallbacks.push(
                construyePlaceholderSubstitucion(
                    ocurrenciaPlaceholder,
                    extraeReferentesDelPlaceholder(ocurrenciaPlaceholder)
                )
            );
        });

        return placeholderCallbacks;
    },
};
