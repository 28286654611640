
import { computed, defineComponent, onMounted, PropType, ref, Ref, toRef, watch } from "vue";

import CustomRadioButton from "@/components/CustomRadioButton.vue";
import { Poema, POEMA_NULL } from "@/types/dominio-ceremonia-civil";
import { BACKEND_API_CONSUMER, LOCALE, strictInject, T } from "@/symbols";

export default defineComponent({
    setup(props, { emit }) {
        const t = strictInject(T);
        const locale = strictInject(LOCALE);
        const backendApiConsumer = strictInject(BACKEND_API_CONSUMER);

        const playerRef = ref(null);

        const listaPoemas: Ref<Poema[]> = ref([]);
        const listaPoemasFiltrados: Ref<Poema[]> = ref([]);
        const generoRecitador = ref("hombre");
        const srcPoemaAudioFile = ref("");
        const idPoema = toRef(props, "idPoema");

        const idPoemaEscogido = computed({
            get(): Poema["id_grabacion"] {
                return idPoema.value;
            },
            set(newVal: Poema["id_grabacion"]) {
                emit("update:idPoema", newVal);
            },
        });

        const sinPoemaEnCeremonialBridge = computed({
            get() {
                return props.sinPoemaEnCeremonial;
            },
            set(newVal: boolean) {
                emit("update:sinPoemaEnCeremonial", newVal);
            },
        });

        function filtraPoemas(generoRecitador: Poema["gender"], idioma: Poema["lang"]) {
            listaPoemasFiltrados.value = listaPoemas.value.filter(
                (poema) => poema.gender === generoRecitador && poema.lang === idioma
            );
        }

        function poemaPorDefecto() {
            idPoemaEscogido.value = listaPoemasFiltrados.value[0].id_grabacion;
        }

        function actualizaCondicionantesPoema() {
            if (listaPoemas.value.length > 0) {
                filtraPoemas(generoRecitador.value, locale.value);

                const previousPoema = listaPoemas.value.find((poema) => poema.id_grabacion === idPoemaEscogido.value);
                if (previousPoema) {
                    const poemaEquivalente = listaPoemasFiltrados.value.find(
                        (poema) => poema.id_nominal === previousPoema.id_nominal
                    );

                    if (poemaEquivalente) {
                        idPoemaEscogido.value = poemaEquivalente.id_grabacion;
                    } else {
                        if (listaPoemasFiltrados.value.length) {
                            poemaPorDefecto();
                        } else {
                            idPoemaEscogido.value = POEMA_NULL;
                        }
                    }
                } else {
                    poemaPorDefecto();
                }
            }
        }

        function updatePoemaPlayer() {
            srcPoemaAudioFile.value = backendApiConsumer.getPoemaAudioFileSrcById(idPoema.value);
            (playerRef.value as unknown as HTMLAudioElement).load();
        }

        function inicializaPoema(idPoema: Poema["id_grabacion"]) {
            if (idPoema === POEMA_NULL) {
                actualizaCondicionantesPoema();
            } else {
                const poema = listaPoemas.value.find((poema) => poema.id_grabacion === idPoema);

                if (poema === undefined) {
                    actualizaCondicionantesPoema();
                } else {
                    generoRecitador.value = poema.gender;
                    filtraPoemas(generoRecitador.value, poema.lang);
                    updatePoemaPlayer();
                }
            }
        }

        watch(idPoema, () => {
            updatePoemaPlayer();
        });

        watch(generoRecitador, () => {
            actualizaCondicionantesPoema();
        });

        watch(locale, () => {
            actualizaCondicionantesPoema();
        });

        watch(listaPoemasFiltrados, () => {
            if (0 === listaPoemasFiltrados.value.length) {
                listaPoemasFiltrados.value = listaPoemas.value.filter(
                    (poema) => poema.gender === generoRecitador.value
                );
                idPoemaEscogido.value = listaPoemasFiltrados.value[0].id_grabacion;
            }
        });

        onMounted(() => {
            backendApiConsumer.getListaPoemas().then((poemas) => {
                listaPoemas.value = poemas;
                inicializaPoema(idPoema.value);
            });
        });

        return {
            t,
            sinPoemaEnCeremonialBridge,
            generoRecitador,
            playerRef,
            srcPoemaAudioFile,
            listaPoemasFiltrados,
            idPoemaEscogido,
        };
    },
    props: {
        idPoema: {
            type: Number as PropType<number>,
            required: true,
        },
        sinPoemaEnCeremonial: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
    },
    emits: ["update:idPoema", "update:sinPoemaEnCeremonial"],
    components: {
        CustomRadioButton,
    },
});
