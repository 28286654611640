
import { computed, defineComponent, PropType, Ref, ref, watch } from "vue";

import { MAX_ATRIBUTOS_ADICIONALES, AtributosAdicionales } from "@/types/dominio-ceremonia-civil";

export default defineComponent({
    setup(props, { emit }) {
        const atributoAux = ref("");

        const listaAtributosBridge = computed({
            get(): AtributosAdicionales["listaAtributos"] {
                return props.listaAtributos;
            },
            set(newVal: AtributosAdicionales["listaAtributos"]) {
                emit("update:listaAtributos", newVal);
            },
        });

        const soloAdicionalesBridge = computed({
            get(): AtributosAdicionales["usaSoloAtributosAdicionales"] {
                return props.soloAdicionales;
            },
            set(newVal: AtributosAdicionales["usaSoloAtributosAdicionales"]) {
                emit("update:soloAdicionales", newVal);
            },
        });

        const listaAux: Ref<AtributosAdicionales["listaAtributos"]> = ref([""]);

        const calculaAtributosRellenados = () => listaAux.value.filter(Boolean);

        const escribeAtributo = () => {
            listaAtributosBridge.value = calculaAtributosRellenados();
        };

        watch(
            listaAux,
            () => {
                const atributosRellenados = calculaAtributosRellenados();

                if (listaAux.value.length === atributosRellenados.length) {
                    if (listaAux.value.length < MAX_ATRIBUTOS_ADICIONALES) {
                        listaAux.value = [...listaAux.value, ""];
                    }
                } else if (listaAux.value.length > atributosRellenados.length + 1) {
                    const eliminaIndex = listaAux.value.findIndex((atributo) => atributo === "");

                    if (eliminaIndex !== -1) {
                        listaAux.value.splice(eliminaIndex, 1);
                    } else {
                        throw new Error("Error de lógica. Tendría que existir un atributo adicional vacío.");
                    }
                }
            },
            { deep: true }
        );

        watch(
            listaAtributosBridge,
            () => {
                listaAux.value = listaAtributosBridge.value;
            },
            { deep: true, immediate: true }
        );

        return {
            listaAtributosBridge,
            escribeAtributo,
            listaAux,
            atributoAux,
            soloAdicionalesBridge,
        };
    },
    props: {
        listaAtributos: {
            type: Array as PropType<AtributosAdicionales["listaAtributos"]>,
            required: true,
        },
        soloAdicionales: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["update:listaAtributos", "update:soloAdicionales"],
});
