import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "accordion" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsIdsList, (itemId) => {
      return _renderSlot(_ctx.$slots, itemId, {
        key: itemId,
        isDesplegado: _ctx.isItemDesplegado(itemId),
        despliegaItem: _ctx.despliegaItem
      })
    }), 128))
  ]))
}