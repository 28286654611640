import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder"]
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  class: "login-form--submit",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: "login-form",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _withDirectives(_createElementVNode("input", {
      class: "login-form--input",
      placeholder: _ctx.$t('formLogin.user'),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event))
    }, null, 8, _hoisted_1), [
      [_vModelText, _ctx.username]
    ]),
    _withDirectives(_createElementVNode("input", {
      class: "login-form--input",
      type: "password",
      placeholder: _ctx.$t('formLogin.password'),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.password]
    ]),
    _createElementVNode("button", _hoisted_3, _toDisplayString(_ctx.$t("formLogin.entra")), 1)
  ], 32))
}