import BackendAPIConsumer from "@/classes/BackendAPIConsumer";
import TokenTipologiaCeremonia from "@/classes/TokenTipologiaCeremonia";
import { Difunto, rangoEdadALos } from "@/modules/Difunto";
import { getPlantillasByClases } from "@/modules/helpers";
import { filtraElementosSegunTipologiaCeremonia } from "@/modules/TipologiaCeremoniaFilter";
import { AppLocales, SeccionRellenadaInit } from "@/types/auxiliares-logica-app";
import {
    ORDEN_SECCIONES_CEREMONIA,
    Plantilla,
    RespuestasEntrevista,
    Seccion,
    SeccionRellenada,
} from "@/types/dominio-ceremonia-civil";
import { ComponentCustomProperties, computed, onMounted, Ref, ref } from "vue";

export default function useSeccionesPlantillas(
    t: ComponentCustomProperties["$t"],
    locale: AppLocales,
    backendApiConsumer: BackendAPIConsumer,
    difunto: Difunto,
    respuestasEntrevista: RespuestasEntrevista
) {
    const seccionesBase: Ref<Seccion[]> = ref([]);
    const areSeccionesCargadas = ref(false);
    const listadoPlantillas: Ref<Plantilla[]> = ref([]);

    const rellenamientoPorSeccion: Ref<(SeccionRellenada | SeccionRellenadaInit)[]> = ref(
        ORDEN_SECCIONES_CEREMONIA.map((idSeccion) => ({
            idSeccion,
            plantillaUsada: null,
            textoRellenado: "",
        }))
    );

    const seccionesNombradas = computed(() =>
        seccionesBase.value.map((seccionBase) => {
            const nombreSeccionTraducido = t("composicionCeremonia.secciones." + seccionBase.nombre);
            return {
                ...seccionBase,
                nombre: nombreSeccionTraducido,
            };
        })
    );

    onMounted(() => {
        const tokenTipologiaCeremonia = new TokenTipologiaCeremonia(
            rangoEdadALos(difunto.edad),
            difunto.sexo,
            difunto.tipoMuerte,
            {
                relaciones: difunto.tipoRelaciones,
                ceremoniaGenerica: !respuestasEntrevista.eligeCeremoniaPersonalizada,
            },
            respuestasEntrevista.enfoque
        );

        backendApiConsumer.getAllPlantillas().then((plantillas) => {
            const plantillasLocalizadas = plantillas.filter(
                (plantilla) => plantilla.clases.includes(locale) || plantilla.clases.includes("estructura")
            );

            listadoPlantillas.value = filtraElementosSegunTipologiaCeremonia(
                tokenTipologiaCeremonia,
                plantillasLocalizadas
            );

            for (let i = 0; i < ORDEN_SECCIONES_CEREMONIA.length; i++) {
                const idSeccion = ORDEN_SECCIONES_CEREMONIA[i];
                const plantillasPorSeccion = getPlantillasByClases([idSeccion], listadoPlantillas.value);

                if (plantillasPorSeccion.length === 0) {
                    // Alerta.error(
                    //     this.$t("composicionCeremonia.plantillasInsuficientes"),
                    //     this.$t("composicionCeremonia.plantillasInsuficientesMsg", { idSeccion })
                    // );
                } else {
                    seccionesBase.value.push({
                        idSeccion: idSeccion,
                        nombre: idSeccion,
                        plantillas: plantillasPorSeccion,
                    });
                }
            }

            areSeccionesCargadas.value = true;
        });
    });

    return {
        areSeccionesCargadas,
        listadoPlantillas,
        seccionesNombradas,
        rellenamientoPorSeccion,
    };
}
