import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d465775a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "entrevista-page" }
const _hoisted_2 = { class: "entrevista-box" }
const _hoisted_3 = { class: "entrevista--header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormEntrevista = _resolveComponent("FormEntrevista")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("entrevista")), 1),
      _createVNode(_component_FormEntrevista, {
        onCompletaEntrevista: _ctx.iniciaComposicionCeremonia,
        "id-ceremonia": _ctx.idCeremonia,
        "continua-ficha-difunto": _ctx.fichaDifunto,
        "continua-respuestas-entrevista": _ctx.respuestasEntrevista,
        versionEntrevista: _ctx.rutaTipoEntrevista
      }, null, 8, ["onCompletaEntrevista", "id-ceremonia", "continua-ficha-difunto", "continua-respuestas-entrevista", "versionEntrevista"])
    ])
  ]))
}