import { BackendRequesterI, BackendResponse } from "../interfaces/BackendRequesterI";
import * as BackendRequesterE from "../exceptions/BackendRequesterErrors";

function checkHttpStatusErrors(response: Response) {
    if (!response.ok) {
        let exception = new BackendRequesterE.UnhandledHttpError(
            `Se produjo un fallo inesperado en la petición: ${response.status} - ${response.statusText}`
        );

        switch (response.status) {
            case 403:
                exception = new BackendRequesterE.Error403("Petición denegada. Prueba a reiniciar la sesión.");
                break;
            case 404:
                exception = new BackendRequesterE.Error404("Endpoint no encontrado en la API del backend.");
                break;
            case 500:
                exception = new BackendRequesterE.Error500("Se produjo un error interno en el backend.");
                break;
        }

        throw exception;
    }

    return response;
}

export default class FetchBackendRequester implements BackendRequesterI {
    request(endpoint: string, params: object, options: object): Promise<BackendResponse> {
        const backendEndpoint = new URL(endpoint);

        const requestConfig = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Request-Headers": "Content-Type",
            },
            ...(Object.keys(params).length !== 0 && {
                body: JSON.stringify(params),
            }),
            ...options,
        };

        return fetch(backendEndpoint.toString(), requestConfig)
            .catch((err) => {
                throw new BackendRequesterE.NetworkError(`Error de red intentando hacer petición al backend: ${err}`);
            })
            .then(checkHttpStatusErrors)
            .then((response) => {
                const cloneResp = response.clone();
                return response.json().catch((err) => {
                    cloneResp.text().then(console.error);
                    throw new BackendRequesterE.MalformedJSONError(
                        `Se produjo un error transformando la respuesta recibida del backend en objeto JSON: ${err}`
                    );
                }) as Promise<BackendResponse>;
            });
    }
}
