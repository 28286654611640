
import { defineComponent } from "vue";

import TransitionCollapsing from "@/components/TransitionCollapsing.vue";
import { ButtonlikeSelector } from "@/modules/FormEntrevistaSelectoresPorPregunta";

import { unhighlightHTML } from "@/modules/helpers";

type LongitudDesarrollo = { desarrolloIndex: number; lengthDesarrollo: number };
type DataTypes = {
    openDesarrollos: boolean;
    desarrollosSeleccionados: number[];
    emplazamientoDesarrollos: LongitudDesarrollo[];
    textoCompuesto: string;
};

const CONECTOR_DESARROLLOS = ".\n";

export default defineComponent({
    data(): DataTypes {
        return {
            openDesarrollos: false,
            desarrollosSeleccionados: [],
            emplazamientoDesarrollos: [],
            textoCompuesto: "",
        };
    },
    methods: {
        actualizaTextoAlternativo(event: Event) {
            this.$emit("input", (event.target as HTMLInputElement).value);
        },
        seleccionaDesarrolloAlternativo(clickedDesarrollo: number) {
            const emplazamientoIndex = this.emplazamientoDesarrollos.findIndex(
                (emplazamiento) => emplazamiento.desarrolloIndex === clickedDesarrollo
            );
            if (emplazamientoIndex !== -1) {
                const emplazamientoClicked = this.emplazamientoDesarrollos[emplazamientoIndex];
                this.emplazamientoDesarrollos.splice(emplazamientoIndex, 1);

                const start = this.emplazamientoDesarrollos
                    .slice(0, emplazamientoIndex)
                    .reduce((acc, curr) => acc + curr.lengthDesarrollo, 0);

                this.textoCompuesto =
                    this.textoCompuesto.substring(0, start) +
                    this.textoCompuesto.substring(start + emplazamientoClicked.lengthDesarrollo);
            } else {
                const desarrollo = unhighlightHTML(this.selector.desarrollos[clickedDesarrollo]);
                this.textoCompuesto += desarrollo + CONECTOR_DESARROLLOS;
                this.emplazamientoDesarrollos.push({
                    desarrolloIndex: clickedDesarrollo,
                    lengthDesarrollo: desarrollo.length + CONECTOR_DESARROLLOS.length,
                });
            }

            this.$emit("input", this.textoCompuesto);
        },
    },
    mounted() {
        const indexDesarrolloInicial = (this.selector as ButtonlikeSelector).desarrollos.findIndex(
            (desarrollo: string) => desarrollo === this.alternativaInicial.texto
        );

        if (indexDesarrolloInicial !== -1) {
            const desarrolloInicial = this.selector.desarrollos[indexDesarrolloInicial];
            this.emplazamientoDesarrollos.push({
                desarrolloIndex: indexDesarrolloInicial,
                lengthDesarrollo: desarrolloInicial.length + CONECTOR_DESARROLLOS.length,
            });

            this.textoCompuesto = desarrolloInicial + CONECTOR_DESARROLLOS;
        } else {
            const textoInicial = this.alternativaInicial.texto + CONECTOR_DESARROLLOS;
            this.emplazamientoDesarrollos.push({
                desarrolloIndex: -1,
                lengthDesarrollo: textoInicial.length,
            });
            this.textoCompuesto = textoInicial;
        }
    },
    props: {
        alternativaInicial: {
            type: Object,
            required: true,
        },
        selector: {
            type: Object,
            required: true,
        },
        simple: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ["input", "bloquea-desarrollo"],
    components: {
        TransitionCollapsing,
    },
});
