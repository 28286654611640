<template>
    <section class="editor-container">
        <ckeditor :editor="editor" v-model="puenteTexto" :config="editorConfig"></ckeditor>
    </section>
</template>

<script>
import { defineComponent } from "vue";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor";

export default defineComponent({
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        "bold",
                        "italic",
                        "underline",
                        "|",
                        "heading",
                        "blockQuote",
                        "|",
                        "fontSize",
                        "fontColor",
                        "|",
                        "undo",
                        "redo",
                    ],
                },
                plugins: [
                    Essentials,
                    Autoformat,
                    Bold,
                    Italic,
                    BlockQuote,
                    Heading,
                    Paragraph,
                    FontSize,
                    FontColor,
                    Underline,
                ],
                fontSize: {
                    options: [12, 14, 16, 18, 20, 23, 26],
                },
            },
        };
    },
    computed: {
        puenteTexto: {
            get() {
                return this.texto;
            },
            set(val) {
                this.$emit("update:texto", val);
            },
        },
    },
    props: {
        texto: {
            type: String,
        },
    },
    components: {
        ckeditor: CKEditor.component,
    },
    emits: ["update:texto"],
});
</script>

<style lang="scss">
.editor-container {
    font: inherit;
    font-size: $def-block-font-size;
    line-height: $mucho-texto-line-height;
    color: text-color-opposite-background($clr-fondo-seccion);
}

$hover-toolbar-btn: lighten($clr-seccion-oscurecida, 15);
$toolbar-btn-activado: darken($clr-seccion-oscurecida, 20);
$hover-toolbar-btn-activado: lighten($toolbar-btn-activado, 10);
:root {
    --ck-border-radius: 0px !important;

    --ck-color-base-background: #{$clr-fondo-seccion} !important;
    --ck-color-shadow-inner: #{$clr-fondo-seccion} !important;
    --ck-color-base-border: #{$clr-fondo-seccion} !important;
    --ck-focus-ring: solid 1px #{$clr-paso-2} !important;
    // Color fuente toolbar
    --ck-color-text: #{$clr-texto-claro} !important;

    --ck-color-button-default-background: #{$clr-seccion-oscurecida} !important;
    --ck-color-button-default-hover-background: #{$hover-toolbar-btn} !important;
    --ck-color-button-default-active-background: #{$toolbar-btn-activado} !important;
    --ck-color-button-on-background: #{$toolbar-btn-activado} !important;
    --ck-color-button-on-hover-background: #{$hover-toolbar-btn-activado} !important;

    --ck-color-toolbar-background: #{$clr-seccion-oscurecida} !important;
    --ck-color-toolbar-border: #{$clr-seccion-oscurecida} !important;
}
</style>
