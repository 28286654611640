
import { defineComponent } from "vue";

export default defineComponent({
    methods: {
        selectRadioButton() {
            this.$emit("update:modelValue", this.value);
        },
    },
    props: {
        value: {
            required: true,
        },
        modelValue: {
            required: true,
        },
    },
    emits: ["update:modelValue"],
});
