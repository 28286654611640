import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fd55c78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-entrevista--pregunta-group" }
const _hoisted_2 = { class: "inline-radio-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomRadioButton = _resolveComponent("CustomRadioButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.$t("formEntrevista.enfoque.pregunta")), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CustomRadioButton, {
        value: _ctx.enfoqueEnum.Laica,
        modelValue: _ctx.enfoque,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.enfoque) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("formEntrevista.enfoque.laica")), 1)
        ]),
        _: 1
      }, 8, ["value", "modelValue"]),
      _createVNode(_component_CustomRadioButton, {
        value: _ctx.enfoqueEnum.LaicaPadre,
        modelValue: _ctx.enfoque,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.enfoque) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("formEntrevista.enfoque.laicaPadre")), 1)
        ]),
        _: 1
      }, 8, ["value", "modelValue"]),
      _createVNode(_component_CustomRadioButton, {
        value: _ctx.enfoqueEnum.Religiosa,
        modelValue: _ctx.enfoque,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.enfoque) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("formEntrevista.enfoque.religiosa")), 1)
        ]),
        _: 1
      }, 8, ["value", "modelValue"])
    ])
  ]))
}