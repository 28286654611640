import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c98caa32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "form-entrevista--pregunta-group"
}
const _hoisted_3 = { class: "form-entrevista--tipo-ceremonia-buttons" }
const _hoisted_4 = {
  class: "button-continua-generacion",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCheckBox = _resolveComponent("CustomCheckBox")!
  const _component_SelectorAtributosCheckboxes = _resolveComponent("SelectorAtributosCheckboxes")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "ramifica-pregunta-persona-conflictiva",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (!_ctx.isPersonaConflictiva || _ctx.eligioCeremoniaPersonalizada)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_SelectorAtributosCheckboxes, {
              categoriaAtributos: "checkboxesCaracter",
              vuexStatePath: "respuestasEntrevista.caracter",
              difunto: _ctx.difunto,
              pregunta: _ctx.$t('formEntrevista.caracter.pregunta', { nombre: _ctx.difunto.nombre })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomCheckBox, {
                  class: "form-entrevista--selector-highlighted",
                  value: "conflictiva",
                  modelValue: _ctx.rasgoTipologiaPersonaConflictiva,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rasgoTipologiaPersonaConflictiva) = $event))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("formEntrevista.caracter.personaGris")), 1)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["difunto", "pregunta"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("formEntrevista.caracter.personaGrisPregunta")), 1),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.$t("formEntrevista.caracter.generaCeremoniaNeutral")), 1),
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.eligeCeremoniaPersonalizada && _ctx.eligeCeremoniaPersonalizada(...args))),
                type: "button",
                class: "form-entrevista--ceremonia-personalizada-button"
              }, _toDisplayString(_ctx.$t("formEntrevista.caracter.seguirPersonalizada")), 1)
            ])
          ]))
    ]),
    _: 1
  }))
}