
import { defineComponent } from "vue";

import { v4 as uuidv4 } from "uuid";

import { RespuestasEntrevista, Enfoque } from "@/types/dominio-ceremonia-civil";
import { Usuario } from "@/interfaces/AuthenticatorI";
import { Difunto } from "@/modules/Difunto";

import ComposicionCeremonia from "./ComposicionCeremonia.vue";

export default defineComponent({
    data(): {
        idCeremonia: string;
        predefSession: { usuario: Usuario };
        respuestasEntrevista: RespuestasEntrevista;
        difunto: Difunto;
    } {
        return {
            idCeremonia: uuidv4(),
            predefSession: {
                usuario: {
                    id: 523,
                    // email: "d@d.com",
                    nombre: "Testmollet Testmollet",
                    token: "beba7e180bd8dca2b86b367e97048ea0d003ca5167e0c126d5828e2af02aabc6",
                    entornos: {
                        "9": "Monserdà",
                    },
                    // perfiles: {
                    //     "33": "Clientes externos (Administradores)",
                    //     "52": "Acceso total a la API",
                    //     "55": "Clientes externos (Usuarios) SOLO MOLLET"
                    // }
                },
            },
            respuestasEntrevista: {
                aficiones: [
                    {
                        id: 13,
                        label: "La Cocina",
                        textoDesarrollo:
                            "Recordadle con esa facilidad que tenía para la cocina y esos platos únicos, con los que alimentaba el estómago pero también vuestro corazón",
                    },
                    {
                        id: 15,
                        label: "La Naturaleza",
                        textoDesarrollo:
                            "La montaña, esos paseos entre los árboles, senderos largos repletos de tierra y piedras, el sonido de los animales, el olor de las flores, paisajes únicos que le conectaban con lo más profundo de su ser",
                    },
                ],
                caracter: [
                    {
                        id: 2,
                        label: "Alegre",
                        textoDesarrollo:
                            "Siempre con esas ganas de sonreírle a la vida, encontrando en cada momento la alegría de vivir",
                    },
                    {
                        id: 3,
                        label: "Positiva",
                        textoDesarrollo:
                            "Consciente que mantener la actitud adecuada causa una reacción en cadena de pensamientos, sucesos y resultados positivos",
                    },
                    {
                        id: 6,
                        label: "Introvertida",
                        textoDesarrollo:
                            "Encontrando en la profundidad de su ser la compañía necesaria para rehuir de su soledad",
                    },
                ],
                actitud: [
                    {
                        id: 25,
                        label: "Generosa",
                        textoDesarrollo:
                            "Que supo darse a los demás de manera desinteresada, compartiendo lo que era, lo que tenía, transmitiendo esa sensación de paz y de amor que todos habéis recibido",
                    },
                    {
                        id: 27,
                        label: "Discreta / Prudente",
                        textoDesarrollo:
                            "Comprendió que muchas veces tenemos que hablar para que nos escuchen, pero otras veces debemos callar para que nos valoren, y así, desde el silencio, lo decía todo",
                    },
                ],
                vinculos: [
                    {
                        id: 7,
                        label: "Familiar",
                        textoDesarrollo:
                            "Que entendió que la familia era el tesoro más grande que uno puede conseguir, y ella se sentía profundamente afortunada con su marido Marcos y sus hijos Miguel y Lucía",
                    },
                    {
                        id: 10,
                        label: "Amiga de sus amigos",
                        textoDesarrollo:
                            "Se mantuvo fiel al pensamiento que la amistad es un amor que no se comunica por los sentidos, existe y por ello debemos valorarla, mimarla y cuidarla como un pequeño y frágil tesoro",
                    },
                ],
                recuerdo: [
                    {
                        id: 30,
                        label: "Con la familia",
                        textoDesarrollo:
                            "A vuestro lado, unidos desde el cariño, con todos los que habéis formado ese sueño que supo transformar en una realidad llena de amor y ternura",
                    },
                    {
                        id: 31,
                        label: "Como una buena persona",
                        textoDesarrollo:
                            "Con todo lo bueno que hemos descrito hoy aquí y que la definen como lo que ha sido para todos vosotros: una persona buena, una buena persona",
                    },
                ],
                servicioMusical: {
                    repertorio: {
                        pieza1: {
                            id: 11,
                            titulo: "Canon - Johann Pachelbel",
                        },
                        pieza2: {
                            id: 17,
                            titulo: "El cant dels ocells - Pau Casals",
                        },
                        pieza3: {
                            id: 4,
                            titulo: "Aria de Rinaldo (Lascia quio Pianga) - Georg Friederich Haendel",
                        },
                        pieza4: {
                            id: -1,
                            titulo: "",
                        },
                    },
                    musicaContratada: true,
                },
                informacionExtra: "Conció a Marcos en las fiestas del pueblo. Él murió en 2015",
                intervenciones: [
                    {
                        parentesco: "Hija",
                        nombre: "Lucía",
                    },
                    {
                        parentesco: "Hermano",
                        nombre: "Jorge",
                    },
                ],
                enfoque: Enfoque.Religiosa,
                audiovisualAutomatico: {
                    idPoemaEscogido: 2,
                    sinPoemaEnCeremonial: true,
                    atributosAdicionales: {
                        usaSoloAtributosAdicionales: false,
                        listaAtributos: ["Risueña", "Bondadosa"],
                    },
                    fotos: [],
                    codigoTemaEscogido: "aigua",
                    fraseDespedida: "Siempre en nuestros corazones",
                },
                menciones: {
                    familia: [
                        {
                            nombre: "Jorge",
                            parentesco: "Hermano",
                        },
                        {
                            nombre: "Melisa",
                            parentesco: "Hija",
                        },
                    ],
                    amigos: ["Julián", "Gregorio", "Valeria"],
                    otros: ["Pere"],
                },
                eligeCeremoniaPersonalizada: true,
            },
            difunto: {
                nombre: "Dolores",
                apellidos: "Gracia Zapatero",
                alias: "Lola",
                lugarNacimiento: "Badajoz",
                edad: 87,
                sexo: 1,
                tipoMuerte: 0,
                comunidadNacimiento: "Extremadura",
                tipoRelaciones: 1,
            },
        };
    },
    created() {
        this.$i18n.locale = "ca";
    },
    components: {
        ComposicionCeremonia,
    },
});
